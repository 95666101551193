import Grupo from "../../../../models/Grupo";
import CustomHandle from "../../../../utils/CustomHandle";

class ControllerListaParticipantes{
    constructor (setLoading, errors, setErrors){
        this.setLoading = setLoading;
        this.errors = errors;
        this.setErrors = setErrors;
    }

    async findAllParticipantes(grupo_id, setParticipantes){
        const result = await Grupo.getParticipantes(grupo_id);
        const onSuccess = (response)=>{
            setParticipantes(response);
        };
      
        CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);
        this.setLoading(false);
    }
}

export default ControllerListaParticipantes;