import api from '../services/api';

const Apoiador = {
  create : async function (data) {
    return await api.post('apoiadores', data)
    .then(response=>response)
    .catch(error=>error);
  },

  delete : async function (patrocinador_id) {
    return await api.delete(`apoiadores/id/${patrocinador_id}`)
    .then(response=>response)
    .catch(error=>error);
  },

  findAll : async function () {
    return await api.get('apoiadores/all/1/7')
    .then(response=>response)
    .catch(error=>error);
  },

  findSearch : async function (data) {
    return await api.get(`apoiadores/data/${data}/1/20`)
    .then(response=>response)
    .catch(error=>error);
  }
}

export default Apoiador;
