import Formulario from "../../../components/Formulario";
import close_icon from "../../../../assets/icons/close.svg";
import { tiposGerenciamentoModal } from "../../../../utils/data";
import Select from "../../../components/Select";
import { Main } from "../styled";
import {useState} from "react";
import Usuario from "../../../../models/Usuario";
import { useEffect } from "react";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import ValidacaoUsuario from "./validacao";
import Validacao from "../../validacao";
import DeleteDialog from "../../../components/DeleteDialog";

function UsuariosModal({tipo, onClose, usuario, setUsuario}){
  const [nomeUsuario, setNomeUsuario] = useState("");
  const [usuarios, setUsuarios] = useState({});
  const [errors, setErrors] = useState({});
  const [showOptions, setShowOptions] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [loading, setShowLoading] = useState(false);

  async function filtrarUsuarios(e) {
    const searchValue = e.target.value;
    setNomeUsuario(searchValue);

    let newUsuarios = [];

    if (searchValue) {
      newUsuarios = await Usuario.findSearch(searchValue);
    } else {
      newUsuarios = await Usuario.findAll();
    }

    setUsuarios(newUsuarios.data);
  }

  function onClickSelectUsuario(usuario) {
    setNomeUsuario(usuario.nome);
    setUsuario(usuario);
    setErrors((prevErrors) => ({ ...prevErrors, usuarios: '' }));
  }

  
  function validar(e, handle) {
    handle(e, usuario, errors, setUsuario, setErrors);
  }

  async function handleSubmit(e){
    e.preventDefault();
    
    const formulario = document.getElementById('form-usuario');
    const input_errors = Validacao.validarCampos(formulario, errors, setErrors);
    setErrors(input_errors);
    if (Validacao.emptyErrors(input_errors)) {
      setShowLoading(true);
      const result = await Usuario.create(usuario);
      if (result.response) {
        const data = result.response.data;

        if (data.field) {
          setErrors((prevErrors) => ({ ...prevErrors, [data.field]: data.error }));
          document.getElementById(data.field)?.focus();
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, geral: data.error }));
        }
      }else{
        setShowSuccess(true);
        setNomeUsuario(usuario.nome);
      }
      setShowLoading(false);
    }
  }

  async function handleDelete(){

    setShowLoading(true);
    const result = await Usuario.delete(usuario.id);
    if (result.response) {
      const data = result.response.data;
      setErrors((prevErrors) => ({ ...prevErrors, delete: data.error }));
    }else{
      window.location.href = "/gerenciamento";
    }
    setShowLoading(false);
    
  }

  function limparCampos(){
    setNomeUsuario("");
    setUsuario({});
  }

  function handleSuccessButton(){
    setShowSuccess(false);
    if(tipo === tiposGerenciamentoModal.CRIAR){
      limparCampos();
    }else{
      // findAllUsuarios();
    }
  }

  useEffect(()=>{
    setNomeUsuario(usuario.nome);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return(
    <>
      {tipo !== tiposGerenciamentoModal.NONE  &&
        <Main>
          {loading && <Loading titulo={"Aguarde um momento"} subtitulo={"Estamos validando suas informações"}/>}
          {showSuccess && 
            <AlertDialog 
              titulo={`Usuario ${usuario.id ? 'atualizado' : 'cadastrado'} com sucesso!`} 
              buttonText={`${usuario.id ? 'Voltar' : 'Cadastrar novo usuario'}`} 
              buttonOnClick={handleSuccessButton}
            />
          }
          { showDelete && 
              <DeleteDialog
                error={errors.delete}
                titulo="Deseja realmente excluir esse usuario?" 
                subtitulo="Todos os dados, plantios e postagens deste usuários seram excluídos de forma permanente."
                onDelete={handleDelete} 
                onClose={()=>setShowDelete(false)}
              />
            }
          <div className="content-modal">
            <div className="top">
              <h3>Usuarios</h3>
              <button type="button" onClick={()=>{limparCampos(); onClose()}}>
                <img src={close_icon} alt="Icone de fechar"/>
              </button>
            </div>
            
            <h4>
              Editar ou excluir um usuário
            </h4>
            <Formulario id="form-usuario">
              { tipo === tiposGerenciamentoModal.EDITAR &&
                <div className="form-group">
                  <label htmlFor="usuarios">Lista de usuarios</label>
                  <Select 
                    id="usuarios" 
                    name="usuarios" 
                    placeholder="Pesquise por usuarios aqui" 
                    required={false}
                    autoComplete="off"
                    value={nomeUsuario} 
                    onChange={filtrarUsuarios} 
                    className={`input-prefix ${errors.usuarios && 'input-error'}`}
                    options={usuarios.rows}
                    onClickOption={onClickSelectUsuario}
                    showOptions={showOptions}
                    setShowOptions={setShowOptions}
                  />
                  <span className="error">{errors.usuarios}</span>
                </div>
              }

<div className="form-group">
                <label htmlFor="nome">Nome do usuário</label>
                <input 
                  required
                  value={usuario.nome || ""} 
                  type="text" 
                  name="nome" 
                  id="nome"
                  disabled
                  placeholder={tipo === tiposGerenciamentoModal.EDITAR ? "Escolha um usuario" : "Digite o nome do novo usuario"}
                />
                <span className="error">{errors.nome}</span>
              </div>

              <div className="form-group">
                <label htmlFor="email">Email do usuário</label>
                <input 
                  required
                  value={usuario.email || ""} 
                  type="text" 
                  name="email" 
                  id="email"
                  disabled
                  placeholder={tipo === tiposGerenciamentoModal.EDITAR ? "Escolha um usuario" : "Digite o nome do novo usuario"}
                />
                <span className="error">{errors.nome}</span>
              </div>

              <div className="form-group">
                <label htmlFor="estado">Estado do usuário</label>
                <input 
                  required
                  value={usuario.estado || ""} 
                  type="text" 
                  name="estado" 
                  id="estado"
                  disabled
                  placeholder={tipo === tiposGerenciamentoModal.EDITAR ? "Escolha um usuario" : "Digite o nome do novo usuario"}
                />
                <span className="error">{errors.nome}</span>
              </div>

              <div className="form-group">
                <label htmlFor="cidade">Cidade do usuário</label>
                <input 
                  required
                  value={usuario.cidade || ""} 
                  type="text" 
                  name="cidade" 
                  id="cidade"
                  disabled
                  placeholder={tipo === tiposGerenciamentoModal.EDITAR ? "Escolha um usuario" : "Digite o nome do novo usuario"}
                />
                <span className="error">{errors.nome}</span>
              </div>


              <div className="form-group">
                <label htmlFor="nome">Tipo de usuário</label>
                <select disabled={usuario.tipo === 'Profissional'} name="tipo" defaultValue={usuario.tipo}>
                  <option disabled value="Profissional">Profissional</option>
                  <option value="Comum">Comum</option>
                  <option value="Administrador">Administrador</option>
                </select>
                <span className="error">{errors.nome}</span>
              </div>
          
              <div className="form-group">
                
                <button disabled={tipo === tiposGerenciamentoModal.EDITAR && !usuario.id} className="save-button" 
                  type="button" onClick={handleSubmit}
                >
                  Salvar edição
                </button>
                <span className="error">{errors.geral}</span>
              </div>

             {tipo === tiposGerenciamentoModal.EDITAR &&
                <div className="form-group">
                  <button disabled={!usuario.id} className="remove-button" onClick={()=>setShowDelete(true)} type="button">Excluir usuario</button>
                </div>
              }
            </Formulario>             
          </div>
        </Main>
      }
    </>
  );
};

export default UsuariosModal;