import { Main, Container } from "./styled";
import perfil_image from "../../../../assets/perfil.png";
import capa_image from "../../../../assets/capa.png";
import localizacao_icone from "../../../../assets/icons/localizacao.svg";
import close_icone from "../../../../assets/icons/close.svg";
import CircularImage from "../../../components/CircularImage";

function GrupoModal({closeModal, grupo, handleParticipar}){
  return(
    <Main>
      <Container>
        <section>
          <div className="image">
            <img src={grupo?.foto_capa?.path || capa_image} alt="Foto do grupo"/>
            <div className="close" onClick={closeModal}>
              <button type="button">
                <img src={close_icone} alt="Icone de fechar"/>
              </button>
            </div>
          </div>
        </section>
        
        <section className="top">
          <h3 className="primary">{grupo?.nome}</h3>
          
          <div className="info">
            <img src={localizacao_icone} alt="Icone de localizacao"/>
            <span>{grupo?.estado}</span>

            <div className="participantes">
              {
                grupo?.grupos_usuarios?.map(grupo_usuario=>
                  <CircularImage src={grupo_usuario?.usuario?.foto_perfil?.path || perfil_image} alt={"Foto de perfil do participante"}/>
                )
              }
              <span>{grupo?.quantidade_usuarios} participantes</span>
            </div>
          </div>
        </section>
        
        <section className="about">
          <h3 className="primary">Sobre</h3>
          <span>{grupo?.sobre}</span>
        </section>

        <section className="admins">
          <h3 className="primary">Administradores</h3>
          <div className="bottom">  
            <div className="content-left">
              {grupo?.administradores?.map(administrator=>
                <div className="admin-profile">
                  <CircularImage src={administrator?.usuario?.foto_perfil?.path} alt={"Foto de perfil do participante 1"}/>
                  <span>{administrator?.usuario?.nome}</span>
                </div>
              )}
            </div>
            <div className="content-right">
              <button type="button" onClick={handleParticipar}>{grupo.is_solicitado ? "Cancelar Solicitação" : "Participar"}</button>
            </div>
          </div>
        </section>          
        
      </Container>
    </Main>
  );
};

export default GrupoModal;