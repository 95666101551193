import styled from "styled-components";

export const Content = styled.div`
  width: 100% !important;
  .back{
    margin-right: auto !important;
    margin-left: 0 !important;
    margin-bottom: 10px !important;
    background-color: transparent !important;
    color: var(--primary-color) !important;
    img{
      width: 8px !important;
    }
  }
`