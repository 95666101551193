import { useEffect } from 'react';
import search_icon from '../../../assets/icons/seach.svg';
import perfil_image from '../../../assets/perfil.png';
import CircularImage from '../CircularImage';
import { Main } from './styled';
import { useRef } from 'react';

const Select = ({ id, name, placeholder, selecteds, closeOnSelect = true, autoComplete, value, onChange, required, className, options, onClickOption, showOptions, setShowOptions, perfil = false, disabled = false }) => {

  const timeoutRef = useRef(null);
  const ref = useRef();

  function handleSelect(option) {
    onClickOption(option);
    if(closeOnSelect){
      setShowOptions(false);
    }
  }

  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowOptions(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <Main ref={ref}>
      <div>
        <input
          type="text"
          id={id}
          name={name}
          placeholder={placeholder}
          required={required}
          autoComplete={autoComplete}
          value={value}
          onChange={onChange}
          onFocus={() => setShowOptions(true)}
          className={className}
          disabled={disabled}
        />
        <img className="prefix" src={search_icon} alt="Icone de busca" />
      </div>
      {showOptions &&
        <div className='search-options'>
          {
            options?.length > 0 ?
              options.map(option => {
                return (
                  <div className={`option-item`} key={`option-${option.id}`} onClick={() => handleSelect(option)}>
                    {
                      option.foto_perfil ?
                        <CircularImage src={option.foto_perfil.path} alt="Foto de perfil do usuário" /> :
                        perfil &&
                        <CircularImage src={perfil_image} alt="Icone de foto de perfil do usuário" />
                    }
                    <span className={`${selecteds?.find(items => items.especie_id === option.id) && 'selected'}`}>
                      {option.nome ?
                        option.nome :
                        `${option.nome_comum} - ${option.nome_cientifico}`
                      }
                    </span>
                  </div>
                )
              })
              :
              <span>Nenhum item encontrado</span>
          }

        </div>
      }
    </Main>
  );
}

export default Select;