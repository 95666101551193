export function formatData(originalDate){
    if (originalDate){
      const date = new Date(originalDate);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const formattedDate = `${day}/${month}/${year}`;
      return formattedDate;
    }
    return null;
}

export async function convertToBase64(files) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    const base64Array = [];

    const readFile = (file) => {
      return new Promise((resolve, reject) => {
        reader.onload = () => {
          const base64String = reader.result.split(',')[1];
          const fileType = file.type.startsWith('image') ? 'Imagem' : 'Video';
          resolve({ base64: base64String, tipo: fileType });
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    };

    const processFiles = async () => {
      try {
        for (let i = 0; i < files.length; i++) {
          const base64Obj = await readFile(files[i]);
          base64Array.push(base64Obj);
        }
        resolve(base64Array);
      } catch (error) {
        reject(error);
      }
    };

    processFiles();
  });
}
