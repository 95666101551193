import plantio_image from "../../../../assets/plantio.png";
import { Card } from "./styled";

function CardApoiador({ patrocinador }) {
  return (
    <Card>
      <a href={`https://${patrocinador?.link}`} target="_blank">
        <img src={patrocinador?.foto?.path ?? plantio_image} alt="Imagem do patrocinador" className={patrocinador ? '': 'img-patrocinador'}/>
      </a>
      {!patrocinador &&
        <div className="info">
          <div className="tag">
            Patrocinador
          </div>
          <div className="title">
            {patrocinador?.nome ?? "Sua empresa aqui"}
          </div>
          <div className="details">
           Se torne um patrocinador do projeto e anuncie sua empresa para todos os plantadores do Brasil.
          </div>
          <div>
            <button type="button">Quero ser um patrocinador</button>
          </div>

        </div>
      }
    </Card>
  );
}

export default CardApoiador;