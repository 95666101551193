import { Main }  from './styled';
import sem_resultados_image from "../../../assets/sem-resultados.png";
const SemResultados = ({texto})=>{
  return(
    <Main>
        <span>{texto}</span>
        <img src={sem_resultados_image} alt='Arvores em preto e branco'/>
    </Main>
  );
}

export default SemResultados;