import styled from "styled-components";

export const FilterDaysContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 12px;
    max-height: 36px !important;

    .active{
        border: 2px solid var(--primary-color);
        background: var(--primary-color);
    }
`;

export const FilterDaysItem = styled.span`
    display: flex;
    padding: 17px 16px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    max-height: 36px !important;

    border-radius: 8px;
    border: 1px solid #ccc;
`;

