import { Content, Main } from "./styled";
import warning from "../../../assets/icons/warning.svg";

function DeleteMidiaDialog({onDelete, onClose}){
  return(
    <Main>
      <Content>
        <div className="top">
          <img src={warning} alt="Icone de Carregamento" className="warning"/>         
          <h3>Deseja deletar esta mídia?</h3>
        </div>
        
        <span>Essa mídia não será utilizada no registro do plantio se a excluir. Tem certeza?</span>

        <div className="bottom">
          <button type="button" onClick={onDelete}>Deletar</button>

          <div className="secondary-button">
            <button type="button" onClick={onClose}>Cancelar</button>
          </div>
        </div>
        
    
      </Content>
    </Main>
  );
}

export default DeleteMidiaDialog;