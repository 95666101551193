import styled from "styled-components";

export const Content = styled.div`
  .toast{
    background-color: #C33333;
    position: absolute;
    bottom: -50px;
    height: 50px;
    left: 20px;
    min-width: 300px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    color: #fff;
    transition: bottom 0.5s ease;
    border-radius: 10px;

    img{
      width: 22px;
      margin-right: 10px;
    }

    span{
      padding: 0;
      margin: 0;
    }

    
  }
  
  .active{
    bottom: 20px;
  }
`