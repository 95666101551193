import doar_image from "../../../../assets/doar.png";
import localizacao_icon from "../../../../assets/icons/localizacao.svg";
import unlike_icon from "../../../../assets/icons/unlike.svg";
import { Content } from "./styled";
import { Card } from "../styled";

function CardOrganiizacao({onClick}){
  return(
    <Card>
      <Content>
        <section>
          <div className="image">
          <img src={doar_image} alt="organizacao 1"/>
        </div>
        </section>

        <section>
          <div className="description">
            <section className="info">
              <div>
                <h4>Plantio Brasil</h4>
                <div className="info-localizacao">
                  <img src={localizacao_icon} alt="Icone de localização"/>
                  <span>Espirito Santo</span>
                </div>
              </div>

              <div>
                <button type="button">
                  <img src={unlike_icon} alt="Icone de curtir"/>
                </button>
              </div>

            </section>

            <section>
              <div className="about">
                <span>Plantio Brasil é um movimento organizado para o plantio de árvores.</span>
              </div>
            </section>
            
          </div>
        </section>

        <section>
          <div className="bottom">
            <button type="button" onClick={onClick}>Ver mais</button>
          </div>
        </section>

      </Content>
    </Card>      
  );
}

export default CardOrganiizacao;