import Usuario from "../../../models/Usuario";
import CustomHandle from "../../../utils/CustomHandle";

class ControllerAuth {
  constructor(usuario, errors, setUsuario, setErrors) {
    this.usuario = usuario;
    this.errors = errors;
    this.setUsuario = setUsuario;
    this.setErrors = setErrors;
  }

  validarEmail(e) {
    this.setUsuario({ ...this.usuario, email: e.target.value });
    const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!regexEmail.test(e.target.value)) {
      this.setErrors({ ...this.errors, email: "Digite um e-mail válido", geral: "" });
    } else {
      this.setErrors({ ...this.errors, email: "", geral: "" });
    }
  }

  validarSenha(e) {
    this.setUsuario({ ...this.usuario, senha: e.target.value });

    if (e.target.value.length < 6) {
      this.setErrors({ ...this.errors, senha: "A senha deve conter pelo menos 6 caracteres", geral: "" });
    } else {
      this.setErrors({ ...this.errors, senha: "", geral: "" });
    }

    if (this.usuario.confirmar_senha) {
      this.validarConfirmarSenha({ target: { value: this.usuario.confirmar_senha } }, e.target.value);
    }
  }

  validarConfirmarSenha(e, senha = false) {
    if(!senha)
      this.setUsuario({ ...this.usuario, confirmar_senha: e.target.value });
    
    if ((senha && e.target.value !== senha) || (!senha && e.target.value !== this.usuario.senha)) {
      this.setErrors({ ...this.errors, confirmar_senha: "As senhas digitadas não são iguais", geral: "" });
    } else {
      this.setErrors({ ...this.errors, confirmar_senha: "", geral: "" });
    }
  }

  validarSobre(e) {
    this.setUsuario({ ...this.usuario, sobre: e.target.value });
    this.setErrors({ ...this.errors, sobre: "", geral: "" });
  }

  async handlerSubmit(e, navigate, setLoading){
    e.preventDefault();
    const submitAction = async ()=> await Usuario.login(this.usuario);
    const onSuccess = (response)=>{
      let usuario = response.data;
      sessionStorage.setItem('usuario', JSON.stringify(usuario));
      navigate('/plantar');
    };

    await CustomHandle.submitForm('form-login', submitAction, this.errors, this.setErrors, setLoading, onSuccess);
    setTimeout(()=>this.setErrors({...this.errors, geral : ''}), 3000);
  }
}

export default ControllerAuth;
