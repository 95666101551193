import styled from "styled-components";

export const Main = styled.div`
  display: flex;
  justify-content: center;
  overflow-y: scroll;

  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
  z-index: 1000;
`

export const Container = styled.div`
  position: relative;
  width: 450px;
  background-color: #fff;
  border-radius: 10px;
  padding: 34px;
  margin-top: 1vh;
  height: 98vh;
  overflow-y: scroll;

  textarea{
    padding: 10px;
    height: 100px;
    resize: none;
  }

  .title{
    h2{
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 120%;
    }
  }

  .close{
    display: flex;
    width: 100%;
    justify-content: flex-end;

    button{
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: none;
      width: 19px;
      height: 19px;
      cursor: pointer;

    }
  }

  .add-adm-item, .add-adm-item-list{
    display: flex;
    align-items: center;
    cursor: pointer;
    .add-button{
      margin-top: 10px;
      margin-left: 10px;
    }

    input{
      width: 280px !important;
    }

    .prefix{
      margin-top: -32px !important;
    }

  }

  .add-adm-item-list{
    .profile{
      margin-top: -40px !important;
      width: 32px;
      height: 32px;
      border: 1px solid var(--primary-color);
      
    }
  }  

  #foto_capa{
    display: none;
  }
  
`