import styled from "styled-components";

export const Content = styled.div`
  label{
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
  }

  .input-area{
    margin-top: 18px;
    width: 100% !important;
    border-radius: 5px 5px 0 0;
    padding: 18px 24px;
    border: 1px solid #cecece;
    display: flex;
    flex-direction: column;

    img{
      width: 60%;
      border-radius: 6px;
      margin-top: 10px;
    }
  }

  #form-postagem{
    margin-bottom: 36px ;
  }

  textarea {
    resize: none;
    width: 100% !important;
    min-height: 5vw;
    margin: 0;
    border: none;
    outline: none !important; 
  }

  .subimit-area{
    display: flex;
    justify-content: space-between;
    align-items: center;

    border: 1px solid #cecece;
    border-radius: 0 0 5px 5px;
    height: 47px;
    padding: 0 25px;

    label{
      margin: 0 !important;
      img{
        cursor: pointer;
        width: 20px;
      }
    }
            
    button{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 26px;
      margin-top: -42px;
    }
  }
`