import Formulario from "../Formulario";
import perfil_imagem from "../../../assets/perfil.png";
import { Content } from "./styled";
import CircularImage from "../CircularImage";
import { useState } from "react";
import { tiposPublicacao } from "../../../utils/data";

function ComentarioInput({usuario, handleComentar, validarTexto, comentario, loading, tipo}){

  return(
    <Content>
      <Formulario>
        <label htmlFor="comentario">
          <div className="coment-area">
            <CircularImage src={usuario?.foto_perfil?.path || perfil_imagem} alt="Foto perfil do participante"/>
            <textarea value={comentario.texto} rows={5} id="comentario" placeholder={`Escreva ${tipo === 'Fórum' || tipo === 'Grupo' ? "seu comentário" : "sua resposta"} aqui...`} onChange={validarTexto}/>
          </div>
        </label>
        <div className="subimit-area">
          <button className={(comentario.texto && !loading) && "active"} type="button" onClick={(comentario.texto && !loading) ? handleComentar : ()=>{}}>Publicar</button>                  
        </div>
        
      </Formulario>
    </Content>
  );
}

export default ComentarioInput;