import Grupo from "../../../../models/Grupo";
import Postagem from "../../../../models/Postagem";
import Tag from "../../../../models/Tag";
import CustomHandle from "../../../../utils/CustomHandle";
import { convertToBase64 } from "../../../../utils/conversoes";

class ControllerGrupo{
  constructor(usuario, grupo, errors, postagem, midia, setPostagem, setPostagens, setMidia, setErrors, setLoading){
    this.grupo = grupo;
    this.usuario = usuario;
    this.midia = midia;
    this.postagem = postagem;
    this.setPostagem = setPostagem;
    this.errors = errors;
    this.setErrors = setErrors;
    this.setMidia = setMidia;
    this.setLoading = setLoading;
    this.setPostagens = setPostagens;
  }

  async findGrupoById(setGrupo, grupo_id){
    this.setLoading(true);
    const result = await Grupo.findById(grupo_id);
    const onSuccess = (response)=>{
      setGrupo(response);
    };
    CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);
    this.setLoading(false);
  }

  async findSubgruposByGrupoId(setSubgrupos, grupo_id){
    this.setLoading(true);
    const result = await Grupo.findSubgruposByGrupoId(grupo_id);
    const onSuccess = (response)=>{
      setSubgrupos(response);
    };
    CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);
    this.setLoading(false);
  }

  async validarTexto(e, setTag) {
    const { postagem, setPostagem, setErrors } = this;
    setPostagem({ ...postagem, texto: e.target.value });
    setErrors({});

    const texto = document.getElementById("post-content");
    texto.style.height = 'auto';
    texto.style.height = texto.scrollHeight + 'px'; 

    this.checkWord(e, setTag);
    
  }

  completaTag(nome, setTag) {
    const textarea = document.getElementById("post-content");
    const conteudo = textarea.value;
    const cursorPos = textarea.selectionStart;
    const palavrasHashtag = conteudo.match(/#[a-zA-Z0-9_-]+\b/g);
  
    let hashtagSelecionada = null;
    if (palavrasHashtag) {
      for (const hashtag of palavrasHashtag) {
        const hashtagIndex = conteudo.indexOf(hashtag);
        if (cursorPos >= hashtagIndex && cursorPos <= hashtagIndex + hashtag.length) {
          hashtagSelecionada = hashtag;
          break;
        }
      }
    }
  
    if (hashtagSelecionada) {
      const hashtagCompleta = `#${nome} `;
      const novoConteudo = conteudo.replace(hashtagSelecionada, hashtagCompleta);
      this.setPostagem({...this.postagem, texto : novoConteudo});
    }

    setTag('');
  }

  async findTags(tag, setTags){
    if(tag && tag !== "#"){
      const result = await Tag.findSearch(tag.slice(1));
      const onSuccess = (response)=>{
        setTags(response);
      };

      CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);
    }else{
      setTags({});
    }
  }

  handleSelectMidia(e, setMidia){
    const { files } = e.target;
    setMidia(files[0]);
    this.setErrors({});
  }

  tratarPostagem(postagem){
    let new_postagem = { ...postagem };

    new_postagem.tipo = 'Grupo';
    new_postagem.usuario_id = this.usuario.id;
    new_postagem.estado = this.usuario.estado;
    new_postagem.grupo_id = this.grupo.id;

    return new_postagem;
  }

  limparCampos(){
    document.getElementById('midia').value = null;
    this.setMidia(null);
    this.setPostagem({...Postagem.model});
  }

  async handleSubmit(e, setLoadingSubtitulo) {
    
    e.preventDefault();
    setLoadingSubtitulo("Estamos compartilhando seus pensamentos com o mundo...");
    if(this.midia){
      convertToBase64([this.midia])
      .then(async (base64Array) => {  
        const new_postagem = this.tratarPostagem({...this.postagem, midia : base64Array[0]});   
        const submitAction = async ()=> await Postagem.create(new_postagem);
        const onSuccess = ()=>{
          this.limparCampos();
          this.findPostagensByGrupoId(this.setPostagens, 1, this.grupo.id);
          
        }
        await CustomHandle.submitForm('form-postagem', submitAction, this.errors, this.setErrors, this.setLoading, onSuccess);
      })
      .catch(()=>{
        this.setErrors({...this.errors, midias : "Ocorreu um erro ao tratar a imagem. Tente novamente"})
      });
    }else{
      const new_postagem = this.tratarPostagem(this.postagem);   
      const submitAction = async ()=> await Postagem.create(new_postagem);
      const onSuccess = ()=>{
        this.findPostagensByGrupoId(this.setPostagens, 1, this.grupo.id);
        this.limparCampos();
      }

      await CustomHandle.submitForm('form-postagem', submitAction, this.errors, this.setErrors, this.setLoading, onSuccess);
    }
  }  

  checkWord(e, setTag) {
    const textarea = e.target;
    const cursorPosition = textarea.selectionStart;
    const content = textarea.value;
    const words = content.split(/\s/); 
    

    const wordWithCursor = words.find(word => {
      const wordStart = content.indexOf(word);
      return wordStart <= cursorPosition && cursorPosition <= wordStart + word.length && word.startsWith("#");
    });
  
    if (wordWithCursor) {
      setTag(wordWithCursor);
      const textareaRect = textarea.getBoundingClientRect()
      let sleep = 0;

      if(!document.getElementById('tooltip')){
        sleep = 500;
      }

      setTimeout(()=>{
        const tooltip = document.getElementById('tooltip');
        if(tooltip){
          tooltip.style.top = `${textareaRect.y + textareaRect.height/2}px`;
          tooltip.style.left = `${textareaRect.x}px`;
        }
      }, sleep);
        
        
   
    }else{
      setTag('');
    }
  }

  voltar(setPostagemSelected){
    setPostagemSelected(null);
    setTimeout(()=>{
      const postagemBack = document.getElementById('selected');
      const content = document.getElementById('content');
      const buttonPosition = postagemBack?.getBoundingClientRect().top;
      content.scrollTo({
        top: buttonPosition - 100,
        behavior: 'smooth'
      });      
    },300);

  }

  selecionaPostagem(setPostagemSelected, postagem, postagens){
    setPostagemSelected(postagem);
    setTimeout(()=>{
      const buttonBack = document.getElementById('button-back');
      const content = document.getElementById('content');
      const buttonPosition = buttonBack.getBoundingClientRect().top;
      content?.scrollTo({
        top: buttonPosition - 100,
        behavior: 'smooth'
      });      
    },300);

    
    
    const new_postagens = {...postagens};
    new_postagens.rows.forEach(item => {
      if(item === postagem){
        item.selected = true;
      }else{
        item.selected = false;
      }
    });
    this.setPostagens(new_postagens);
    
  }

  async handleLike(postagem, postagens){
    const result = await Postagem.like(this.usuario, postagem);
    const onSuccess = (response)=>{
      const new_postagens = {...postagens};
      new_postagens?.rows?.forEach(item => {
        if(item === postagem){
          if(response){
            item.quantidade_curtidas = parseInt(item.quantidade_curtidas) + 1;
            item.curtiu = true;
          }else{
            item.quantidade_curtidas = parseInt(item.quantidade_curtidas) - 1;
            item.curtiu = false;
          }
        }
      });
      this.setPostagens(new_postagens);
    };

    CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);
  }

  async findPostagensByGrupoId(setPostagens, page, grupo_id){
    this.setLoading(true);

    const result = await Postagem.findAllGrupo(page, grupo_id);
    const onSuccess = (response)=>{
      if(page === 1){
        setPostagens(response);
      }else{
        const new_postagens = response.rows;
        setPostagens(postagens => ({...postagens, rows : [...this.postagens.rows, ...new_postagens]}));
      }
    };

    CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);

    this.setLoading(false);
  }
}

export default ControllerGrupo;