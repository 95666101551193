import styled from "styled-components";

export const Content = styled.div`
  
  .description{
    
    .info{
      .participantes{
        display: flex;
        align-items: center;

        .profile:not(:first-child) {
          margin-left: -10px;
        }

        h6{
          font-style: normal;
          font-weight: 500;
          font-size: 11px;
          line-height: 150%;
          margin-left: 8px;
          color: #8F8F8F;
        }

      }  

      .about{
        margin-top: 12px;
        span{
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 150%;
        }
      }
    }
  }



  .bottom{
    display: flex;
    margin: 20px 18px;
    
    a, button{
      width: 100%;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px;
      color: #fff;
      border-radius: 5px;
      text-decoration: none;
      background-color: var(--primary-color);
      border: none;
      cursor: pointer;

      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 150%;
      margin: 0 !important;
    }

  }
`;
