import Plantio from "../../../../models/Plantio";
import CustomHandle from "../../../../utils/CustomHandle";

class ControllerMapa {

  constructor(errors, setErrors, setLoading, setPlantios){
    this.errors = errors;
    this.setErrors = setErrors;
    this.setLoading = setLoading;
    this.setPlantios = setPlantios;
  }

  nextImage() {
    const imagesContainer = document.querySelector('.images');
    if (imagesContainer.scrollLeft + imagesContainer.clientWidth >= imagesContainer.scrollWidth) {
      imagesContainer.scrollTo({
        left: 0,
        behavior: 'smooth'
      });
    } else {
      imagesContainer.scrollTo({
        left: imagesContainer.scrollLeft + imagesContainer.clientWidth,
        behavior: 'smooth'
      });
    }
  }
    
  previousImage() {
    const imagesContainer = document.querySelector('.images');
    if (imagesContainer.scrollLeft === 0) {
      imagesContainer.scrollTo({
        left: imagesContainer.scrollWidth,
        behavior: 'smooth'
      });
    } else {
      imagesContainer.scrollTo({
        left: imagesContainer.scrollLeft - imagesContainer.clientWidth,
        behavior: 'smooth'
      });
    }
  }

  getLoacation(setMapCenter){
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setMapCenter({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.log("Erro ao obter a posição do usuário:", error);
        }
      );
    }
  }

  async findPlantios(usuario_id){
    if(usuario_id){
      this.setLoading(true);

      const result = await Plantio.findAll(usuario_id);
      const onSuccess = (response)=>this.setPlantios(response);
      CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);
    

      this.setLoading(false);
    }
  }
}
  
export default ControllerMapa;
  