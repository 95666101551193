import { Main } from "../styled";
import Formulario from "../../../components/Formulario";
import login_image from "../../../../assets/login-image.png";
import eye_icon from "../../../../assets/icons/eye.svg";
import eye_show_icon from "../../../../assets/icons/eye-show.svg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Loading from "../../../components/Loading";
import ControllerAuth from "../controller";
import AlertDialog from "../../../components/AlertDialog";
import ControllerRedefinirSenha from "./controller";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../../utils/config";

function RedefinirSenha({usuario, errors, setUsuario, setErrors}){
  const [loading, setLoading] = useState(false);
  const [showSenha, setShowSenha] = useState(false);
  const [showConfirmarSenha, setShowConfirmarSenha] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');

  const controllerAuth = new ControllerAuth(usuario, errors, setUsuario, setErrors);
  const controllerRedefinirSenha = new ControllerRedefinirSenha(usuario, setUsuario, errors, setErrors, setLoading, setShowSuccess);

  function isMobileDevice() {
    const userAgent = navigator.userAgent;
    const mobileKeywords = ["Mobile", "Android", "iPhone", "iPad", "Windows Phone"];

    return mobileKeywords.some((keyword) => userAgent.includes(keyword));
  }


  return(
    <Main>
      {loading && <Loading titulo={"Aguarde um momento"} subtitulo={"Estamos validando suas informações"}/>}
      {showSuccess && 
        <AlertDialog 
          titulo={ token ?  `Senha alterada com sucesso! ${isMobileDevice() && 'Acesse o aplicativo para fazer login'}` : `E-mail enviado com sucesso!`} 
          buttonText={isMobileDevice() ? "Sair" : "Fazer login"} 
          buttonOnClick={()=> isMobileDevice() ? window.location.href = '/' : navigate('/login')}
        />
      }
      <div className="content">
        
        <section className="left-section center-content">
          <div className="painel">
            <div className="top">
              <h2>Redefina sua Senha</h2>
              <div className="description">
                <span>
                  { token ? 
                    'Sua senha deve conter no mínimo 6 caracteres.' :
                    'Digite o email que você usa para entrar em sua conta e receba um link para redefinir sua senha.'
                  }
                </span>
              </div>
            </div>

            <div className="form">
              <Formulario id="form-redefinir-senha">
                <div className="form-col">

                  { token ?
                    <>
                      <div className="form-group">
                        <label htmlFor="senha">Nova Senha</label>
                        <input type={showSenha ? "text" : "password"} id="senha" name="senha" placeholder="Insira sua senha" required autoComplete="off"
                          onChange={(e)=>controllerAuth.validarSenha(e)} className={errors.senha && 'input-error'}
                        />
                        <img src={showSenha ? eye_show_icon : eye_icon} alt="Icone ver senha" className="sufix" onClick={()=>setShowSenha(!showSenha)}/>
                        <span className="error">{errors.senha}</span>
                      </div>

                      <div className="form-group">
                        <label htmlFor="confirmar_senha">Repita nova senha</label>
                        <input type={showConfirmarSenha ? "text" : "password"} id="confirmar_senha" name="confirmar_senha" placeholder="Confirme sua senha" required autoComplete="off"
                          onChange={(e)=>controllerAuth.validarConfirmarSenha(e)} className={errors.confirmar_senha && 'input-error'}
                        />
                        <img src={showConfirmarSenha ? eye_show_icon : eye_icon} alt="Icone ver senha" className="sufix"  onClick={()=>setShowConfirmarSenha(!showConfirmarSenha)}/>
                        <span className="error">{errors.confirmar_senha}</span>
                      </div> 
                    </> :

                    <div className="form-group">
                      <label htmlFor="email">E-mail</label>
                      <input type="text" name="email" id="email" placeholder="Insira seu e-mail" required
                        value={usuario.email} onChange={(e)=>controllerAuth.validarEmail(e)} className={errors.email && 'input-error'}
                      />
                      <span className="error">{errors.email}</span>
                    </div>
                    
                  }
                  
                  <div className="form-group">
                    <span className="error">{errors.geral}</span>
                  </div>

                  <div className="form-group">
                    <button type="submit" onClick={(e)=>controllerRedefinirSenha.handlerSubmit(e, token)}>{token ? 'Redefinir senha' : 'Enviar email'}</button>
                  </div>

                </div>
              </Formulario>
            </div>
          </div>
        </section>

        <section className="right-section">
          <img src={login_image} alt="Crianca plantando uma muda"/>
        </section>

      </div>
    </Main>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RedefinirSenha);