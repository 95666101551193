import Grupo from "../../../../models/Grupo";
import CustomHandle from "../../../../utils/CustomHandle";

class ControllerGrupos {
  constructor(usuario, errors, setErrors, setLoading) {
    this.usuario = usuario;
    this.setLoading = setLoading;
    this.errors = errors;
    this.setErrors = setErrors;
  }

  async findMeusGrupos(meusGrupos, setMeusGrupos, page){
    this.setLoading(true);

    const result = await Grupo.findByUsuarioId(this.usuario.id, page);
    
    const onSuccess = (response)=>{
      if(page <= 1){
        setMeusGrupos(response);
      }else{
        const new_grupos = response.rows;
        setMeusGrupos({...meusGrupos, rows : [...meusGrupos.rows, ...new_grupos]});
      }
    };

    CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);

    this.setLoading(false);
  }

  async participar(grupo, setGrupo){
    this.setLoading(true);

    const result = await Grupo.participar(this.usuario.id, grupo.id);
    
    const onSuccess = (response)=>{
      setGrupo({...grupo, is_solicitado : !grupo.is_solicitado });
    };

    CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);

    this.setLoading(false);
  }

  async findAllGrupos(grupos, setGrupos, page){
    this.setLoading(true);

    const result = await Grupo.findAll(page);
    
    const onSuccess = (response)=>{
      if(page <= 1){
        let new_grupos = response;
        new_grupos.rows = new_grupos.rows.filter(grupo => !grupo.is_participante);
        setGrupos(new_grupos);
      }else{
        let new_grupos = response;
        new_grupos.rows = new_grupos.rows.filter(grupo => !grupo.is_participante);
        new_grupos = new_grupos.rows;
        setGrupos({...grupos, rows : [...grupos.rows, ...new_grupos]});
      }
    };

    CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);

    this.setLoading(false);
  }
}

export default ControllerGrupos;
