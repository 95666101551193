import api from '../services/api';

const Plantio = {
  model: {titulo : '', endereco : '', cidade: '', estado: '', startConfig: true, coordenadas: [],  especies : [], tipo : '', tipo_categoria: '', midias: []},
  
  findSearch : async function (usuario_id, page, search, tipo_categoria, limit=6) {
    search = search ? search : ' ';
    return await api.get(`/plantios/usuario/${usuario_id}/${page}/${limit}?search=${search}${tipo_categoria ? `&tipo_categoria=${tipo_categoria}` : ''}`)
    .then(response=>response)
    .catch(error=>error);
  },


  findGraph : async function (fromDate, toDate) {
    return await api.get(`/plantios/graph/data/${fromDate}/between/${toDate}/tipo-categoria/todos`)
    .then(response=>response)
    .catch(error=>error);
  },
  
  findGraphByState : async function (fromDate, toDate, estado) {
    return await api.get(`/plantios/graph/data/${fromDate}/between/${toDate}/tipo-categoria/todos/estado/${estado}`)
    .then(response=>response)
    .catch(error=>error);
  },

  findAll : async function (usuario_id) {
    return await api.get(`/plantios/usuario/${usuario_id}/1/10`)
    .then(response=>response)
    .catch(error=>error);
  },

  findById : async function (id) {
    return await api.get(`plantios/id/${id}`)
    .then(response=>response)
    .catch(error=>error);
  },

  create : async function (data) {
    const newData = JSON.parse(JSON.stringify(data));
    newData.especies.forEach(especie=>{
      delete especie.especie
    });

    return await api.post(`plantios`, newData)
    .then(response=>response)
    .catch(error=>error);
  },

  addMidias : async function (data) {
    return await api.post(`plantios/add/midia`, data)
    .then(response=>response)
    .catch(error=>error);
  },

  deleteMidia : async function (plantio_id, midia_id) {
    return await api.delete(`plantios/midia/${plantio_id}/${midia_id}`)
    .then(response=> response)
    .catch(error=>error)
  }
}

export default Plantio;
