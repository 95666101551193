import Formulario from "../../../Formulario";
import { Card } from "../../styled";
import { Content } from "../styled";
import { useState } from "react";
import Select from "../../../Select";
import Bioma from "../../../../../models/Bioma";
import { useEffect } from "react";

function CardBiomas({onCreate, onEdit}){
  const [nomeBioma, setNomeBioma] = useState("");
  const [bioma, setBioma] = useState({});
  const [biomas, setBiomas] = useState({});
  const [errors, setErrors] = useState({});
  const [showOptions, setShowOptions] = useState(false);

  async function filtrarBiomas(e) {
    const searchValue = e.target.value;
    setNomeBioma(searchValue);

    let newBiomas = [];

    if (searchValue) {
      newBiomas = await Bioma.findSearch(searchValue);
    } else {
      newBiomas = await Bioma.findAll();
    }

    setBiomas(newBiomas.data);
  }

  function onClickSelectBioma(bioma) {
    setNomeBioma(bioma.nome);
    setBioma(bioma);
    setErrors((prevErrors) => ({ ...prevErrors, bioma: '' }));
  }

  async function findAllBiomas() {
    const allBiomas = await Bioma.findAll();
    setBiomas(allBiomas.data);
  }

  useEffect(() => {
    findAllBiomas();
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className="card-gerenciamento">
      <Content>
        <h3>Biomas</h3>
        <h4>Edite ou adicione novos biomas.</h4>
        <Formulario>
         
          <div className="form-group">
            <label htmlFor="biomas">Lista de biomas</label>
            <Select 
              id="biomas" 
              name="biomas" 
              placeholder="Pesquise por biomas aqui" 
              required 
              autoComplete="off"
              value={nomeBioma} 
              onChange={filtrarBiomas} 
              className={`input-prefix ${errors.biomas && 'input-error'}`}
              options={biomas?.rows}
              onClickOption={onClickSelectBioma}
              showOptions={showOptions}
              setShowOptions={setShowOptions}
            />
            <span className="error">{errors.bioma}</span>
          </div>

          <div className="form-group">
            <button className="edit-button" type="button" onClick={()=>onEdit(bioma)}>Editar bioma</button>
          </div>

          <div className="form-group">
            <button className="add-button" type="button" onClick={onCreate}>Adicionar um novo bioma</button>
          </div>
        </Formulario>
      </Content>
    </Card>    
  );
}

export default CardBiomas;

