import styled from "styled-components";

export const Content = styled.div`


  .panel{
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;

    .painel-empty{
      width: 100%;
      img{
        width: 110px;
      }
    }

    .left-section{
      width: 60%;

      .perfil{
        position: relative;
        height: 160px;
        width: 100%;
        
        
        .foto-capa{
          img{
            width: 100%;
            height: 100px;
            object-fit: cover;
            border-radius: 8px;
          }
        }

        .d-none{
          display: none;
        }

        .profile{
          position: absolute;
          width: 75px;
          height: 75px;
          margin-top: -45px;
          margin-left: 21px;
          border: 2px solid #F9F9F9;

          img{
            width: 100%;
            height: 100%;
          }
        }
      }

      
      .info{
        border-bottom: 1px solid #CFCFCF;
        .info-user{
          display: flex;
          justify-content: space-between;

          h4{
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 120%;
          }

          button{
            border: 1px solid #CFCFCF;
            border-radius: 4px;
            background-color: transparent;
            padding: 7px;
            cursor: pointer;

            color: var(--black-color) !important;
            margin-bottom: 1px !important;

            font-style: normal;
            font-weight: 500;
            font-size: 9px;
            line-height: 120%;
          }

          .info-localizacao{
            display: flex;
            margin-top: 7px ;
            img{
              width: 11px;
              margin-right: 6px;
            }

            span{
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 120%;
            }
          }
        }
        .info-about{
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 120%;

          margin-top: 18px;
          margin-bottom: 12px;
        }

      }
   
      .section-arvores{
        width: 100%;
        margin-top: 24px;
        margin-bottom: 20px;
        
        .top{
          h3{
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 120%;
          }

          h6{
            align-self: flex-end;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 120%;
            cursor: pointer;
          }
        }

        .arvores{
          display: flex;
          flex-wrap: wrap;
        }
        
      }
      
    }

    .right-section{
      width: 30%;
      padding-left: 39px;
      border-left: 1px solid #D9D9D9;
      display: flex;
      flex-direction: column;
      gap: 16px; 

      h3{
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
      }
    }
  }

 

  .button-edit-perfil, .button-edit-capa{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(200,200,200,0.5);
    cursor: pointer;
    object-fit: cover;
    border-radius: 8px;
    img{
      width: 34px !important;
      height: 34px !important;
    }
  }
  
  .button-edit-capa{
    top: 0;
    height: 100px;
    width: 100%;
  }

  .button-edit-perfil{
    width: 75px;
    height: 75px;
    margin-top: -45px;
    margin-left: 21px;
    border-radius: 75px;
  }

  .info-about{
    input{
      width: 100%;
      padding: 10px;
      border: 1px solid #CFCFCF ;
      border-radius: 5px;
    }
  }

  
`;
