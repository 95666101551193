import styled from "styled-components";
export const Content = styled.div`
  display: flex;
  background-color: #fff;
  width: 100%;
  height: 100vh;
  

  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  .especies-list{
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }

  .left-section{
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 5% 7%;
    overflow-y: scroll;

    .top{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    h2, p{
      font-style: normal;
      font-weight: 700;
      font-size: 42px;
      line-height: 120%;
      margin: 0;
    }

    span{
      font-size: 14px;
      line-height: 150%;
      margin-top: 15px;
      width: 70%;
    }

    .painel{
      .item{
        width: 100%;
        display: flex;
        flex-direction: column;
        border: 1px solid #CFCFCF;
        border-radius: 3px;
        padding: 12px 9px;
        margin-top: 14px;

        h4{
          color: #8B8B8B;
          font-size: 12px;
          font-weight: 600;
          line-height: 120%;
        }

        .info{
          width: 100%;
          display: flex;
          margin-top: 12px;
          justify-content: center;
          align-items: center;

          span{
            width: 100%;
            padding: 0;
            margin: 0;
            font-size: 14px;
            line-height: 120%;
            }

          img{
            width: 18px;
            margin-right: 5px;
          }
        }
      }

      .item-midias{
        h4{
          color: #8B8B8B;
          font-size: 14px;
          font-weight: 600;
          line-height: 150%;
          margin: 15px 0 12px 0;
        }

        .item-images{
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .gallery{
        position: relative;
        width: 291px;
        
        .next, .previus{  
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          
          
          button{
            display: flex;
            border: none;
            border-radius: 9px;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            height: 34px;
            width: 34px;
          }
        }

        .next{
          right: 10px;
        }

        .previus{
          left: 10px;
        }

        .images{
          display: flex;
          transition: transform 0.5s ease;
          overflow: hidden;

          .add-image{
            cursor: pointer;
          }

          .image{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 295px;
            height: 210px;
            background-color: #efefef;
            border-radius: 10px;
            
            img{
              width: 291px;
              height: 100%;
              object-fit: cover;
              border-radius: 10px;
            }
          }
        }
      }
    }

    .coordenadas{
      color: #838383;
      font-size: 12px;
      font-weight: 500;
      margin-top: 12px;
      
      span{
        font-size: 12px;
        line-height: 150%;
        color: var(--black, #4D4F50) !important;
      }
    }

    .atualizar-button{
      width: 100%;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px;
      color: #fff;
      border-radius: 5px;
      text-decoration: none;
      background-color: var(--primary-color);
      border: none;
      cursor: pointer;
      margin-top: 24px;

      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 150%;
    }
    
  }

  .right-section{
    width: 50%;
    height: 100vh;
    overflow: hidden;
    display: flex; 
    justify-content: center; 
    align-items: center; 

    img{
      width: 100%; 
    }
  }

  @media screen and (max-width: 796px){
    .right-section{
      display: none;
    }

    .left-section{
      width: 100%;
      padding-top: 60px;
      height: calc(100vh - 60px);
    }
  }
`