import styled from "styled-components";
export const Content = styled.div`

  .sized-box{
    height: 42px;
  }
    
  .card{
    margin-top: 40px;
    min-width: 295px;
    border-radius: 6px;
    background: var(#FCFEFC);
    box-shadow: 0px 2px 3px 0px rgba(36, 36, 36, 0.10);
    padding: 24px 33px;
    margin-right: 18px;
    height: 100%;

    .top{
      display: flex;
      width: 100%;
      justify-content: flex-start;
      border-bottom: 1px solid #CFCFCF;
      padding-bottom: 8px;

      img{
        width: 24px;
        margin-right: 9px;
      }

      span{
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;            
      } 
    }

    .list{
      .content-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .item{
        display: flex;
        flex-direction: column;
        padding-bottom: 6px;
          

        label {
          margin-top: 12px;
        }

        label, span{
          color: #8A8A8A ;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
        }

        span{
          color: #4D4F50;
          margin-top: 6px;

          a{
            color: #0B8CCD;
            text-decoration: none;
          } 
        }

        img{
          cursor: pointer;
        }
      }

      .check{
        cursor: pointer;
        position: relative;
        width: 15px;
        height: 15px;
        border-radius: 5px;
        border: 1px solid #CFCFCF;
        margin-right: 14px;
        

        input {
          margin-top: 0;
          width: 15px;
          display: none;
          cursor: pointer;
        }
        
      }

      .checked{
        background-color: var(--primary-color);
        border: none;

        :after{
          content: "";
          position: absolute;
          top: 2px;
          left: 5px;
          width: 3px;
          height: 6px;
          border: solid #fff;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotare(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }

      .border{
        border-bottom: 1px solid #CFCFCF;
      }
    }

  button{
    width: 100%;
    border-radius: 6px;
    height: 36px;
    margin-top: 12px;
    cursor: pointer;
  }

  .delete-account{
    border: 0.5px solid #D41A1A;
    background: #D41A1A;
    color: #fff;
  }

    .logout{
      border: 1px solid var(--black-color, #4D4F50);
      background-color: transparent;
      margin-top: 50px !important;
      color: var(--black-color, #4D4F50);;
    }
  }

  .modal{
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(50,50,50,0.5);
    display: flex;
    align-items: center;
    justify-content: center;

    .content-modal{
      width: 450px;
      background-color: #fff;
      border-radius: 6px;
      padding: 18px 28px;

      .top{

        display: flex !important;;

        h3{
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
        }

        img, button{
          width: 24px;
          height: 24px;
          background-color: transparent;
          border: none;
          cursor: pointer;
          margin: 0 !important ;
        }
      }

      .input-code{
          width: 44px;
          height: 52px;
          margin-right: 9px;
          text-align: center;
          background-color: #EDEDED;
          font-size: 15pt;
        }

        .resend-code{
          margin-top: 27px;
          font-size: 10px;
          max-width: 170px;
          height: 27px;
          font-weight: normal;
          background-color: #9A9A9A;

          :disabled{
            background-color: #FDFDFD;
            border: 1px solid #CFCFCF;
            color: #9A9A9A;
          }
        }

    }

    .form-group{
      button{
        margin-top: 10px !important ;
        margin-bottom: 5px !important;
      }
    }

  }
`