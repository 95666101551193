import { Main } from "../styled";
import Formulario from "../../../components/Formulario";
import login_image from "../../../../assets/login-image.png";
import eye_icon from "../../../../assets/icons/eye.svg";
import eye_show_icon from "../../../../assets/icons/eye-show.svg";
import cadeado_icon from "../../../../assets/icons/cadeado.svg";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import Loading from "../../../components/Loading";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../../utils/config";
import ControllerAuth from "../controller";
import Toast from "../../../components/Toast";

function Login({usuario, errors, setErrors, setUsuario}){
  const [loading, setLoading] = useState(false);
  const [showSenha, setShowSenha] = useState(false);
  const navigate = useNavigate();

  const controllerAuth = new ControllerAuth(usuario, errors, setUsuario, setErrors);

  return(
    <Main>
      {loading && <Loading titulo={"Aguarde um momento"} subtitulo={"Estamos validando suas informações"}/>}
      <Toast error={errors.geral}/>

      <div className="content">
        
        <section className="left-section center-content">
          <div className="painel">
            <div className="top">
              <h2>Bem Vindo de Volta!</h2>
              <div className="description">
                <span>Entre em sua conta para continuar nos ajudando em nossa missão.</span>
              </div>
            </div>

            <div className="form">
              <Formulario id="form-login">
                <div className="form-col">
                  <div className="form-group">
                    <label htmlFor="email">E-mail</label>
                    <input type="text" name="email" id="email" placeholder="Insira seu e-mail" required
                      value={usuario?.email} onChange={(e)=>controllerAuth.validarEmail(e)} className={errors.geral && 'input-error'}
                    />
                    <span className="error">{errors.email}</span>
                  </div>

                  <div className="form-group">
                    <label htmlFor="senha">Senha</label>
                    <input type={showSenha ? "text" : "password"} name="senha" id="senha" placeholder="Insira sua senha" required
                      value={usuario?.senha} onChange={(e)=>controllerAuth.validarSenha(e)} className={errors.geral && 'input-error'}
                    />
                    <img src={ showSenha ? eye_show_icon : eye_icon } alt="Icone ver senha" className="sufix" onClick={()=>setShowSenha(!showSenha)}/>
                    <span className="error">{errors.senha}</span>
                  </div>

                  <div className="forgot-password">
                    <img src={cadeado_icon} alt="Icone ver senha" className="sufix"/>
                    <span onClick={()=>navigate('/redefinir-senha')}>Esqueci minha senha</span>
                  </div>


                  <div className="form-group">
                    <button type="submit" onClick={(e)=>controllerAuth.handlerSubmit(e, navigate, setLoading)}>Entrar</button>
                  </div>

                  <div className="form-group">
                    <span className="have-account">
                      Não tem uma conta? <Link to={'/cadastro'}>Crie agora</Link>
                    </span>
                  </div>

                </div>
              </Formulario>
            </div>
          </div>
        </section>

        <section className="right-section">
          <img src={login_image} alt="Crianca plantando uma muda"/>
        </section>

      </div>
    </Main>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);