import styled from "styled-components";

export const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  
`

export const Container = styled.div`
  width: 350px;
  background-color: #fff;
  border-radius: 10px;
  max-height: 90vh;
  padding: 34px;
  overflow-y: scroll ;
  

  .title{
    h2{
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 120%;
    }
  }

  .close{
    display: flex;
    width: 100%;
    justify-content: flex-end;

    button{
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: none;
      width: 19px;
      height: 19px;
      cursor: pointer;

    }
  }

  .painel{
    .card{
      display: flex;
      flex-direction: column !important;
      align-items: center;

      span{
        margin-top: 10px;
        color: #555 ;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }

      .grupo{
        color: var(--primary-color);
      }

      button{
        width: 250px;
        padding: 10px;
        border-radius: 6px;
        margin-top: 15px;
        cursor: pointer;

      }

      .aceitar{
        background-color: var(--primary-color);
        border: none;
        color: #fff;
      }

      .recusar{
        border: 2px solid #555;
        color: #555;
        background-color: transparent;
      }
    }
  }


  
`