import styled from "styled-components";

export const Card = styled.div`
  width: 100%;
  margin: 15px 0;
  padding: 10px;
  background: #FCFCFC;
  box-shadow: 0px 2px 2px rgba(36, 36, 36, 0.1);
  border-radius: 6px;
  padding: 15px 24px;

  .top{
    display: flex;
    justify-content: space-between;
    margin-bottom: 9px;

    .info-options{
      cursor: pointer;
      align-self: flex-start;
      img{
        width: 15px;
      }
    }
    
    .info-profile{
      display: flex;
      align-items: center;
      
      
      .profile{
        width: 39px;
        height: 39px;
        img{
          width: 39px;
        }
        border: 1px solid var(--primary-color);
      }

      h4{
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        margin-left: 5px;
      }

      .info-localizacao{
        display: flex;
        align-items: center;
        img{
          width: 14px;
          margin-right: 4px;
        }

        h6{
          color: #8F8F8F;
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 120%;
        }
      }
    }
  }

  .message{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    display: flex;
    flex-direction: column;

    img{
      margin-top: 20px;
      width: 60%;
      object-fit: cover;
      margin-right: 10px;
      border-radius: 6px ;
    }

    span{
      align-self: flex-start;
      justify-self: flex-start;
      width: 100%;
      word-wrap: break-word;
      margin: 0;
    }
  }

  .options{
    position: relative;
  }

  .item-options{
    position: absolute;
    background-color: #fff;
    z-index: 100;
    padding: 10px;
    right: 15px;
    top: 5px;
    cursor: pointer;
    width: 145px;
    box-shadow: 0px 2px 2px rgba(36, 36, 36, 0.1);

    :hover{
      font-weight: bold;
    }
  }


  .bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    .interactions{
      display: flex;
      align-items: center;
      margin-top: 10px;

      .likes, .coments{
        display: flex;
        align-items: center;

        img{
          width: 15px;
          cursor: pointer;
        }

        span{
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 120%;
          margin: 0 14px 0 5px;
        }
      }
    }

    .footer{
      button{
        width: 115px;
        height: 24px;
        background: #FFFFFF;
        box-shadow: 0px 1px 1px rgba(36, 36, 36, 0.1);
        border-radius: 6px;
        border: none;
        cursor: pointer;

        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 120%;
        color: var(--black-color);
        margin-top: -10px !important;
      }
    }

  }


`