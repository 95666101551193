import { Card } from "./styled";
import Circularimage from '../../CircularImage';
import localizacao_icon from '../../../../assets/icons/location.svg';
import opcoes_icon from '../../../../assets/icons/opcoes.svg';
import perfil_image from '../../../../assets/perfil.png';
import like_icon from '../../../../assets/icons/like.svg';
import unlike_icon from '../../../../assets/icons/unlike.svg';
import comentarios_icon from '../../../../assets/icons/comentarios.svg';
import { tiposPublicacao } from "../../../../utils/data";
import { useState } from "react";
import DeleteDialog from "../../DeleteDialog";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../../utils/config";

function CardPostagem({usuario, postagem, onClick, handleLike, handleComentar, tipo, onDelete}){
  const [showOptions, setShowOptions] = useState(false);
  const [showDeletarDialog, setShowDeletarDialog] = useState(false);
  return(
    <Card onClick={onClick}>
      {showDeletarDialog && 
        <DeleteDialog 
          titulo={`Deseja deletar a ${postagem.tipo === "Fórum" || postagem.tipo === "Grupo" ?  "Postagem" : postagem.tipo}?`} 
          subtitulo={"Tem certeza que deseja deletar este post? Esta ação é irrevercível."}
          onClose={()=>setShowDeletarDialog(false)}
          onDelete={()=>onDelete(setShowDeletarDialog)}

        />
      }

      <div className="top" id={postagem.selected && "selected"}>
        <div className="info-profile">
          <Circularimage src={postagem?.usuario?.foto_perfil?.path || perfil_image} alt="Perfil do participante"/>
          
          <div>
            <h4>{postagem?.usuario?.nome}</h4>
            <div className="info-localizacao">
              <img src={localizacao_icon} alt="Icone de localizacao"/>
              <h6>{postagem?.estado}</h6>
            </div>
          </div>
        </div>
        {postagem.usuario.id === usuario.id &&
          <div className="options">
            <div className="info-options" onClick={()=>setShowOptions(!showOptions)}>
              <img src={opcoes_icon} alt="Icone de opções"/>
            </div>
            {showOptions && 
              <div className="item-options">
                <span onClick={()=>{setShowDeletarDialog(true); setShowOptions(false)}}>Excluir {postagem.tipo === "Fórum" || postagem.tipo === "Grupo" ?  "Postagem" : postagem.tipo}</span>
              </div>
            }
          </div>
        }
        
         
      </div>

      <div className="message">
        <span> 
          {postagem.texto}
        </span>
        { postagem.midia && <img src={postagem.midia.path} alt="Imagem postada pelo usuário" /> }
      </div>

      <div className="bottom">
        <div className="interactions">
          
          <div className="likes" onClick={handleLike}>
            <img src={postagem.curtiu ? like_icon : unlike_icon} alt="Icone de like"/>
            <span>{postagem.quantidade_curtidas}</span>
          </div>

          { postagem.tipo !== "Resposta" &&
            <div className="coments" onClick={handleComentar}>
              <img src={comentarios_icon} alt="Icone de comentários"/>
              <span>{ postagem.quantidade_comentarios || postagem.quantidade_respostas }</span>
            </div>
          }

        </div>
        { postagem.tipo !== "Resposta" &&
          <div className="footer">
            { (tipo === tiposPublicacao.POSTAGEM || tipo === tiposPublicacao.COMENTARIO) &&
              <button type="button" onClick={handleComentar}>{tipo === tiposPublicacao.POSTAGEM ? "Comentar" : "Responder" }</button>
            }
          </div>
        }
      </div> 
    </Card>       
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CardPostagem);