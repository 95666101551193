import { Main, Container } from "./styled";
import close_icone from "../../../../assets/icons/close.svg";
import { useState } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../../utils/config";
import Loading from "../../../components/Loading";
import ControllerVerConvite from "./controller";
import AlertDialog from "../../../components/AlertDialog";

function VerConviteModal({solicitacao, grupo, onClose, errors, setErrors}){
  const [showSuccess, setShowSuccess] = useState();
  const [aceito, setAceito] = useState();
  const [loading, setLoading] = useState(false);
  const closeModal = onClose;

  const controllerVerConvite = new ControllerVerConvite(setLoading, errors, setErrors);

  
  return(
    <Main>
      {loading && <Loading titulo={"Aguarde"} subtitulo={"Estamos deixando tudo pronto para você..."}/>}
      {showSuccess &&
          <AlertDialog
            titulo={`Solicitação ${aceito? "aceita" : "recusada"} com sucesso!`} 
            buttonText={"Ver solicitações"} 
            buttonOnClick={()=>{
              setShowSuccess(false);
              onClose();
            }}
          />
        }
      <Container>
        <section>
          <div className="close" onClick={closeModal}>
            <button type="button">
              <img src={close_icone} alt="Icone de fechar"/>
            </button>
          </div>
        </section>

        <section>
          <div className="title">
            <h2>Solicitação de convite</h2>
          </div>
        </section>  

        <section className="painel">
            <section className="card">
              <span>{solicitacao?.usuario?.nome} quer entrar no grupo <span className="grupo">{grupo?.nome}</span></span>
              <button className="aceitar" onClick={()=>controllerVerConvite.aceitar(solicitacao.id, setShowSuccess, setAceito)}>Aceitar</button>
              <button className="recusar" onClick={()=>controllerVerConvite.recusar(solicitacao.id, setShowSuccess, setAceito)}>Recusar</button>
              
            </section>     
        </section>
      </Container>
    </Main>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(VerConviteModal);