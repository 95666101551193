import styled from "styled-components";

export const Content = styled.div`
  .back{
    width: auto;
    padding: 0 !important;
    margin-right: auto !important;
    margin-left: 0 !important;
    margin-bottom: 1px !important;
    background-color: transparent !important;
    color: var(--primary-color) !important;
    img{
      width: 8px !important;
    }
  }

  .panel{
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;

    .more{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--primary-color);
      cursor: pointer;

      :hover{
        font-weight: bold;
      }

    }

    .left-section{
      width: 60%;
    }

    .right-section{
      width: 33%;
      padding-left: 39px;
      margin-top: 40px;
      border-left: 1px solid #D9D9D9;

      h3{
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
      }

      .matters-section{
        width: 25vw;
      
        .item{
          width: 75%;
          background-color: #F9F9F9 ;
          padding: 6px 12px;
          font-weight: 500;
          margin: 24px 0;

          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 120%;
        }
      }

      .supporters-section{
        position: relative;
        hr{
          position: absolute;
          left: -40px;
          width: 30vw;
          border: 1px solid #D9D9D9;
          top: -30px;
        }
        h3{
          margin-top: 60px;
        }
      }
    }
  }
`;