import Patrocinador from "../../../../models/Patrocinador";
import Postagem from "../../../../models/Postagem";
import Tag from "../../../../models/Tag";
import CustomHandle from "../../../../utils/CustomHandle";
import { convertToBase64 } from "../../../../utils/conversoes";


class ControllerForum {
  constructor(page, errors, postagem, usuario, midia, postagens, search, setSearch, setPage, setMidia, setPostagem, setErrors, setPostagens, setLoading, setLoadingSubtitulo, setPatrocinadores) {
    this.page = page;
    this.setPostagens = setPostagens;
    this.setLoading = setLoading;
    this.errors = errors;
    this.midia = midia;
    this.setErrors = setErrors;
    this.postagem = postagem;
    this.setPostagem = setPostagem;
    this.usuario = usuario;
    this.setLoadingSubtitulo = setLoadingSubtitulo;
    this.setMidia = setMidia;
    this.postagens = postagens;
    this.setPage = setPage;
    this.search = search;
    this.setSearch = setSearch;
    this.setPatrocinadores = setPatrocinadores;
  }

  async validarTexto(e, setTag) {
    const { postagem, setPostagem, setErrors } = this;
    setPostagem({ ...postagem, texto: e.target.value });
    setErrors({});

    const texto = document.getElementById("post-content");
    texto.style.height = 'auto';
    texto.style.height = texto.scrollHeight + 'px'; 

    this.checkWord(e, setTag);
    
  }

  checkWord(e, setTag) {
    const textarea = e.target;
    const cursorPosition = textarea.selectionStart;
    const content = textarea.value;
    const words = content.split(/\s/); 

    const wordWithCursor = words.find(word => {
      const wordStart = content.indexOf(word);
      return wordStart <= cursorPosition && cursorPosition <= wordStart + word.length && word.startsWith("#");
    });
  
    if (wordWithCursor) {
      setTag(wordWithCursor);
      
      const textareaRect = textarea.getBoundingClientRect()
      let sleep = 0;

      if(!document.getElementById('tooltip')){
        sleep = 500;
      }

      setTimeout(()=>{
        const tooltip = document.getElementById('tooltip');
        if(tooltip){
          tooltip.style.top = `${textareaRect.y + textareaRect.height/2}px`;
          tooltip.style.left = `${textareaRect.x}px`;
        }
      }, sleep);
        
        
   
    }else{
      setTag('');
    }
  }
  

  selecionaPostagem(setPostagemSelected, postagem){
    setPostagemSelected(postagem);
    setTimeout(()=>{
      const buttonBack = document.getElementById('button-back');
      const content = document.getElementById('content');
      const buttonPosition = buttonBack.getBoundingClientRect().top;
      content.scrollTo({
        top: buttonPosition - 100,
        behavior: 'smooth'
      });      
    },300);
    
    const new_postagens = {...this.postagens};
    new_postagens.rows.forEach(item => {
      if(item === postagem){
        item.selected = true;
      }else{
        item.selected = false;
      }
    });
    this.setPostagens(new_postagens);
    
  }

  voltar(setPostagemSelected){
    setPostagemSelected(null);
    setTimeout(()=>{
      const postagemBack = document.getElementById('selected');
      const content = document.getElementById('content');
      const buttonPosition = postagemBack?.getBoundingClientRect().top;
      content.scrollTo({
        top: buttonPosition - 100,
        behavior: 'smooth'
      });      
    },300);

  }

  async deletePostagem(postagem_id, setShowDeletarDialog){
    this.setLoading(true);
    const result = await Postagem.delete(postagem_id);
    const onSuccess = ()=>{
      const rows = this.postagens.rows.filter(item => item.id !== postagem_id);
      this.setPostagens({...this.postagens, rows : rows, count : this.postagens.count - 1});
      setShowDeletarDialog(false);
    };

    CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);
    this.setLoading(false);
  }

  async handleLike(postagem){
    const result = await Postagem.like(this.usuario, postagem);
    const onSuccess = (response)=>{
      const new_postagens = {...this.postagens};
      new_postagens.rows.forEach(item => {
        if(item === postagem){
          if(response){
            item.quantidade_curtidas = parseInt(item.quantidade_curtidas) + 1;
            item.curtiu = true;
          }else{
            item.quantidade_curtidas = parseInt(item.quantidade_curtidas) - 1;
            item.curtiu = false;
          }
        }
      });
      this.setPostagens(new_postagens);
    };

    CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);
  }

  async findPostagens(page = this.page){
    this.setLoadingSubtitulo("Estamos preparando tudo pra você");
    this.setLoading(true);

    const result = await Postagem.findAllForum(page, this.search);
    const onSuccess = (response)=>{
      if(page === 1){
        this.setPostagens(response);
      }else{
        const new_postagens = response.rows;
        this.setPostagens({...this.postagens, rows : [...this.postagens.rows, ...new_postagens]});
      }
    };

    CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);

    this.setLoading(false);
  }

  async findTags(tag, setTags){
    if(tag && tag !== "#"){
      const result = await Tag.findSearch(tag.slice(1));
      const onSuccess = (response)=>{
        setTags(response);
      };

      CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);
    }else{
      setTags({});
    }
  }

  async findTagsMaisComentadas(setTags){
    const result = await Tag.findSearch(' ');
    const onSuccess = (response)=>{
      setTags(response);
    };

    CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);
  }

  async findPatrocinadores(){
    const result = await Patrocinador.findAll(' ');
    const onSuccess = (response)=>{
      this.setPatrocinadores(response['rows']);
    };

    CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);
  }


  tratarPostagem(postagem){
    let new_postagem = { ...postagem };

    new_postagem.tipo = 'Fórum';
    new_postagem.usuario_id = this.usuario.id;
    new_postagem.estado = this.usuario.estado;

    return new_postagem;
  }

  completaTag(nome, setTag) {
    const textarea = document.getElementById("post-content");
    const conteudo = textarea.value;
    const cursorPos = textarea.selectionStart;
    const palavrasHashtag = conteudo.match(/#[a-zA-Z0-9_-]+\b/g);
  
    let hashtagSelecionada = null;
    if (palavrasHashtag) {
      for (const hashtag of palavrasHashtag) {
        const hashtagIndex = conteudo.indexOf(hashtag);
        if (cursorPos >= hashtagIndex && cursorPos <= hashtagIndex + hashtag.length) {
          hashtagSelecionada = hashtag;
          break;
        }
      }
    }
  
    if (hashtagSelecionada) {
      const hashtagCompleta = `#${nome} `;
      const novoConteudo = conteudo.replace(hashtagSelecionada, hashtagCompleta);
      this.setPostagem({...this.postagem, texto : novoConteudo});
    }

    setTag('');
  }

  handleSelectMidia(e){
    const { files } = e.target;
    this.setMidia(files[0]);
    this.setErrors({});
  }

  handleSearch(e, typingTimeout, setTypingTimeout){
    clearTimeout(typingTimeout);
    const newTypingTimeout = setTimeout(() => {
      this.setPostagens({});
      this.setSearch(e.target.value);
      if(this.page!==1){
        this.setPage(1);
      }
    }, 500);
    setTypingTimeout(newTypingTimeout);
  };

  limparCampos(){
    document.getElementById('midia').value = null;
    this.setMidia(null);
    this.setPostagem({...Postagem.model});
  }

  async handleSubmit(e) {
    
    e.preventDefault();
    this.setLoadingSubtitulo("Estamos compartilhando seus pensamentos com o mundo...");
    if(this.midia){
      convertToBase64([this.midia])
      .then(async (base64Array) => {  
        const new_postagem = this.tratarPostagem({...this.postagem, midia : base64Array[0]});   
        const submitAction = async ()=> await Postagem.create(new_postagem);
        const onSuccess = ()=>{
          this.limparCampos();
          this.findPostagens(1);
          
        }

        await CustomHandle.submitForm('form-postagem', submitAction, this.errors, this.setErrors, this.setLoading, onSuccess);
      })
      .catch(()=>{
        this.setErrors({...this.errors, midias : "Ocorreu um erro ao tratar a imagem. Tente novamente"})
      });
    }else{
      const new_postagem = this.tratarPostagem(this.postagem);   
      const submitAction = async ()=> await Postagem.create(new_postagem);
      const onSuccess = ()=>{
        this.findPostagens(1);
        this.limparCampos();
      }

      await CustomHandle.submitForm('form-postagem', submitAction, this.errors, this.setErrors, this.setLoading, onSuccess);
    }
  }  
}

export default ControllerForum;
