import api from '../services/api';

const Usuario = {
  model : {nome: '', email: '', estado: '', cidade: '', celular: '', senha: '', confirmar_senha: '', termo: ''},
  
  login : async function (usuario) {
    return await api.post(`/login`, usuario)
    .then(response=>response)
    .catch(error=>error);
  },

  findSearch : async function (search, page=1) {
    search = search ? search : ' ';
    return await api.get(`/usuarios/data/${search}/${page}/8`)
    .then(response=>response)
    .catch(error=>error);
  },

  addMidia : async function (data) {
    return await api.post(`/usuarios/add/midia`, data)
    .then(response=>response)
    .catch(error=>error);
  },

  alterarSenha : async function (data) {
    return await api.post(`/usuarios/alterar-senha`, data)
    .then(response=>response)
    .catch(error=>error);
  },

  solicitarRedefinirSenha : async function (data) {
    return await api.post(`/usuarios/redefinir-senha/solicitacao`, data)
    .then(response=>response)
    .catch(error=>error);
  },

  redefinirSenha : async function (data) {
    return await api.post(`/usuarios/redefinir-senha`, data)
    .then(response=>response)
    .catch(error=>error);
  },


  findById : async function (id) {
    return await api.get(`usuarios/id/${id}`)
    .then(response=>response)
    .catch(error=>error);
  },

  create : async function (data) {
    if(!data.sobre){
      data.sobre = "";
    }
    delete data.foto_perfil;
    delete data.foto_capa;
    return await api.post(`usuarios`, data)
    .then(response=>response)
    .catch(error=>error);
  },

  delete : async function (usuario_id) {
    return await api.delete(`usuarios/id/${usuario_id}`)
    .then(response=>response)
    .catch(error=>error);
  },

  sendEmail : async function (usuario_id) {
    return await api.post('usuarios/email-confirmacao', {usuario_id})
    .then(response=>response)
    .catch(error=>error);
  },

  confirmEmail : async function (usuario_id, codigo) {
    return await api.post('usuarios/validar-codigo', {usuario_id, codigo})
    .then(response=>response)
    .catch(error=>error);
  },

  findGraph : async function (fromDate, toDate, userType) {
    return await api.get(`/usuarios/graph/data/${fromDate}/between/${toDate}/tipo-usuario/${userType}`)
    .then(response=>response)
    .catch(error=>error);
  },
}

export default Usuario;