class ControllerPlantioModal {

  nextImage() {
    const imagesContainer = document.querySelector('.images');
    if (imagesContainer.scrollLeft + imagesContainer.clientWidth >= imagesContainer.scrollWidth) {
      imagesContainer.scrollTo({
        left: 0,
        behavior: 'smooth'
      });
    } else {
      imagesContainer.scrollTo({
        left: imagesContainer.scrollLeft + imagesContainer.clientWidth,
        behavior: 'smooth'
      });
    }
  }

  previousImage() {
    const imagesContainer = document.querySelector('.images');
    if (imagesContainer.scrollLeft === 0) {
      imagesContainer.scrollTo({
        left: imagesContainer.scrollWidth,
        behavior: 'smooth'
      });
    } else {
      imagesContainer.scrollTo({
        left: imagesContainer.scrollLeft - imagesContainer.clientWidth,
        behavior: 'smooth'
      });
    }
  }

}

export default ControllerPlantioModal;
