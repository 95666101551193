import Formulario from "../../../Formulario";
import { Card } from "../../styled";
import { Content } from "../styled";
import Select from "../../../Select";
import Patrocinador from "../../../../../models/Patrocinador";
import { useState } from "react";
import { useEffect } from "react";

function CardPatrocinadores({onCreate, onEdit}){
  const [nomePatrocinador, setNomePatrocinador] = useState("");
  const [patrocinador, setPatrocinador] = useState({});
  const [patrocinadores, setPatrocinadores] = useState({});
  const [errors, setErrors] = useState({});
  const [showOptions, setShowOptions] = useState(false);

  async function filtrarPatrocinadores(e) {
    const searchValue = e.target.value;
    setNomePatrocinador(searchValue);

    let newPatrocinadores = [];

    if (searchValue) {
      newPatrocinadores = await Patrocinador.findSearch(searchValue);
    } else {
      newPatrocinadores = await Patrocinador.findAll();
    }

    setPatrocinadores(newPatrocinadores.data);
  }

  function onClickSelectPatrocinador(patrocinador) {
    setNomePatrocinador(patrocinador.nome);
    setPatrocinador(patrocinador);
    setErrors((prevErrors) => ({ ...prevErrors, patrocinador: '' }));
  }

  async function findAllPatrocinadores() {
    const allPatrocinadores = await Patrocinador.findAll();
    setPatrocinadores(allPatrocinadores.data);
  }

  useEffect(() => {
    findAllPatrocinadores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className="card-gerenciamento">
      <Content>
        <h3>Patrocinadores</h3>
        <h4>Procure e edite patrocinadores.</h4>
        <Formulario>
          <div className="form-group">
            <label>Nome do Patrocinador</label>
            <Select 
              id="patrocinador" 
              name="patrocinador" 
              placeholder="Pesquise por patrocinador aqui" 
              required 
              autoComplete="off"
              value={nomePatrocinador} 
              onChange={filtrarPatrocinadores} 
              className={`input-prefix ${errors.patrocinador && 'input-error'}`}
              options={patrocinadores?.rows}
              onClickOption={onClickSelectPatrocinador}
              showOptions={showOptions}
              setShowOptions={setShowOptions}
            />
            <span className="error">{errors.patrocinador}</span>
          </div>

          <div className="form-group">
            <button className="edit-button" type="button" onClick={()=>onEdit(patrocinador)}>Editar patrocinador</button>
          </div>

          <div className="form-group">
            <button className="add-button" type="button" onClick={onCreate}>Adicionar um novo patrocinador</button>
          </div>

        </Formulario>
      </Content>
    </Card>    
  );
}

export default CardPatrocinadores;

