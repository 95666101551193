import api from '../services/api';

const Bioma = {
  create : async function (data) {
    return await api.post('biomas', data)
    .then(response=>response)
    .catch(error=>error);
  },

  delete : async function (bioma_id) {
    return await api.delete(`biomas/id/${bioma_id}`)
    .then(response=>response)
    .catch(error=>error);
  },

  findAll : async function () {
    return await api.get('biomas/all/1/7')
    .then(response=>response)
    .catch(error=>error);
  },

  findSearch : async function (data) {
    return await api.get(`biomas/data/${data}/1/20`)
    .then(response=>response)
    .catch(error=>error);
  }
}

export default Bioma;
