import styled from "styled-components";

export const Content = styled.div`
  padding: 18px;

  h3{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
  }

  h4{
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 120%;
    margin-top: 9px;
  }

  .more-especies{
    color: var(--primary-color);
    font-style: normal;
    font-weight: 500 ;
    font-size: 12px;
    line-height: 150%;
    align-self: flex-end;
  }



  form{
    .form-group{
      margin: 0px;
      
      button{
        cursor: pointer;
        border-radius: 4px;
        margin-top: 10px !important;
        width: 100%;
      }

      .edit-button{
        background-color: transparent;
        color: var(--primary-color);
        border: 1.2px solid var(--primary-color);
        margin: 0 !important;
        margin-top: 10px !important;
      }

      .add-button{
        margin-bottom: 1px !important;
      }
    }
  }
 
`