import styled from "styled-components";

export const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  z-index: 1000;
  
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
`

export const Container = styled.div`
  width: 500px;
  background-color: #fff;
  border-radius: 8px;

  .image{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    height: 173px;
    border-radius: 8px 8px 0 0;

    img{
      height: 100%;
    }

    .close{
      position: absolute;
      top: 24px;
      right: 24px;

      button{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        cursor: pointer;
        background-color: #FCFEFC;
        border: none;
        border-radius: 4px;

        img{
          width: 10px;
        }
      }
    }
  }


  section{
    h3{
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 120%;
    }
  }

  .top{
    flex-direction: column;
    align-items: flex-start !important;

    margin: 0 20px;
    h3{
      margin-top: 18px;
      font-size: 18px;
    }
  }

  .info{
    display: flex;
    align-items: center;
    margin-top: 8px;
   
    img{
      width: 9px;
    }

    span{
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      color: #8F8F8F;
      margin-left: 5px;
    }
  }

  .participantes{
    display: flex;
    align-items: center;
    margin-left: 10px;

    .profile{
      width: 20px;
      height: 20px;
      img{
        width: 22px;
      }
    }
      
    .profile:not(:first-child) {
      margin-left: -10px;
    }
    
  }

  .about, .admins{
    margin: 20px 20px 0 20px;
    h3{
      margin-bottom: 6px;
    }
    span{
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
    }
  }

  .bottom{
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .admin-profile{
      display: flex;
      align-items: center;
      margin-top: 5px;

      .profile{
        width: 24px;
        height: 24px;
        margin-right: 9px;
        img{
          width: 26px;
        }
      }
      
      span{
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
      }
    }
    
    .content-right{
      display: flex;
      button{ 
        height: 42px;
        width: 254px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        color: #fff;
        align-self: flex-end;
        background-color: var(--primary-color);
      }
    }

    @media screen and (max-width: 796px){
      width: 100%;
      .content-right{
        margin-top: 20px;
        width: 100%;
        button{
          width: 100%;
        }

      }
    }
  }
`