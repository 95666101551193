import styled from "styled-components";

export const Main = styled.div`
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(50,50,50,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Content = styled.div`
 
  background-color: #fff;
  width: 338px;
  background: #FFFFFF;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .top{
    width: 100%;
    padding: 27px 32px 21px 31px;
    border-bottom: 1px solid #CFCFCF;

    img{
      width: 24px;
      margin-right: 8px;
    }

    h3{
      font-size: 18px;
      font-weight: 600;
      line-height: 120%;
    }

  }

  span{
    font-size: 15px;
    font-weight: 500;
    line-height: 150%;
    padding: 18px 50px;
  }

  .bottom{
    width: 100%;
    padding: 0 50px 27px 50px;
    button{
      background-color: #DA1A1A;
      width: 100%;
      height: 40px;
      color: #FFF;
      border: none;
      border-radius: 6px;
      cursor: pointer;
      margin-top: 6px;

    }

    .secondary-button{
      width: 100%;
      button{
        margin-top: 12px;
        background-color: transparent;
        color: var(--black-color);
        border: 1px solid #4d4f50;
        font-weight: bold;
      }
    }
  }

`