import Formulario from "../../../Formulario";
import { Card } from "../../styled";
import { Content } from "../styled";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import Especie from "../../../../../models/Especie";
import Select from "../../../Select";

function CardEspecies({especie, setEspecie, nomeEspecie, setNomeEspecie, onCreate, onEdit, onDetails}){
  const [especies, setEspecies] = useState({});
  const [errors, setErrors] = useState({});
  const [showOptions, setShowOptions] = useState(false);

  async function filtrarEspecies(e) {
    const searchValue = e.target.value;
    setNomeEspecie(searchValue);

    let newEspecies = [];

    if (searchValue) {
      newEspecies = await Especie.findSearch(searchValue);
    } else {
      newEspecies = await Especie.findAll();
    }

    setEspecies(newEspecies.data);
  }

  function  onClickSelectEspecie(especie) {
    setNomeEspecie(`${especie.nome_comum} - ${especie.nome_cientifico}`);
    setEspecie(especie);
    setErrors((prevErrors) => ({ ...prevErrors, especie: '' }));
    
  }

  async function findAllEspecies() {
    const allEspecies = await Especie.findAll();
    setEspecies(allEspecies.data);
  }

  useEffect(() => {
    findAllEspecies();
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Card className="card-gerenciamento">
      <Content>
        <h3>Espécies</h3>
        <h4>Edite ou adicione novas espécies.</h4>
        <Formulario>
          <div className="form-group">
            <label htmlFor="especies">Lista de especies</label>
            <Select
              id="especies" 
              name="especies" 
              placeholder="Pesquise por especies aqui" 
              required 
              autoComplete="off"
              value={nomeEspecie} 
              onChange={filtrarEspecies} 
              className={`input-prefix ${errors.especies && 'input-error'}`}
              options={especies?.rows}
              onClickOption={onClickSelectEspecie}
              showOptions={showOptions}
              setShowOptions={setShowOptions}
            />
            <span className="error">{errors.especie}</span>
            <Link className="more-especies" onClick={onDetails}>Ver mais informações</Link>
          </div>

          <div className="form-group">
            <button className="edit-button" type="button" onClick={onEdit}>Editar espécie</button>
          </div>

          <div className="form-group">
            <button className="add-button" type="button" onClick={onCreate}>Adicionar uma nova espécie</button>
          </div>

        </Formulario>
      </Content>
    </Card>    
  );
}

export default CardEspecies;

