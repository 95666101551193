import Validacao from '../ui/modules/validacao';

const CustomHandle = {
  submitForm : async function (form_id, submitAction, errors, setErrors, setLoading, onSuccess) {
    const formulario = document.getElementById(form_id);
    const input_errors = Validacao.validarCampos(formulario, errors);
    setErrors(input_errors);
  
    if(Validacao.emptyErrors(input_errors)){
      setLoading(true);
      const result = await submitAction();
      if(result?.status === 200){
        onSuccess(result);
      }else{
        let data = result.response.data;
        if(data.field){
          setErrors({...errors, [data.field] : data.error});
          document.getElementById(data.field).focus();
        }else{
          setErrors({...errors, geral : result?.response?.data?.error || "Ocorreu um erro desconhecido. Tente novamente mais tarde."});
        } 
      }
      setLoading(false);
    }
  },

  handleResult : async function (result, errors, setErrors, onSuccess) {
    if(result?.status === 200){
      onSuccess(result.data);
      setErrors({});
    }else{
      let data = result.response.data;
      if(data.field){
        setErrors({...errors, [data.field] : data.error});
        document?.getElementById(data.field)?.focus();
      }else{
        setErrors({...errors, geral : result?.response?.data?.error || "Ocorreu um erro desconhecido. Tente novamente mais tarde."});
        setTimeout(()=>setErrors({...errors, geral : ''}), 3000);
      } 
    }
  }
}


export default CustomHandle;