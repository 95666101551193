
function validarCampos (formulario, errors) {
  var inputs = formulario.getElementsByTagName('input');
  inputs = [...inputs, ...formulario.getElementsByTagName('select')]
  var input_errors = errors;
  var firt_input = undefined;

  for (var i = 0; i < inputs.length; i++) {
    var input = inputs[i];
    
    var nome_campo = input.getAttribute('name');
    if (input.required && (input.value.trim() === '' || input.value === "false")) {
      var label = formulario.querySelector('label[for="' + input.id + '"]')?.textContent.trim();
      input_errors = {...input_errors, [nome_campo] : `O campo ${label} é obrigatório`}
      if(!firt_input)
        firt_input = input;
    }
  }
  
  firt_input?.focus();
  return input_errors;
}


function limparForm (formulario) {
  var inputs = formulario.getElementsByTagName('input');
  inputs = [...inputs, ...formulario.getElementsByTagName('select')]
  
  for (var i = 0; i < inputs.length; i++) {
    var input = inputs[i];
    input.value = '';
  }
}

function emptyErrors(errors){
  if(Object.keys(errors).length === 0)
    return true;
  
  for (var campo in errors) {
    if (campo !== 'geral' && errors.hasOwnProperty(campo) && errors[campo].trim() !== '') {
      return false;
    }
  }

  return true;
}


const Validacao = {
  validarCampos,
  emptyErrors,
  limparForm
};

export default Validacao;