import arvore_image from "../../../../assets/arvore.png";
import arrow_back_icon from "../../../../assets/icons/arrow-back.svg";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../../utils/config";
import { useState } from "react";
import { useEffect } from "react";
import CardPostagem from "../../../components/Cards/CardPostagem";
import ComentarioInput from "../../../components/ComentarioInput";
import ControllerComentar from "./controller";
import Comentario from "../../../../models/Comentario";
import { tiposPublicacao } from "../../../../utils/data";
import { Content } from "./styled";

function Comentar({usuario, postagemSelected, setPostagemSelected, handleLike, errors, setErrors}){
  const [comentario, setComentario] = useState({...Comentario.model});
  const [comentarios, setComentarios] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const controllerComentar = new ControllerComentar(comentario, page, postagemSelected, usuario, errors, comentarios, setErrors, setComentarios, setComentario, setPostagemSelected, setLoading);

  useEffect(()=>{
    controllerComentar.findComentarios(page);
  }, [page, postagemSelected]);

  return(
    <section className="left-section">
      <Content>

        <section className="postagens-section">
          <CardPostagem
            postagem={postagemSelected}
            handleLike={postagemSelected.tipo === "Comentário" || postagemSelected.tipo === "Resposta" ? ()=>controllerComentar.handleLike(postagemSelected) : handleLike}
          />
          <ComentarioInput 
            usuario={usuario} 
            tipo={postagemSelected.tipo}
            handleComentar={()=>controllerComentar.handleComentar()}
            validarTexto={(e)=>controllerComentar.validarTexto(e)}
            comentario = {comentario}
            loading = {loading}
          />
          <h3>{ postagemSelected.tipo  === "Comentário" ? "Respostas" : "Comentários" }</h3>
          {
              comentarios?.rows?.length > 0 ?
              <>
                {comentarios?.rows?.map((comentario) => (
                  <CardPostagem 
                    postagem={comentario}
                    tipo={tiposPublicacao.COMENTARIO}
                    handleComentar= {()=>{setPostagemSelected(comentario); setPage(1)}}
                    handleLike={()=>controllerComentar.handleLike(comentario)}
                    onDelete={(setShowDeletarDialog)=>controllerComentar.deleteComentario(comentario.id, setShowDeletarDialog)}
                  />
                ))}
                { comentarios.rows.length !== comentarios.count &&
                  <span className="more" onClick={()=>setPage(page+1)}> Ver mais </span>
                }
              </>:
              <div className="painel-empty">
                <img src={arvore_image} alt="Icone de uma árvore"/>
                <span>Ainda não há nada aqui. Seja o primeiro a fazer um comentário...</span>
              </div>
          }
        </section>
      </Content>
    </section>
    
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Comentar);