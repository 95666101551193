import { estados } from "../../../../utils/data";
import Usuario from "../../../../models/Usuario";
import CustomHandle from "../../../../utils/CustomHandle";
import { convertToBase64 } from "../../../../utils/conversoes";
import Grupo from "../../../../models/Grupo";


class ControllerNovoGrupo {
  constructor(usuario, errors, grupo, setGrupo, setErrors, setLoading) {
    this.usuario = usuario;
    this.setLoading = setLoading;
    this.errors = errors;
    this.setErrors = setErrors;
    this.grupo = grupo;
    this.setGrupo = setGrupo;
  }

  validarNome(e) {
    this.setGrupo({ ...this.grupo, nome: e.target.value });
    if(e.target.value.length < 8){
      this.setErrors({...this.errors, nome : "O nome do grupo deve conter pelo menos 8 caracteres."})
    }else{
      this.setErrors({ ...this.errors, nome: "", geral: "" });
    }
    
  }

  validarSobre(e) {
    this.setGrupo({ ...this.grupo, sobre: e.target.value });
    if(e.target.value.length < 20){
      this.setErrors({...this.errors, sobre : "O campo sobre deve conter pelo menos 20 caracteres."})
    }else{
      this.setErrors({ ...this.errors, sobre: "", geral: "" });
    }
  }

  validarEstado(e) {
    this.setGrupo({ ...this.grupo, estado: e.target.value });
    if (!estados.includes(e.target.value)) {
      this.setErrors({ ...this.errors, estado: "Digite um estado válido", geral: "" });
    } else {
      this.setErrors({ ...this.errors, estado: "", geral: "" });
    }
  }

  addAdministrador(administrador, setAdministradoresId, setUsuarios, setNomeUsuario){
    const new_administradores = this.grupo.administradores;
    new_administradores.push(administrador);
    this.setGrupo({...this.grupo, administradores : new_administradores});
    setAdministradoresId((administradores)=>[...administradores, administrador.id]);
    setUsuarios({});
    setNomeUsuario('');
  }

  removeAdministrador(administrador, setAdministradoresId){
    const new_administradores = this.grupo.administradores.filter(item=>item.id !== administrador.id);
    this.setGrupo({...this.grupo, administradores : new_administradores});
    setAdministradoresId(adiministradoresId => (adiministradoresId.filter(administradorId=> administradorId !== administrador.id)));
  }

  handleChangeMidia(e, setFotoCapa) {
    const { files: selectedFiles } = e.target;
    setFotoCapa(selectedFiles[0]);
 
  }

  tratarGrupo(grupo, base64Array, grupo_pai){
    const new_grupo = {...grupo};
    new_grupo.tipo = grupo_pai ? "Subgrupo" : "Grupo Principal";
    new_grupo.foto_capa = base64Array[0];
    new_grupo.grupos_usuarios = [{usuario_id : this.usuario.id, is_admin: true, is_creador: true, ingresso_em: new Date()}];
   
    if(grupo_pai){
      new_grupo.grupo_pai_id = grupo_pai.id;
    }

    for(const usuario of grupo.administradores){
      new_grupo.grupos_usuarios.push({
        usuario_id : usuario.id,
        is_admin: true,
        is_creador: false,
        ingresso_em: new Date()
      })
    }

    delete new_grupo.administradores;

    return new_grupo;
  }

  async handleSubmit(e, fotoCapa, setShowSuccess, grupo_pai){
    e.preventDefault();

    if(!fotoCapa){
      this.setErrors({...this.errors, foto_capa : "Adicione uma foto de capa no grupo."});
    }else{
      convertToBase64([fotoCapa])
      .then(async (base64Array) => { 
        const new_grupo = this.tratarGrupo(this.grupo, base64Array, grupo_pai);
        const submitAction = async ()=> await Grupo.create(new_grupo);
        const onSuccess =  ()=>setShowSuccess(true);

        await CustomHandle.submitForm('form-grupo', submitAction, this.errors, this.setErrors, this.setLoading, onSuccess);
      })
      .catch((err)=>{
        this.setErrors({...this.errors, foto_capa : "Ocorreu um erro ao tratar as imagens. Tente novamente" + err})
      });
    }

  }

  limparCampos(setShowSuccess, closeModal, setFotoCapa, atualizarGrupos){
    this.setGrupo(Grupo.model);
    setShowSuccess(false);
    closeModal();
    setFotoCapa(null);
    atualizarGrupos();
  }

  async filtrarUsuarios(e, setUsuarios, setNomeUsuario, administradoresId){
    setNomeUsuario(e.target.value);
    const result = await Usuario.findSearch(e.target.value);
    const onSuccess = (response)=>{
      const new_usuarios = [];
      for(const usuario of response.rows){
        if(!administradoresId.includes(usuario.id)){
          new_usuarios.push(usuario);
        }
      }
      setUsuarios(usuarios=>({...usuarios, rows : new_usuarios}));
    };

    CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);
  }

}

export default ControllerNovoGrupo;
