import styled from "styled-components";

export const Content = styled.div`

  .back{
    width: auto;
    padding: 0 !important;
    margin-right: auto !important;
    margin-left: 0 !important;
    margin-bottom: 1px !important;
    background-color: transparent !important;
    color: var(--primary-color) !important;
    img{
      width: 8px !important;
    }
  }

  .top{
    span{
      font-style: normal;
      font-weight: 700 !important;
      font-size: 27px;
      color: var(--primary-color);
    }
  }

  .panel{
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;

    .left-section{
      width: 60%;

      .grupos-list{
        display: flex;
        flex-wrap: wrap;

        .card{
          width: 45% !important;
        }
      }
    }

    .right-section{
      width: 30%;
      padding-left: 63px;
      margin-top: 40px;
      border-left: 1px solid #D9D9D9;

      .actions-section{
        .action-item:not(:first-of-type) {
          border-top: 1px solid #CFCFCF;
        }

        .action-item{
          display: flex;
          align-items: center;
          padding: 16px 0;
          cursor: pointer;
          
          img{
            width: 24px;
            margin-right: 10px;
          }

          span{
            color: #4D4F50;
            font-family: Poppins;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
          }
        }
      }

      
      

      .admins-section{
        h3{
          margin-bottom: 6px;
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 120%;
        }
        
        .admin-profile{
          display: flex;
          align-items: center;
          margin-top: 12px;
          

          .profile{
            width: 40px;
            height: 40px;
            margin-right: 10px;
            border: 1px solid var(--primary-color);
            img{
              width: 40px;
              
            }
          }
        } 

        span{
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 150%;
        }

      }

      .participantes-section{
        margin-top: 37px;
        h3{
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 120%;
        }

        .list{
          display: flex;
          margin-top: 18px;
          .profile{
            width: 24px;
            height: 24px;
            border: 1px solid var(--primary-color);
            margin-left: -2px;

            img{
              width: 24px;
            }
          }
        }
        span{
          display: flex;
          width: 100px;
          margin-top: 9px;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 150%;
        }
      }

      .semelhantes-section{
        margin-top: 37px;
        h3{
          margin-bottom: 3px;
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 120%;
        }

        .card{
          padding-bottom: 3px ;
          width: 100%;
          margin: 15px 0;
          
          img{
            height: 88px;
          }

          h4{
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 120%;
          }

          .profile{
            width: 20px;
            height: 20px;

            img{
              width: 20px;
              height: 100%;
            }
          }

          .profile:not(:first-child){
            margin-left: -5px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 796px){
    .panel{
      flex-direction: column;

      .left-section, .right-section{
        width: 100%;
      }
    }
  }

  
`