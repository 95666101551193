import styled from "styled-components";

export const Main = styled.div`

 

  .option-item{
    display: flex;
    padding: 5px;

    .profile{
      width: 32px;
      height: 32px;
      border: 1px solid var(--primary-color);

      img{
        width: 32px;
        object-fit: cover;
      }
    }
  }
  .search-options{
    position: absolute;
    width: 100%;
    z-index: 1000;
    padding: 5px;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    border-radius: 3px;
    box-shadow: 0px 2px 3px rgba(36, 36, 36, 0.3);
    background-color: #FCFEFC;

    .selected{
      color: var(--primary-color) !important;
      font-weight: bold;
    }

    span{
      padding: 5px 12px;
      cursor: pointer;

      :hover{
        font-weight: bold;
      }
    }
  }
`