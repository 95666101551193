import styled from "styled-components";

export const ModalOverview = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 100;
    background-color: #cccccc55;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  
`;

export const ModalContentGreen = styled.div`
    display: flex;
    padding: 30px 18px;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    flex: 1 0 0;
    width: 320px;

    border-radius: 12px;
    background: var(--verde-primrio-real, #2AA444);
    box-shadow: 0px 10px 25px 0px #CCD9FF;

    .info{
        display: flex;
        flex-direction: column;
        gap: 15px;

        .infoTop{
            display: flex;
            flex-direction: column;
            gap: 9px;

            .title{
                color: var(--Shades-White, var(--Basic-White, #FFF));
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            .description{
                overflow: hidden;
                color: var(--Neutral-50, #F7F8F9);
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        .infoValue{
            display: flex;
            align-items: center;
            gap: 4px;

            .value{
                color: var(--Shades-White, var(--Basic-White, #FFF));
                font-family: Manrope;
                font-size: 42px;
                font-weight: 600;
            }

            .period{
                color: var(--Neutral-50, #F7F8F9);
                font-family: Manrope;
                font-size: 12px;
                font-weight: 300;
            }
        }
    }

    .beneficios{
        display: flex;
        flex-direction: column;
        gap: 9px;

        .beneficio{
            display: flex;
            gap: 12px;
            align-items: center;

            color: var(--Shades-White, var(--Basic-White, #FFF));
            font-family: Manrope;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    button{
        display: flex;
        height: 33px;
        padding: 6px 12px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        align-self: stretch;
        border-radius: 4px;
        background: var(--Basic-White, #FFF) !important;

        color: var(--verde-primrio-real, #2AA444) !important;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Manrope;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
`;


export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 22px;
    align-items: center;
    width: 501px;
    height: 677px;
    border-radius: 6px;
    padding: 30px;
    background: #FCFEFC;
    box-shadow: 0px 2px 3px 0px rgba(36, 36, 36, 0.10);
    position: relative;

    .noInterested{
        cursor: pointer;
    }
    
   

    .closeIcon{
        display: flex;
        position: absolute;
        top: 32px;
        right: 50px;
        cursor: pointer;
    }

    .topModal{
        display: flex;
        gap: 8px;

        span{
            color: var(--preto, #4D4F50);
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%; /* 28.8px */
        }

        .pro{
            display: flex;
            width: 41px;
            height: 23px;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 12px;
            font-weight: 400;

            border-radius: 12px;
            background: #2AA444;

        }
    }
`;