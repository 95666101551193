import api from '../services/api';

const Comentario = {
  model: {texto: ""},
  
  create : async function (data) {
    const url = data.comentario_id ? 'comentarios/resposta' : 'comentarios';
    return await api.post(url, data)
    .then(response=>response)
    .catch(error=>error);
  },

  delete : async function (comentario_id) {
    return await api.delete(`comentarios/id/${comentario_id}`)
    .then(response=>response)
    .catch(error=>error);
  },

  findByPostagemId : async function (postagem_id, page) {
    return await api.get(`comentarios/${postagem_id}/${page}/10`)
    .then(response=>response)
    .catch(error=>error);
  },

  findRespostasByComentarioId : async function (comentario_id, page) {
    return await api.get(`comentarios/respostas/${comentario_id}/${page}/10`)
    .then(response=>response)
    .catch(error=>error);
  },

  like : async function (usuario, comentario) {
    return await api.post(`comentarios/curtida`, {usuario_id : usuario.id, comentario_id: comentario.id, postagem_id: comentario.postagem_id})
    .then(response=>response)
    .catch(error=>error);
  },

  findSearch : async function (data) {
    return await api.get(`comentarios/data/${data}/1/20`)
    .then(response=>response)
    .catch(error=>error);
  }
}

export default Comentario;
