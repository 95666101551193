import styled from "styled-components";

export const Card = styled.div`
  width: 23.4%;
  min-width: 112px;
  margin-right: 9px;
  flex: 0 0 auto;
  scroll-snap-align: start;
  border-radius: 4px;
  background: #FCFEFC;
  box-shadow: 0px 2px 2px rgba(36, 36, 36, 0.1);
  border-radius: 4px;

  .image{
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 4px 4px 0px 0px;
    img{
      height: 133px;
    }
  }

  .description{
    padding: 9px;
    h4{
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
    }

    h6{
      font-style: normal;
      font-weight: 600;
      font-size: 9px;
      line-height: 150%;
      color: #CFCFCF;
      margin-top: 6px;
    }

    .data{
      display: flex;
      align-items: center;
      img{
        width: 12px;
        margin-right: 3px;
      }

      span{
        font-style: normal;
        font-weight: 500;
        font-size: 9px;
        line-height: 150%;
      }
    }
  }

`