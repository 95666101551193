import styled from "styled-components";

export const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;

  
  .more-especies{
    color: var(--primary-color);
    font-style: normal;
    font-weight: 500 ;
    font-size: 12px;
    line-height: 150%;
    align-self: flex-end;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .image-added{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }

  .content-modal{
    width: 380px;
    max-height: 600px;
    background: #FCFEFC;
    overflow-y: scroll;
    box-shadow: 0px 2px 3px rgba(36, 36, 36, 0.1);
    border-radius: 6px;
    padding: 24px;

    .top{
      display: flex;
      align-items: center !important;
      button{
        margin: 0 !important;
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;
      }
    }

    h3{
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 120%;
      margin: 0;
    }

    h4{
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 120%;
      margin-top: 30px;
    }

    form{
      margin: 0;
      label{
        margin-top: 18px;
        margin-bottom: 3px;
      }

      input{
        margin-top: 0;
      }

      img{
        top: 45px !important;
      }

      button{
        margin: 0;
        margin-top: 18px;
        border-radius: 4px;

      }

      .remove-button{
        margin-top: 12px;
        margin-bottom: 12px;
        background: #C43333;
      }

    }
    
    .bioma-selecionado{
      margin-top: 4px;
    }


  }

  .form-patrocinador, .form-apoiador{
    display: flex;
    justify-content: space-between;
  }

  .modal-patrocinador, .modal-apoiador{
    width: 932px;
    height: 744px;

    .buttons{
      margin-top: auto;
    }

    

    .container-left{

      
      
    }
    .container-left, .container-right{
      display: flex;
      flex-direction: column;
      width: 46%;
      height: 470px;

      .title{
        color: #3E8D50;
        font-size: 15px;
        width: 100%;
        text-align: center;
        font-weight: 700;
      }

    #foto{
      display: none;
    }

    .image{
      margin-top: 5px;
    }

    .form-container{
      display: flex;
      width: 100%;
    }


    .cards{
      display: flex;
      flex-direction: column ;
      gap: 50px;
      width: 100%;
      height: 387px;
      margin-top: 45px;

      .sub-title{
        color: #3E8D50;
        font-size: 16px;
        font-weight: 700;
      }

      .card-app, .card-site{
        display: flex;
        flex-direction: column;
        gap: 12px;
        
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 8px;
          box-shadow: 0px 2px 3px 0px rgba(36, 36, 36, 0.10);
        }
      }
      
      .card-site>.image{
        width: 274px;
        height: 154px;
        flex-shrink: 0;
        border: none;
      }

      .card-app>.image{
        width: 245px;
        height: 136px;
        flex-shrink: 0;
        border: none;
      }
    }
  }
  .container-right{
    margin-top: -25px;
    height: 495px;
  }
}
`;