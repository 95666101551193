import { connect } from "react-redux";
import close_icon from "../../../../../assets/icons/close.svg";
import Formulario from "../../../../components/Formulario";
import { mapDispatchToProps, mapStateToProps } from "../../../../../utils/config";
import { useState } from "react";
import Loading from "../../../../components/Loading";
import AlertDialog from "../../../../components/AlertDialog";
import { Main, Container } from "../styled";
import InformacoesGrupoController from "../controller";
import { estados } from "../../../../../utils/data";

function AlterarEstadoModal({grupo, setGrupo, errors, setErrors, onClose}){
  const [loading, setLoading] = useState(false);
  const [dadosGrupo, setDadosGrupo] = useState({...grupo});
  const [showSuccess, setShowSuccess] = useState(false);

  const informacoesGrupoController = new InformacoesGrupoController(dadosGrupo, setDadosGrupo, errors, setErrors, setLoading);

  return(
    <Main>
    <Container>
      {loading && <Loading/>}
      {showSuccess && (
        <AlertDialog
          titulo={`Estado alterado com sucesso com sucesso!`}
          buttonText={"Ver meus dados"}
          buttonOnClick={onClose}
        />
      )}
      <div className="content-modal">
        <section>
          <div className="close" onClick={onClose}>
            <button type="button">
              <img src={close_icon} alt="Icone de fechar"/>
            </button>
          </div>
        </section>

        <section>
          <div className="title">
            <h2>Alterar Estado do Grupo</h2>
          </div>
        </section> 

        <Formulario id="form-alterar-estado">
          
          <div className="form-group">
            <label htmlFor="estado">Estado</label>
            <input type="text" list="estados" id="estado" name="estado" placeholder="Escolha seu novo estado" required autoComplete="off"
              value={dadosGrupo.estado} onChange={(e)=>informacoesGrupoController.validarEstado(e)} className={errors.estado && 'input-error'}
            />
            <datalist id="estados">
              { estados.map((estado, index)=>{
                  return <option  key={index} value={estado}/>
              })}
            </datalist>
            <span className="error">{errors.estado}</span>
          </div>

          <div className="form-group">
          <button type="button" onClick={(e)=>informacoesGrupoController.alterarDados(e, 'form-alterar-estado', setGrupo, setShowSuccess)}>Salvar</button> 
          </div>


              
        </Formulario>
      </div>
    </Container>
    </Main>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AlterarEstadoModal);