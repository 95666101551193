import Formulario from "../../../Formulario";
import { Card } from "../../styled";
import { Content } from "../styled";
import { useEffect, useState } from "react";
import Apoiador from "../../../../../models/Apoiador";
import Select from "../../../Select";

function CardApoiadores({onCreate, onEdit}) {

  const [nomeApoiador, setNomeApoiador] = useState("");
  const [apoiador, setApoiador] = useState({});
  const [apoiadores, setApoiadores] = useState({});
  const [errors, setErrors] = useState({});
  const [showOptions, setShowOptions] = useState(false);

  async function filtrarApoiadores(e) {
    const searchValue = e.target.value;
    setNomeApoiador(searchValue);

    let newApoiadores = [];

    if (searchValue) {
      newApoiadores = await Apoiador.findSearch(searchValue);
    } else {
      newApoiadores = await Apoiador.findAll();
    }

    setApoiadores(newApoiadores.data);
  }

  function onClickSelectApoiador(apoiador) {
    setNomeApoiador(apoiador.nome);
    setApoiador(apoiador);
    setErrors((prevErrors) => ({ ...prevErrors, apoiador: '' }));
  }

  async function findAllApoiadores() {
    const allApoiadores = await Apoiador.findAll();
    setApoiadores(allApoiadores.data);
  }

  useEffect(() => {
    findAllApoiadores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Card className="card-gerenciamento">
      <Content>
        <h3>Apoiadores</h3>
        <h4>Procure e edite apoiadores.</h4>
        <Formulario>
          <div className="form-group">
            <label>Nome do apoiador</label>
            <Select
              id="apoiador"
              name="apoiador"
              placeholder="Pesquise por apoiador aqui"
              required
              autoComplete="off"
              value={nomeApoiador}
              onChange={filtrarApoiadores}
              className={`input-prefix ${errors.apoiador && 'input-error'}`}
              options={apoiadores?.rows}
              onClickOption={onClickSelectApoiador}
              showOptions={showOptions}
              setShowOptions={setShowOptions}
            />
            <span className="error">{errors.apoiador}</span>
          </div>

          <div className="form-group">
            <button className="edit-button" type="button" onClick={()=>onEdit(apoiador)}>Editar apoiador</button>
          </div>

          <div className="form-group">
            <button className="add-button" type="button" onClick={onCreate}>Adicionar um novo apoiador</button>
          </div>

        </Formulario>
      </Content>
    </Card>
  );
}

export default CardApoiadores;

