import Usuario from "../../../../models/Usuario";
import CustomHandle from "../../../../utils/CustomHandle";
import { estados } from "../../../../utils/data";

class ControllerCadastro {
  constructor(usuario, errors, setUsuario, setErrors, setLoading) {
    this.usuario = usuario;
    this.errors = errors;
    this.setUsuario = setUsuario;
    this.setErrors = setErrors;
    this.setLoading = setLoading;
  }

  validarNome(e) {
    this.setUsuario({ ...this.usuario, nome: e.target.value });
    let nomeList = this.usuario.nome?.split(" ");
    if (nomeList?.length < 2) {
      this.setErrors({ ...this.errors, nome: "Digite nome e sobrenome", geral: "" });
    } else {
      this.setErrors({ ...this.errors, nome: "", geral: "" });
    }
  }

  validarCidade(e) {
    this.setUsuario({ ...this.usuario, cidade: e.target.value });
    this.setErrors({ ...this.errors, nome: "", geral: "" });
    
  }

  validarEstado(e) {
    this.setUsuario({ ...this.usuario, estado: e.target.value });
    if (!estados.includes(e.target.value)) {
      this.setErrors({ ...this.errors, estado: "Digite um estado válido", geral: "" });
    } else {
      this.setErrors({ ...this.errors, estado: "", geral: "" });
    }
  }

  validarCelular(e) {
    const regexCelular = /^\(\d{2}\)\s\d{5}-\d{4}$/;
    const mascaraCelular = /^(\d{2})(\d{5})(\d{4})$/;
    let celular = e.target.value.replace(mascaraCelular, "($1) $2-$3");

    this.setUsuario({ ...this.usuario, celular: celular });

    if (!regexCelular.test(celular)) {
      this.setErrors({
        ...this.errors,
        celular: "Digite um número de telefone válido",
        geral: "",
      });
    } else {
      this.setErrors({ ...this.errors, celular: "", geral: "" });
    }
  }

  validarTermo(checked, setChecked) {
    setChecked(!checked);
    if (!checked) this.setErrors({ ...this.errors, termo: "" });
  }

  async handleSubmit(e, navigate){
    e.preventDefault();
    const submitAction = async ()=> await Usuario.create({...this.usuario, tipo : "Comum"});

    const onSuccess = (response)=>{
      let usuario = response.data;
      sessionStorage.setItem('usuario', JSON.stringify(usuario));
      this.setUsuario(usuario);
      Usuario.sendEmail(usuario.id);
      navigate('/cadastro/confirmacao');
    };

    await CustomHandle.submitForm('form-cadastro', submitAction, this.errors, this.setErrors, this.setLoading, onSuccess);

  }
}

export default ControllerCadastro;
