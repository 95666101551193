import api from '../services/api';

const Tag = {
  model: {nome: ""},

  findSearch : async function (data) {
    return await api.get(`tags/1/5?search=${data}`)
    .then(response=>response)
    .catch(error=>error);
  }
}

export default Tag;
