import { Main, Container } from "./styled";
import close_icone from "../../../../assets/icons/close.svg";
import lixeira_icon from "../../../../assets/icons/lixeira.svg";
import perfil_image from "../../../../assets/perfil.png";
import add_adm_icone from "../../../../assets/icons/add-adm.svg";
import remove_adm_icone from "../../../../assets/icons/remove-adm.svg";
import plus_icone from "../../../../assets/icons/plus.svg";
import Formulario from "../../../components/Formulario";
import { useState } from "react";
import Grupo from "../../../../models/Grupo";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../../utils/config";
import ControllerNovoGrupo from "./controller";
import { estados } from "../../../../utils/data";
import Select from "../../../components/Select";
import CircularImage from "../../../components/CircularImage";
import Loading from "../../../components/Loading";
import AlertDialog from "../../../components/AlertDialog";

function NovoGrupoModal({atualizarGrupos, onClose, grupo_pai, usuario, errors, setErrors }){
  const [grupo, setGrupo] = useState(Grupo.model);
  const [showFindUser, setShowFindUser] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [usuarios, setUsuarios] = useState();
  const [fotoCapa, setFotoCapa] =  useState();
  const [nomeUsuario, setNomeUsuario] = useState();
  const [administradoresId, setAdministradoresId] = useState([usuario.id]);
  const closeModal = onClose;

  const controllerNovoGrupo = new ControllerNovoGrupo(usuario, errors, grupo, setGrupo, setErrors, setLoading);
  return(
    <Main>
      {loading && <Loading titulo={"Aguarde"} subtitulo={"Estamos deixando tudo pronto para você..."}/>}
      {showSuccess &&
          <AlertDialog
            titulo={`Grupo cadastrado com sucesso!`} 
            buttonText={"Ver grupos"} 
            buttonOnClick={()=>controllerNovoGrupo.limparCampos(setShowSuccess, closeModal, setFotoCapa, atualizarGrupos)}
          />
        }
      <Container>
        <section>
          <div className="close" onClick={closeModal}>
            <button type="button">
              <img src={close_icone} alt="Icone de fechar"/>
            </button>
          </div>
        </section>

        <section>
          <div className="title">
            <h2>{`Criar um ${grupo_pai ? "subgrupo" : "grupo"} novo`}</h2>
          </div>
        </section>  

        <section>
          <Formulario id="form-grupo">
            <div className="form-col">
              <div className="form-group">
                <label htmlFor="nome">{`Nome do ${grupo_pai ? "subgrupo" : "grupo"}`}</label>
                <input type="text" id="nome" name="nome" placeholder={`Insira o nome do ${grupo_pai ? "subgrupo" : "grupo"}`} required
                  value={grupo.nome} onChange={(e)=>controllerNovoGrupo.validarNome(e)} className={errors.nome && 'input-error'}
                />
                <span className="error">{errors.nome}</span>
              </div>

              <div className="form-group">
                <label htmlFor="sobre">Sobre</label>
                <textarea id="sobre" name="sobre" placeholder={`Escreva aqui um pequeno texto sobre o ${grupo_pai ? "subgrupo" : "grupo"}`} required
                  value={grupo.sobre} onChange={(e)=>controllerNovoGrupo.validarSobre(e)} className={errors.sobre && 'input-error'}
                />
                <span className="error">{errors.sobre}</span>
              </div>

              <div className="form-group">
                <label htmlFor="estado">Estado</label>
                <input type="text" list="estados" id="estado" name="estado" placeholder="Escolha seu estado" required autoComplete="off"
                  value={grupo.estado} onChange={(e)=>controllerNovoGrupo.validarEstado(e)} className={errors.estado && 'input-error'}
                />
                <datalist id="estados">
                  { estados.map((estado, index)=>{
                      return <option  key={index} value={estado}/>
                  })}
                </datalist>
                <span className="error">{errors.estado}</span>
              </div>

              <div className="form-group">
                <label htmlFor="administador">Administradores</label>
                <div className="add-adm-item">
                  <input id="administador" name="administador" value="Você" disabled/>
                  <img src={add_adm_icone} alt="Icone de adicionar" className="add-button" onClick={()=>setShowFindUser(true)}/>
                </div>
              </div>
              
              {grupo.administradores.map(administrador=>
                <div className="form-group">
                  <div className="add-adm-item-list">
                    <input className="input-prefix" name="administador" value={administrador.nome} disabled/>
                    <CircularImage className="prefix" src={administrador?.foto_perfil?.path || perfil_image} alt="Foto do usuario" />
                    <img src={remove_adm_icone} alt="Icone de adicionar" className="add-button" onClick={()=>controllerNovoGrupo.removeAdministrador(administrador, setAdministradoresId)}/>
                  </div>
                </div>
              )}
              
              
              { showFindUser &&
                <div className="form-group">
                  <div className="add-adm-item">
                    <Select
                      id="usuarios" 
                      name="usuarios" 
                      placeholder="Pesquise por usuarios aqui" 
                      required={false}
                      autoComplete="off"
                      value={nomeUsuario} 
                      onChange={(e)=>controllerNovoGrupo.filtrarUsuarios(e, setUsuarios, setNomeUsuario, administradoresId)} 
                      className={`input-prefix ${errors.usuarios && 'input-error'}`}
                      options={usuarios?.rows}
                      onClickOption={(usuario)=>controllerNovoGrupo.addAdministrador(usuario, setAdministradoresId, setUsuarios, setNomeUsuario)}
                      showOptions={showOptions}
                      setShowOptions={setShowOptions}
                      perfil={true}
                    />  
                  </div>
                  <span className="error">{errors.usuarios}</span>
                </div>
              }

              

              <div className="form-group">
                <label>{`Adicionar foto de capa do ${grupo_pai ? "subgrupo" : "grupo"}`}</label>
                <input id="foto_capa" type="file" name="foto_capa" accept="image/*" onChange={(e)=>controllerNovoGrupo.handleChangeMidia(e, setFotoCapa)}/>
                <div className="midias">
                  {fotoCapa ? 
                    <label>
                      <div className="image">
                        <img className="preview-image" src={URL.createObjectURL(fotoCapa)} alt="Preview" />
                        <div className="blur" 
                          onClick={()=>setFotoCapa(null)}
                        >
                          <div className="blur-bg"></div>
                          <img src={lixeira_icon} alt="Icone de Lixeira"/>
                        </div>
                        
                      </div> 
                    </label> : 
                  
                    <label htmlFor="foto_capa">
                      <div className={`image ${errors.foto_capa && 'image-error'}`} onClick={()=>setErrors({...errors, foto_capa : ""})}>
                        <img className="plus-icon" src={plus_icone} alt="Iocne de adicionar" />
                      </div>
                    </label>
                  }
                  
                </div>
                <span className="error">{errors.foto_capa}</span>
              </div>

              <div className="form-group">
                <button type="submit" onClick={(e)=>controllerNovoGrupo.handleSubmit(e, fotoCapa, setShowSuccess, grupo_pai) }>{`Criar ${grupo_pai ? "subgrupo" : "grupo"}`}</button>
                <span className="error">{errors.geral}</span>
              </div>


            </div>

          </Formulario>
        </section>      
        
      </Container>
    </Main>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NovoGrupoModal);