import Usuario from "../../../../models/Usuario";
import CustomHandle from "../../../../utils/CustomHandle";

class ControllerConfirmarCadastro {
  constructor(usuario, setUsuario, inputRefs, setShowSuccess, errors, setErrors, setLoading, setClickCount, setDisabled, setTimer) {
    this.usuario = usuario;
    this.setUsuario = setUsuario;
    this.inputRefs = inputRefs;
    this.setShowSuccess = setShowSuccess;
    this.errors = errors;
    this.setErrors = setErrors;
    this.setLoading = setLoading;
    this.setClickCount = setClickCount;
    this.setDisabled = setDisabled;
    this.setTimer = setTimer;
  }

  validarCodigo(e, index) {
    const input = e.target;
    const key = e.key;
    const maxLength = parseInt(input.getAttribute('maxLength'), 10);

    if (input.value.length >= maxLength && key !== "Backspace") {
      if (index < this.inputRefs.length - 1) {
        this.inputRefs[index + 1].current.focus();
      }
    } else if (input.value.length === 0 && key === "Backspace") {
      if (index > 0) {
        this.inputRefs[index - 1].current.focus();
      }
    }
    
    this.setErrors({ codigo: '' });
  }

  handlePaste(e) {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text/plain');
    const numbers = pastedText.replace(/\D/g, '');

    numbers.split('').forEach((number, index) => {
      if (this.inputRefs[index]?.current) {
        this.inputRefs[index].current.value = number;
      }
    });
  }

  async handleResendEmail() {
    this.setClickCount((prevCount) => prevCount + 1);
    Usuario.sendEmail(this.usuario.id);
    this.setDisabled(true);
    this.setTimer(30);
  }

  async handleSubmit(e) {
    e.preventDefault();
    const codigo = this.inputRefs.map((ref) => ref.current.value).join("");
    const submitAction = async () => await Usuario.confirmEmail(this.usuario.id, codigo);
    const onSuccess = () => {
      sessionStorage.removeItem('usuario');
      this.setUsuario({...Usuario.model});
      this.setShowSuccess(true);
    };
    await CustomHandle.submitForm('form-confirmar-cadastro', submitAction, this.errors, this.setErrors, this.setLoading, onSuccess);
  }
}

export default ControllerConfirmarCadastro;
