import Formulario from "../Formulario";
import { Content } from "./styled";
import search_icon from "../../../assets/icons/seach.svg";

function InputSearch({text, onChangeCapture, error, placeholder}){
  return (
    <Content>
      <Formulario>
        <div className="form-group">
          <label>{text}</label>
          <input placeholder={placeholder} className={`input-prefix ${error && 'input-error'}`} type="text" onChangeCapture={onChangeCapture}/>
          <img className="prefix" src={search_icon} alt="Icone de search"/> 
          <span className="error">{error}</span>
        </div>
      </Formulario>
    </Content>
  );
}

export default InputSearch;