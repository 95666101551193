import { Main } from "../styled";
import Formulario from "../../../components/Formulario";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import cadastro_image from "../../../../assets/cadastro-image.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../../utils/config";
import ControllerConfirmarCadastro from "./controller";

function CadastroConfirmacao({usuario, setUsuario, errors, setErrors}) {
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(true);
  const [clickCount, setClickCount] = useState(0);
  const [timer, setTimer] = useState(30);
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const controllerConfirmarCadastro = new ControllerConfirmarCadastro(
    usuario, setUsuario, inputRefs, setShowSuccess, errors, setErrors, setLoading, setClickCount, setDisabled, setTimer
  );

  useEffect(() => {
    let countdown = setInterval(() => {
      setTimer((prevTime) => prevTime - 1);
    }, 1000);

    return () => {
      clearInterval(countdown);
    };
  }, [clickCount, navigate]);

  useEffect(() => {
    if (timer <= 0) {
      setDisabled(false);
    }
  }, [timer]);

  return (
    <Main>
      {loading && (
        <Loading
          titulo={"Aguarde um momento"}
          subtitulo={"Estamos validando suas informações"}
        />
      )}
      {showSuccess && (
        <AlertDialog
          titulo={`Cadatro realizado com sucesso!`}
          buttonText={"Acessar minha conta"}
          buttonOnClick={() => navigate('/login')}
        />
      )}
      <div className="content">
        <section className="left-section center-content">
          <div className="top">
            <h2>Estamos quase lá!</h2>
            <span>
              Confirme seu cadastro preenchendo o código enviado por email para{" "}
              <strong>{usuario.email || "seu email"}</strong>
            </span>
          </div>
          <div className="form">
            <Formulario id='form-confirmar-cadastro'>
              <div className="form-row">
                {[0, 1, 2, 3].map((index) => (
                  <div className="form-group" key={index}>
                    <label htmlFor={`codigo`}></label>
                    <input
                      className={`input-code ${errors?.codigo && "input-error"}`}
                      type="text"
                      name={`codigo`}
                      id={`codigo`}
                      maxLength="1"
                      required
                      onKeyDown={(e) =>
                        controllerConfirmarCadastro.validarCodigo(e, index)
                      }
                      ref={inputRefs[index]}
                      onPaste={(e) =>
                        controllerConfirmarCadastro.handlePaste(e, inputRefs)
                      }
                    />
                  </div>
                ))}
              </div>

              <div className="form-group">
                <span className="error">{errors?.codigo}</span>
              </div>

              <div className="form-group">
                <button
                  type="button"
                  className="resend-code"
                  disabled={disabled}
                  onClick={() => controllerConfirmarCadastro.handleResendEmail()}
                >
                  {timer > 0
                    ? `Nãorecebi um código (0:${timer < 10 ? "0" + timer : timer})`
                    : "Não recebi um código"}
                </button>
              </div>

              <div className="form-group">
                <button type="submit" onClick={(e) =>controllerConfirmarCadastro.handleSubmit(e)}>
                  Prosseguir
                </button>
                <span className="error">{errors.geral}</span>
              </div>
            </Formulario>
          </div>
        </section>

        <section className="right-section">
          <img src={cadastro_image} alt="Crianca plantando uma muda" />
        </section>
      </div>
    </Main>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CadastroConfirmacao);
