
import Comentario from "../../../../models/Comentario";
import CustomHandle from "../../../../utils/CustomHandle";

class ControllerComentar {
  constructor(comentario, page, postagemSelected, usuario, errors, comentarios, setErrors, setComentarios, setComentario, setPostagemSelected, setLoading) {
    this.comentario = comentario;
    this.page = page;
    this.postagemSelected = postagemSelected;
    this.usuario = usuario;
    this.setPostagemSelected = setPostagemSelected;
    this.errors = errors;
    this.setErrors = setErrors;
    this.setComentario = setComentario;
    this.comentario = comentario;
    this.setComentarios = setComentarios;
    this.comentarios = comentarios;
    this.setLoading = setLoading;

  }

  async findComentarios(page = this.page){
    const result = this.postagemSelected.tipo === "Comentário" ?
      await Comentario.findRespostasByComentarioId(this.postagemSelected.id, page) :
      await Comentario.findByPostagemId(this.postagemSelected.id, page);
    const onSuccess = (response)=>{
      if(page === 1){
        this.setComentarios(response);
      }else{
        const new_comentarios = response.rows;
        this.setComentarios({...this.comentarios, rows : [...this.comentarios.rows, ...new_comentarios]});
      }
    };

    CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);
  }

  async deleteComentario(comentario_id, setShowDeletarDialog){
    this.setLoading(true);
    const result = await Comentario.delete(comentario_id);
    const onSuccess = ()=>{
      const rows = this.comentarios.rows.filter(item => item.id !== comentario_id);
      this.setComentarios({...this.comentarios, rows : rows, count : this.comentarios.count - 1});
      setShowDeletarDialog(false);
    };

    CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);
    this.setLoading(false);
  }

  voltar(){
    this.setPostagemSelected(null);
    setTimeout(()=>{
      const postagemBack = document.getElementById('selected');
      const content = document.getElementById('content');
      const buttonPosition = postagemBack.getBoundingClientRect().top;
      content.scrollTo({
        top: buttonPosition - 100,
        behavior: 'smooth'
      });      
    },300);

  }

  async handleLike(comentario){
    const result = await Comentario.like(this.usuario, comentario);
    const onSuccess = (response)=>{
      if(comentario === this.postagemSelected){
        let quantidade_curtidas = parseInt(this.postagemSelected.quantidade_curtidas);
        quantidade_curtidas = this.postagemSelected.curtiu ? quantidade_curtidas - 1 : quantidade_curtidas + 1

        this.setPostagemSelected({...this.postagemSelected, curtiu : !this.postagemSelected.curtiu, quantidade_curtidas})
      }else{
        const new_comentarios = {...this.comentarios};
        new_comentarios.rows.forEach(item => {
          if(item === comentario){
            if(response){
              item.quantidade_curtidas = parseInt(item.quantidade_curtidas) + 1;
              item.curtiu = true;
            }else{
              item.quantidade_curtidas = parseInt(item.quantidade_curtidas) - 1;
              item.curtiu = false;
            }
          }
        });
        this.setComentarios(new_comentarios);
      }
    };

    CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);
  }

  async validarTexto(e) {
    const { comentario, errors, setComentario, setErrors } = this;
    setComentario({ ...comentario, texto: e.target.value });
    setErrors({ ...errors, texto: "" });
  }

  limparCampos(){
    this.setComentario(Comentario.model);
  }

  async handleComentar(){
    this.setLoading(true);
    const postagem_id = this.postagemSelected.tipo === "Fórum" || this.postagemSelected.tipo === "Grupo" ? this.postagemSelected.id : this.postagemSelected.postagem_id;
    let new_comentario = {...this.comentario, postagem_id : postagem_id, usuario_id : this.usuario.id, estado : this.usuario.estado};
    
    if(this.postagemSelected.tipo === "Comentário"){
      new_comentario = {...new_comentario, comentario_id : this.postagemSelected.id }
    }

    const result = await Comentario.create(new_comentario);
    const onSuccess = ()=>{
      this.setPostagemSelected({...this.postagemSelected, quantidade_comentarios : parseInt(this.postagemSelected.quantidade_comentarios) + 1});
      this.findComentarios(1);
      this.limparCampos();
    };

    await CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);

    this.setLoading(false);
  }
}

export default ControllerComentar;
