import { connect } from "react-redux";
import close_icon from "../../../../assets/icons/close.svg";
import Formulario from "../../../components/Formulario";
import { mapDispatchToProps, mapStateToProps } from "../../../../utils/config";
import { useState } from "react";
import eye_icon from "../../../../assets/icons/eye.svg";
import eye_show_icon from "../../../../assets/icons/eye-show.svg";
import ControllerConfiguracoes from "../Home/controller";
import Loading from "../../../components/Loading";
import AlertDialog from "../../../components/AlertDialog";

function AlterarSenhaModal({usuario, setUsuario, errors, setErrors, onClose}){
  const [dadosUsuario, setDadosUsuario] = useState({senha_atual : '', nova_senha: '', confirmar_senha: ''});
  const [showSuccess, setShowSuccess] = useState(false);
  const [showSenha, setShowSenha] = useState(false);
  const [showConfirmarSenha, setShowConfirmarSenha] = useState(false);
  const [showSenhaAtual, setShowSenhaAtual] = useState(false);
  const [loading, setLoading] = useState(false);

  const controllerConfiguracoes = new ControllerConfiguracoes(usuario, errors, setErrors, setLoading, setUsuario);

  return(
    <div className="modal">
      {loading && <Loading/>}
      {showSuccess && (
        <AlertDialog
          titulo={`Senha alterada com sucesso com sucesso!`}
          buttonText={"Ver meus dados"}
          buttonOnClick={onClose}
        />
      )}
      <div className="content-modal">
        <div className="top">
          <h3>Alterar Senha</h3>
          <button type="button" onClick={onClose}>
            <img src={close_icon} alt="Icone de fechar"/>
          </button>
        </div>

        <Formulario id="form-alterar-senha">
          <div className="form-group">
            <span className="error">{errors.geral}</span>
          </div>
          
          <div className="form-group">
            <label htmlFor="senha_atual">Senha Atual</label>
            <input type={showSenhaAtual ? "text" : "password"} id="senha_atual" name="senha_atual" placeholder="Insira sua senha atual" required autoComplete="off"
              onChange={(e)=>controllerConfiguracoes.validarSenha(e, dadosUsuario, setDadosUsuario)} className={errors.senha_atual && 'input-error'}
            />
            <img src={showSenhaAtual ? eye_show_icon : eye_icon} alt="Icone ver senha" className="sufix" onClick={()=>setShowSenhaAtual(!showSenhaAtual)}/>
            <span className="error">{errors.senha_atual}</span>
          </div>
          
          <div className="form-group">
            <label htmlFor="nova_senha">Nova Senha</label>
            <input type={showSenha ? "text" : "password"} id="nova_senha" name="nova_senha" placeholder="Insira sua nova senha" required autoComplete="off"
              onChange={(e)=>controllerConfiguracoes.validarNovaSenha(e, dadosUsuario, setDadosUsuario)} className={errors.senha && 'input-error'}
            />
            <img src={showSenha ? eye_show_icon : eye_icon} alt="Icone ver senha" className="sufix" onClick={()=>setShowSenha(!showSenha)}/>
            <span className="error">{errors.nova_senha}</span>
          </div>

          <div className="form-group">
            <label htmlFor="confirmar_senha">Confirmar senha</label>
            <input type={showConfirmarSenha ? "text" : "password"} id="confirmar_senha" name="confirmar_senha" placeholder="Confirme sua senha" required autoComplete="off"
              onChange={(e)=>controllerConfiguracoes.validarConfirmarSenha(e, dadosUsuario, setDadosUsuario)} className={errors.confirmar_senha && 'input-error'}
            />
            <img src={showConfirmarSenha ? eye_show_icon : eye_icon} alt="Icone ver senha" className="sufix"  onClick={()=>setShowConfirmarSenha(!showConfirmarSenha)}/>
            <span className="error">{errors.confirmar_senha}</span>
          </div>

          <div className="form-group">
            <button type="button" onClick={(e)=>controllerConfiguracoes.alterarSenha(e, usuario.id, dadosUsuario, setShowSuccess)}>Salvar</button> 
            
          </div>


              
        </Formulario>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AlterarSenhaModal);