import { Content } from "./styled";
import error_icon from "../../../assets/icons/error.svg";

function Toast({error}){
  return(
    <Content>
      <div className={`toast ${error && 'active'}`}>
        <img src={error_icon} alt="Icone de erro"/> 
        <span>{error}</span>
      </div>
        
    </Content>
  );
}

export default Toast;
