
import Usuario from "../../../../models/Usuario";
import CustomHandle from "../../../../utils/CustomHandle";

class ControllerRedefinirSenha {
  constructor(usuario, setUsuario, errors, setErrors, setLoading, setShowSuccess) {
    this.usuario = usuario;
    this.setUsuario = setUsuario;
    this.errors = errors;
    this.setErrors = setErrors;
    this.setLoading = setLoading;
    this.setShowSuccess = setShowSuccess;

  }

  async handlerSubmit(e, token){
    e.preventDefault();
    sessionStorage.setItem('usuario', JSON.stringify({token}));
    const submitAction = async ()=> token ? await Usuario.redefinirSenha(this.usuario) : await Usuario.solicitarRedefinirSenha(this.usuario);
    const onSuccess = ()=>{ 
      this.setUsuario({...Usuario.model})
      this.setShowSuccess(true);
    };

    await CustomHandle.submitForm('form-redefinir-senha', submitAction, this.errors, this.setErrors, this.setLoading, onSuccess);
  }
}

export default ControllerRedefinirSenha;
