import Formulario from "../Formulario";
import image_icon from "../../../assets/icons/image.svg";
import { Content } from "./styled";

function PostagemInput({postagem, midia, onChange, onSubmit, onSelectMidia, errors}){
  return(
    <Content>
      <Formulario id="form-postagem">
        <div className="form-group">
          <label>Faça uma postagem</label>
          <div className="input-area">
            <textarea id="post-content" placeholder="Compartilhe algo aqui..." value={postagem.texto} onChange={onChange}/>
            {midia && <img src={URL.createObjectURL(midia)}/> }
          </div>
          
          <input type="file" id="midia" hidden onChange={onSelectMidia}/>
          <div className="subimit-area">
            <label for="midia">
              <img src={image_icon} alt="Icone de imagem"/>
            </label>  
            <button type="submit" onClick={onSubmit}>Postar</button>                  
          </div>
          <span className="error">{errors.geral}</span>
        </div>
      </Formulario>
    </Content>
  );
}

export default PostagemInput;