import styled from "styled-components";

export const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  
`

export const Container = styled.div`
  width: 400px;
  max-height: 90vh;
  background-color: #fff;
  border-radius: 10px;
  padding: 34px;
  overflow-y: scroll ;
  

  .title{
    h2{
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 120%;
    }
  }

  .close{
    display: flex;
    width: 100%;
    justify-content: flex-end;

    button{
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: none;
      width: 19px;
      height: 19px;
      cursor: pointer;

    }
  }

  .participante{
    display: flex;
    align-items: center;
    padding: 10px;

    .profile{
      width: 32px;
      height: 32px;
      margin-right: 12px;
      
      img{
        width: 32px;
        height: 32px;
      }
    }

    .location{
      display: flex;
      align-items: center;
      img{
        width: 12px;
        margin-right: 5px;
      }
      span{
        color: #555 ;
        font-size: 12px;
      }
    }

    

    span{
      color: #555 ;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
  }

  .participante:not(:first-of-type) {
    border-top: 1px solid #CFCFCF;
  }


  button{
    width: 100%;
    border-radius: 6px;
    height: 36px;
    margin-top: 12px;
    cursor: pointer;
  }

  .delete-account{
    border: 0.5px solid #D41A1A;
    background: #D41A1A;
    color: #fff;
    margin-top: 30px;
  }



    .add-adm-item, .add-adm-item-list{
    display: flex;
    align-items: center;
    cursor: pointer;
    .add-button{
      margin-top: 10px;
      margin-left: 10px;
    }

    input{
      width: 280px !important;
    }

    .prefix{
      margin-top: -32px !important;
    }

  }

  .add-adm-item-list{
    .profile{
      margin-top: -40px !important;
      width: 32px;
      height: 32px;
      border: 1px solid var(--primary-color);
      
    }
  }  

  
`