import Formulario from "../../../components/Formulario";
import close_icon from "../../../../assets/icons/close.svg";
import plus_icon from "../../../../assets/icons/plus.svg";
import lixeira_icon from "../../../../assets/icons/lixeira.svg";
import add_image from "../../../../assets/add-imagem.png";
import { tiposGerenciamentoModal } from "../../../../utils/data";
import Select from "../../../components/Select";
import { Main } from "../styled";
import { useState } from "react";
import Patrocinador from "../../../../models/Patrocinador";
import { useEffect } from "react";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import Validacao from "../../validacao";
import DeleteDialog from "../../../components/DeleteDialog";
import ValidacaoPatrocinadores from "./validacao";
import { convertToBase64 } from "../../../../utils/conversoes";
import CustomHandle from "../../../../utils/CustomHandle";


function PatrocinadoresModal({ tipo, onClose, patrocinador, setPatrocinador }) {
  const [nomePatrocinador, setNomePatrocinador] = useState("");
  const [patrocinadores, setPatrocinadores] = useState({});
  const [errors, setErrors] = useState({});
  const [showOptions, setShowOptions] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [loading, setShowLoading] = useState(false);
  const [image, setImage] = useState();

  async function filtrarPatrocinadores(e) {
    const searchValue = e.target.value;
    setNomePatrocinador(searchValue);

    let newPatrocinadores = [];

    if (searchValue) {
      newPatrocinadores = await Patrocinador.findSearch(searchValue);
    } else {
      newPatrocinadores = await Patrocinador.findAll();
    }

    setPatrocinadores(newPatrocinadores.data);
  }

  function onClickSelectPatrocinador(patrocinador) {
    setNomePatrocinador(patrocinador.nome);
    setPatrocinador(patrocinador);
    setErrors((prevErrors) => ({ ...prevErrors, patrocinadores: '' }));
  }


  function validar(e, handle) {
    handle(e, patrocinador, errors, setPatrocinador, setErrors);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    
      if (image) {
        convertToBase64([image])
          .then(async (base64Array) => {
            const new_patrocinador = { ...patrocinador, foto: base64Array[0] };
            const submitAction = async () => await Patrocinador.create(new_patrocinador);
            const onSuccess = () => {
              setShowSuccess(true);
            }
            await CustomHandle.submitForm('form-patrocinador', submitAction, errors, setErrors, setShowLoading, onSuccess);
          })
          .catch(() => {
            setErrors({ ...errors, midias: "Ocorreu um erro ao tratar a imagem. Tente novamente" })
          });
      } else {
        if (!patrocinador.id) {
          setErrors({ ...errors, foto: 'Campo obrigatório' });
        } else {
          const new_patrocinador = { ...patrocinador};
          delete new_patrocinador.foto;
          const submitAction = async () => await Patrocinador.create(new_patrocinador);
          const onSuccess = () => {
            setShowSuccess(true);
          }
          await CustomHandle.submitForm('form-patrocinador', submitAction, errors, setErrors, setShowLoading, onSuccess);
        }
        
      }
    
  }

  async function handleDelete() {

    setShowLoading(true);
    const result = await Patrocinador.delete(patrocinador.id);
    if (result.response) {
      const data = result.response.data;
      setErrors((prevErrors) => ({ ...prevErrors, delete: data.error }));
    } else {
      window.location.href = "/gerenciamento";
    }
    setShowLoading(false);

  }

  function limparCampos() {
    setNomePatrocinador("");
    setImage(null);
    setPatrocinador({});
    setErrors({});
    document.getElementById("foto").value = null;
  }

  function handleSuccessButton() {
    setShowSuccess(false);
    if (tipo === tiposGerenciamentoModal.CRIAR) {
      limparCampos();
    } else {
      // findAllPatrocinadores();
    }
  }

  useEffect(() => {
    setNomePatrocinador(patrocinador.nome);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      {tipo !== tiposGerenciamentoModal.NONE &&
        <Main>

          <div className="content-modal modal-patrocinador">
            <div className="top">
              <h3>Patrocinadores</h3>
              <button type="button" onClick={() => { limparCampos(); onClose() }}>
                <img src={close_icon} alt="Icone de fechar" />
              </button>
            </div>

            <h4>
              {tipo === tiposGerenciamentoModal.EDITAR ? "Editar ou excluir um usuário" : "Adicionar um novo patrocinador"}
            </h4>
            <Formulario className="form-patrocinador" id="form-patrocinador">
              <div className="container-left">
                {tipo === tiposGerenciamentoModal.EDITAR &&
                  <div className="form-group">
                    <label htmlFor="patrocinadores">Lista de patrocinadores</label>
                    <Select
                      id="patrocinadores"
                      name="patrocinadores"
                      placeholder="Pesquise por patrocinadores aqui"
                      required={false}
                      autoComplete="off"
                      value={nomePatrocinador}
                      onChange={filtrarPatrocinadores}
                      className={`input-prefix ${errors.patrocinadores && 'input-error'}`}
                      options={patrocinadores.rows}
                      onClickOption={onClickSelectPatrocinador}
                      showOptions={showOptions}
                      setShowOptions={setShowOptions}
                    />
                    <span className="error">{errors.patrocinadores}</span>
                  </div>
                }

                <div className="form-group">
                  <label htmlFor="nome">Nome do Patrocinador</label>
                  <input
                    required
                    value={patrocinador.nome || ""}
                    type="text"
                    name="nome"
                    id="nome"
                    placeholder={tipo === tiposGerenciamentoModal.EDITAR ? "Escolha um patrocinador" : "Digite o nome do novo patrocinador"}
                    onChange={(e) => ValidacaoPatrocinadores.validarNome(e, patrocinador, errors, setPatrocinador, setErrors)}
                  />
                  <span className="error">{errors.nome}</span>
                </div>

                <div className="form-group">
                  <label htmlFor="link">Site do Patrocinador</label>
                  <input
                    required
                    value={patrocinador.link || ""}
                    type="text"
                    name="link"
                    id="link"
                    placeholder={"Digite o link patrocinador"}
                    onChange={(e) => ValidacaoPatrocinadores.validarLink(e, patrocinador, errors, setPatrocinador, setErrors)}
                  />
                  <span className="error">{errors.link}</span>
                </div>
                

                <div className="form-group">
                  <label>Imagem do banner do patrocinador</label>

                  <input id="foto" type="file" name="foto" accept="image/*" required={!patrocinador.id} onChange={(e) => { ValidacaoPatrocinadores.handleChangeMidia(e, setImage) }} />
                  <div className="foto">
                    <label htmlFor="foto">

                      <div className={`image ${errors.foto && 'image-error'}`} onClick={() => setErrors({ ...errors, foto: "" })}>
                        {image || patrocinador?.foto?.path ?
                          <>
                            <img className={`image-added`} src={image ? URL.createObjectURL(image) : patrocinador?.foto?.path} alt="Imagem do produto" />
                            <div className="blur"
                              onClick={() => { }}
                            >
                              <div className="blur-bg"></div>
                              <img src={lixeira_icon} alt="Icone de Lixeira" />
                            </div>
                          </> :
                          <img className="plus-icon" src={plus_icon} alt="Iocne de adicionar" />
                        }
                      </div>
                    </label>
                  </div>
                  <span className="error">{errors.foto}</span>
                </div>

                <div className="buttons">
                  <div className="form-group">
                    <button disabled={tipo === tiposGerenciamentoModal.EDITAR && !patrocinador.id} className="save-button"
                      type="button" onClick={handleSubmit}
                    >
                      Salvar edição
                    </button>
                    <span className="error">{errors.geral}</span>
                  </div>

                  {tipo === tiposGerenciamentoModal.EDITAR &&
                    <div className="form-group">
                      <button disabled={!patrocinador.id} className="remove-button" onClick={() => setShowDelete(true)} type="button">Excluir patrocinador</button>
                    </div>
                  }
                </div>
              </div>

              <div className="container-right">
                <span className="title">Visualização do banner</span>

                <div className="cards">
                  <div className="card-site">
                    <span className="sub-title">Site</span>
                    <div className="image">
                      <img src={image ? URL.createObjectURL(image) : patrocinador?.foto?.path || add_image} alt="Imagem do banner" />
                    </div>

                  </div>

                  <div className="card-app">
                    <span className="sub-title">Aplicativo Geoplatio</span>
                    <div className="image">
                      <img src={image ? URL.createObjectURL(image) : patrocinador?.foto?.path || add_image} alt="Imagem do banner" />
                    </div>

                  </div>
                </div>
              </div>
            </Formulario>
          </div>

          {loading && <Loading titulo={"Aguarde um momento"} subtitulo={"Estamos validando suas informações"} />}
          {showSuccess &&
            <AlertDialog
              width={320}
              titulo={`Patrocinador ${patrocinador.id ? 'atualizado' : 'cadastrado'} com sucesso!`}
              buttonText={`${patrocinador.id ? 'Voltar' : 'Cadastrar novo patrocinador'}`}
              buttonOnClick={handleSuccessButton}
            />
          }
          {showDelete &&
            <DeleteDialog
              error={errors.delete}
              titulo="Deseja realmente excluir esse patrocinador?"
              subtitulo="Todos os dados deste patrocinador seram excluídos de forma permanente."
              onDelete={handleDelete}
              onClose={() => setShowDelete(false)}
            />
          }
        </Main>
      }
    </>
  );
};

export default PatrocinadoresModal;