import SideBar from "../../../components/SideBar";
import { Main } from "../../styled";
import { useState } from "react";
import OrganizacaoModal from "../OrganizacaoModal";
import InputSearch from "../../../components/InputSearch";
import CardOrganizacao from "../../../components/Cards/CardOrganizacao";

function Home(){
  const [showModal, setShowModal] = useState(false);
  return(
    <SideBar>
      <Main>
        {showModal && <OrganizacaoModal onClose={()=>setShowModal(false)}/>}
        <div className="content">
          <section>
            <div className="top">
              <div className="title">
                <h2>Doar</h2>
                <span>Faça contribuições para nos ajudar a manter o projeto vivo.</span>
              </div>
              <InputSearch text="Pesquisar plantio"/>
            </div>
          </section>

          <section>
            <div className="painel">
              {
                Array.from({ length: 5 }).map((_, index) => (
                  <CardOrganizacao key={`card-organizacao-${index}`} onClick={()=>setShowModal(true)}/>   
                ))
              }
            </div>
          </section>
        </div>
      </Main>
    </SideBar>
  );
}

export default Home;