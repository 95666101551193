import styled from "styled-components";

export const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;

  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
`

export const Container = styled.div`
  width: 500px;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 60px;

  .image{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    height: 200px;
    border-radius: 10px 10px 0 0;

    img{
      width: 100%;
    }

    .close{
      position: absolute;
      top: 20px;
      right: 20px;

      button{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        cursor: pointer;

        img{
          width: 10px;
        }
      }
    }
  }

  h3{
    margin: 20px 0 3px 20px ;
  }
  
  .title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    img{
      margin-right: 20px;
      margin-top: 10px;
    }
    
  }

  .info{
    margin: 0 20px;
    display: flex;
    align-items: center;

    img{
      margin-right: 5px;
    }
  }

  .social-network{
    margin-left: 20px;
    margin-top: 8px;
    display: flex;

    img{
      width: 20px;
      margin-right: 5px;
    }

    a{
      color: var(--black-color);
      text-decoration: none;
    }
  }

  .gallery{
    display: flex;
    margin-left: 20px;
    margin-top: 8px;

    .image{
      overflow: hidden;
      height: 80px;
      width: 80px;
      border-radius: 5px;
      margin-right: 5px;
      cursor: pointer;

      img{
        width: 100%;
      }
    }

    .image-more{
      position: relative;
      img{
        opacity: 0.4;
        position: absolute;
      }

      span{
        position: absolute;
        font-weight: bold;
      }
    }
  }

  
  .about{
    margin: 0 20px 0 20px;
  }

  .bottom{
    button{
      height: 40px;
      width: calc(100% - 40px);
      margin: 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      color: #fff;
      align-self: flex-end;
      margin-right: 20px;
      background-color: var(--primary-color);
    }
  }
  
`