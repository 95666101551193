import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  span{
    color: #555 ;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin: 15px 0;
  }

  img{
    margin-left: auto;
    margin-top: 15px;
  }
`