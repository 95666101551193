import { Content } from "./styled";
import logo from "../../../assets/logo_plantando.png";
// import doar_icon from "../../../assets/icons/doar.svg";
import forum_icon from "../../../assets/icons/forum.svg";
import grupos_icon from "../../../assets/icons/grupos.svg";
import minhas_arvores_icon from "../../../assets/icons/minhas_arvores.svg";
import configuracoes_icon from "../../../assets/icons/settings.svg";
import perfil_icon from "../../../assets/icons/perfil.svg";
import mapa_icon from "../../../assets/icons/mapa.svg";
import plantar_icon from "../../../assets/icons/plantar.svg";
import gerenciamento_icon from "../../../assets/icons/gerenciamento.svg";
import relatorios_icon from "../../../assets/icons/relatorios.svg";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../utils/config";

function SideBar({ usuario, children }) {
  const path = window.location.pathname.split('/')[1]
  return (
    <Content>
      <div className="sidebar">
        <div className="top-logo">
          <img src={logo} alt="Logo Plantando para o planeta" />
        </div>

        <nav>
          <Link to="/plantar" className={`nav-item ${path === "plantar" && 'active'} `}>
            <img src={plantar_icon} alt="Icone de plantar" />
            <span>Plantar</span>
          </Link>

          <Link to="/minhas-arvores" className={`nav-item ${path === "minhas-arvores" && 'active'} `}>
            <img src={minhas_arvores_icon} alt="Icone de minhas árvores" />
            <span>Minhas Árvores</span>
          </Link>

          <Link to="/grupos" className={`nav-item ${path === "grupos" && 'active'} `}>
            <img src={grupos_icon} alt="Icone de grupos" />
            <span>Grupos</span>
          </Link>

          {/*<Link to="/doar" className={`nav-item ${path === "doar" && 'active'} `}>
            <img src={doar_icon} alt="Icone de doar"/>
            <span>Doar</span>
          </Link>*/}

          <Link to="/forum" className={`nav-item ${path === "forum" && 'active'} `}>
            <img src={forum_icon} alt="Icone de forum" />
            <span>Fórum</span>
          </Link>

          <Link to="/perfil" className={`nav-item ${path === "perfil" && 'active'} `}>
            <img src={perfil_icon} alt="Icone de perfil" />
            <span>Perfil</span>
          </Link>

          <Link to="/mapa" className={`nav-item ${path === "mapa" && 'active'} `}>
            <img src={mapa_icon} alt="Icone de mapa" />
            <span>Mapa</span>
          </Link>

          <Link to="/configuracoes" className={`nav-item ${path === "configuracoes" && 'active'} `}>
            <img src={configuracoes_icon} alt="Icone de configuracoes" />
            <span>Configurações</span>
          </Link>


          {
            usuario.tipo === "Administrador" &&
            <>
              <Link to="/gerenciamento" className={`nav-item ${path === "gerenciamento" && 'active'} `}>
                <img src={gerenciamento_icon} alt="Icone de gerenciamento" />
                <span>Gerenciamento</span>
              </Link>

              <Link to="/relatorios" className={`nav-item ${path === "relatorios" && 'active'} `}>
                <img src={relatorios_icon} alt="Icone de relatorios" />
                <span>Relatórios</span>
              </Link>
            </>
          }


        </nav>
      </div>
      <div className="panel">
        {children}
      </div>

    </Content>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);