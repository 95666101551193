import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Cadastro from "./ui/modules/auth/Cadastro";
import CadastroConfirmacao from "./ui/modules/auth/CadastroConfirmacao";
import Login from "./ui/modules/auth/Login";
import RedefinirSenha from "./ui/modules/auth/RedefinirSenha";
import HomePlantar from "./ui/modules/Plantar/Home";
import HomeMinhasArvores from "./ui/modules/MinhasArvores/Home";
import HomeGrupos from "./ui/modules/Grupos/Home";
import HomeDoar from "./ui/modules/Doar/Home";
import HomeForum from "./ui/modules/Forum/Home";
import HomePerfil from "./ui/modules/Perfil/Home";
import HomeGerenciamento from "./ui/modules/Gerenciamento/Home";
import Grupos from "./ui/modules/Grupos/Grupos";
import HomeMapa from "./ui/modules/Mapa/Home";
import HomeConfiguracoes from "./ui/modules/Configuracoes/Home";
import HomeRelatorios from "./ui/modules/Relatorios/Home";

import { useEffect } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "./utils/config";
import Usuario from "./models/Usuario";
import { AppDownload } from "./ui/modules/AppDowload";

const RoutesApp = ({setUsuario}) => {
  const path = window.location.pathname;

  function isMobileDevice() {
    const userAgent = navigator.userAgent;
    const mobileKeywords = ["Mobile", "Android", "iPhone", "iPad", "Windows Phone"];

    return mobileKeywords.some((keyword) => userAgent.includes(keyword));
  }

  async function  getUsuario(){
    const usuario_json = JSON.parse(sessionStorage.getItem('usuario'));
    const usuario = await Usuario.findById(usuario_json.id);
    if(usuario.data){
      setUsuario(usuario.data);
    }
  }
  
  useEffect(() => {
    const path_free = ['/login', '/cadastro', '/redefinir-senha', '/app-download'];
    if (!path_free.includes(path) && !sessionStorage.getItem('usuario')) {
      window.location.href = "/login";
    }else if(!path_free.includes(path)){
      getUsuario();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);


 
  return (
    !isMobileDevice() || path === '/redefinir-senha' ?
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/cadastro" element={<Cadastro />} />
        <Route path="/redefinir-senha" element={<RedefinirSenha />} />
        <Route path="/cadastro/confirmacao" element={<CadastroConfirmacao />} />
        <Route path="/plantar" element={<HomePlantar />} />
        <Route path="/minhas-arvores" element={<HomeMinhasArvores />} />
        <Route path="/minhas-arvores/atualizar/:plantio_id" element={<HomePlantar/>} />
        <Route path="/grupos" element={<HomeGrupos />} />
        <Route path="/grupos/:id" element={<Grupos />} />
        <Route path="/doar" element={<HomeDoar />} />
        <Route path="/forum" element={<HomeForum />} />
        <Route path="/perfil" element={<HomePerfil />} />
        <Route path="/mapa" element={<HomeMapa/>} />
        <Route path="/configuracoes" element={<HomeConfiguracoes />} />
        <Route path="/gerenciamento" element={<HomeGerenciamento />} />
        <Route path="/relatorios" element={<HomeRelatorios />} />
      </Routes>
    </BrowserRouter>
  : <AppDownload/>);
};

export default connect(mapStateToProps, mapDispatchToProps)(RoutesApp);
