import close_icon from "../../../../assets/icons/close.svg";
import perfil_icon from "../../../../assets/perfil.png";
import pin_icon from "../../../../assets/icons/pin.svg";
import { tiposGerenciamentoModal } from "../../../../utils/data";
import { Main } from "../styled";
import {useState} from "react";
import { useEffect } from "react";
import Loading from "../../../components/Loading";
import ControllerListaUsuarios from "./controller";
import { Content, PerfilItem } from "./styled";
import CircularImage from "../../../components/CircularImage";
import InputSearch from "../../../components/InputSearch";

function ListaUsuariosModal({tipo, onClose, usuario, openEditModal, setUsuario}){
  const [usuarios, setUsuarios] = useState();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [errors, setErrors] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const controllerListaUsuarios = new ControllerListaUsuarios(page, setPage, search, errors, setErrors, setSearch, usuarios, setUsuarios, setUsuario, setShowModal,  setLoading);

  useEffect(()=>{
    controllerListaUsuarios.findUsuarios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, search]);

  return(
    <>
      {tipo !== tiposGerenciamentoModal.NONE  &&
        <Main>
          {loading && <Loading titulo={"Aguarde um momento"} />}

          <div className="content-modal">
            <div className="top">
              <h3>Usuarios</h3>
              <button type="button" onClick={onClose}>
                <img src={close_icon} alt="Icone de fechar"/>
              </button>
            </div>
            <Content>
            <InputSearch text="Buscar por usuários" onChangeCapture={(e)=>controllerListaUsuarios.handleSearch(e, typingTimeout, setTypingTimeout)}/>
              <span>Mostrando {usuarios?.rows?.length} de {usuarios?.count} usuários</span>
              {usuarios?.rows?.map(usuario=>(
                <PerfilItem>
                  <div>
                    <CircularImage src={usuario.foto_perfil?.path || perfil_icon}/>
                  </div>
                  <div className="user-info">
                    {usuario.nome}
                    <div className="location-info">
                      <img src={pin_icon}/>
                      {usuario.estado}
                    </div>
                  </div>

                  <button type="button" onClick={()=>{
                    setUsuario(usuario);
                    openEditModal();
                  }}>Editar</button>
                  
                </PerfilItem>
              ))} 
              
              {usuarios?.rows?.length !== usuarios?.count && <span className="more" onClick={()=>setPage(page+1)}> Ver mais </span> }

            </Content>
          </div>        
          
        </Main>
      }
    </>
  );
};

export default ListaUsuariosModal;