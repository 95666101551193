import Grupo from "../../../../models/Grupo";
import Usuario from "../../../../models/Usuario";
import CustomHandle from "../../../../utils/CustomHandle";
import { estados } from "../../../../utils/data";

class InformacoesGrupoController{
    constructor (grupo, setGrupo, errors, setErros, setLoading){
        this.grupo = grupo;
        this.setGrupo = setGrupo;
        this.errors = errors;
        this.setErrors = setErros;
        this.setLoading = setLoading;
    }

    validarNome(e) {
      this.setGrupo({ ...this.grupo, nome: e.target.value });
      if (this.grupo.nome.length < 8) {
        this.setErrors({ ...this.errors, nome: "O nome deve conter pelo menos 8 caracteres", geral: "" });
      } else {
        this.setErrors({ ...this.errors, nome: "", geral: "" });
      }
    }

    validarSobre(e) {
      this.setGrupo({ ...this.grupo, sobre: e.target.value });
      if (this.grupo.sobre.length < 20) {
        this.setErrors({ ...this.errors, sobre: "O sobre deve conter pelo menos 20 caracteres", geral: "" });
      } else {
        this.setErrors({ ...this.errors, sobre: "", geral: "" });
      }
    }

    validarEstado(e) {
      this.setGrupo({ ...this.grupo, estado: e.target.value });
      if (!estados.includes(e.target.value)) {
        this.setErrors({ ...this.errors, estado: "Digite um estado válido", geral: "" });
      } else {
        this.setErrors({ ...this.errors, estado: "", geral: "" });
      }
    }

    removeAdministrador(administrador, administradores, setAdministradores){
      const new_administradores = [];
      for(const item of administradores){
        if(item.id){
          if(item.usuario.id === administrador.usuario.id){
            item.is_admin = false;
          }
          new_administradores.push(item);
        }else{
          if(item.usuario.id !== administrador.usuario.id){
            new_administradores.push(item);
          }
        }
      }

      setAdministradores(new_administradores);

    }

    addAdministrador(grupoId, administrador, administradores, setAdministradoresId, setUsuarios, setNomeUsuario){
      const new_administrador = {
        is_admin : true,
        is_creador: false,
        grupo_id: grupoId,
        usuario_id: administrador.id,
        usuario: administrador
      }

      administradores.push(new_administrador);
      setAdministradoresId((administradores)=>[...administradores, administrador.id]);
      setUsuarios({});
      setNomeUsuario('');
    }

    async filtrarUsuarios(e, setUsuarios, setNomeUsuario, administradoresId){
      setNomeUsuario(e.target.value);
      const result = await Usuario.findSearch(e.target.value);
      const onSuccess = (response)=>{
        const new_usuarios = [];
        for(const usuario of response.rows){
          if(!administradoresId.includes(usuario.id)){
            new_usuarios.push(usuario);
          }
        }
        setUsuarios(usuarios=>({...usuarios, rows : new_usuarios}));
      };
  
      CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);
    }

    async deleteGrupo(onDelete){
      const result = await Grupo.delete(this.grupo.id);
      const onSuccess = ()=>{
        onDelete();
      };
  
      CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);
    }

    async alterarAdministradores(administadores, setShowSuccess, setGrupo){
      let new_administradores = [];

      for(const administador of administadores){
        let new_administrador = {...administador};
        delete new_administrador.usuario;

        new_administradores.push(new_administrador);

      }

      const result = await Grupo.updateAdministradores(this.grupo.id, {"administradores" : new_administradores});
      const onSuccess = async()=>{
        const response = await Grupo.findById(this.grupo.id);
        setGrupo(response.data);
        
        setShowSuccess(true);
      }
      CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);

    }

    async alterarDados(e, form, setGrupo, setShowSuccess){
      e.preventDefault();
      let new_grupo = {...this.grupo};      
      if(!new_grupo.sobre){
        new_grupo.sobre = '';
      }

      let body = {...new_grupo};
      delete body.foto_capa;
      delete body.grupos_usuarios;
      delete body.administradores;
  
      const submitAction = async ()=> await Grupo.create(body);
      const onSuccess = async  ()=>{
        setShowSuccess(true);
        setGrupo(new_grupo);
      }
  
      await CustomHandle.submitForm(form, submitAction, this.errors, this.setErrors, this.setLoading, onSuccess);
  
  
    }
  
}

export default InformacoesGrupoController;