import styled from "styled-components";

export const Content = styled.div`
  margin-bottom: 24px;
  .coment-area{
    padding: 18px 24px;
    background: #FCFCFC;
    box-shadow: 0px 2px 2px rgba(36, 36, 36, 0.1);
    border-radius: 6px;
    height: 96px;
    display: flex;

    .profile{
      width: 39px;
      height: 39px;
      img{
        width: 39px;
      }
      border: 1px solid var(--primary-color);
    }
    
    textarea{
      width: 100% !important;
      border: none;
      resize: none;
      outline:0px none transparent;
      background-color: transparent;
    }
  }
  
  .subimit-area{
    display: flex;
    align-items: center;
    justify-content: right;
    margin-top : 18px;
    
    button{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 73px;
      height: 24px;
      margin: 0;
      background: #8F8F8F;
      box-shadow: 0px 1px 1px rgba(36, 36, 36, 0.1);
      border-radius: 4px;
    }

    .active{
      background-color: var(--primary-color);
    }

    
  }
`