import styled from "styled-components";

export const Content = styled.div`
  position: absolute;
  padding: 10px;
  width: 300px;
  background-color: #fff;
  box-shadow: 0px 1px 1px rgba(36, 36, 36, 0.1);

  .item{
    cursor: pointer;
  }

  .nome{
    font-weight: 600;
  }

  .mencoes{
    color: #9e9e9e;
  }

`