import api from '../services/api';

const Postagem = {
  model: {texto: ""},
  
  create : async function (data) {
    return await api.post('postagens', data)
    .then(response=>response)
    .catch(error=>error);
  },

  delete : async function (postagem_id) {
    return await api.delete(`postagens/id/${postagem_id}`)
    .then(response=>response)
    .catch(error=>error);
  },
  
  findAllForum : async function (page, search) {
    return await api.get(`postagens/forum/${page}/10?search=${search}`)
    .then(response=>response)
    .catch(error=>error);
  },
  
  findAllGrupo : async function (page, grupo_id) {
    return await api.get(`postagens/grupo/${grupo_id}/${page}/10`)
    .then(response=>response)
    .catch(error=>error);
  },

  like : async function (usuario, postagem) {
    return await api.post(`postagens/curtida`, {usuario_id : usuario.id, postagem_id: postagem.id})
    .then(response=>response)
    .catch(error=>error);
  },

  findSearch : async function (data) {
    return await api.get(`postagens/data/${data}/1/20`)
    .then(response=>response)
    .catch(error=>error);
  }
}

export default Postagem;
