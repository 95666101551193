import SideBar from "../../../components/SideBar";
import { Main } from "../../styled";
import arvore_image from "../../../../assets/arvore.png";
import perfil_image from "../../../../assets/perfil.png";
import informations_icon from "../../../../assets/icons/informations.svg";
import lista_participantes_icon from "../../../../assets/icons/lista_participantes.svg";
import mail_icon from "../../../../assets/icons/mail.svg";
import InputSearch from "../../../components/InputSearch";
import CardGrupo from "../../../components/Cards/CardGrupo";
import { Content } from "./styled";
import PostagemInput from "../../../components/PostagemInput";
import CardPostagem from "../../../components/Cards/CardPostagem";
import CircularImage from "../../../components/CircularImage";
import { tiposPublicacao } from "../../../../utils/data";
import arrow_back_icon from "../../../../assets/icons/arrow-back.svg";
import { useState } from "react";
import ControllerGrupo from "./controller";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../../utils/config";
import Loading from "../../../components/Loading";
import Postagem from "../../../../models/Postagem";
import TagsList from "../../../components/TagsList";
import Comentar from "../../Forum/Comentar";
import InformacoesGrupoModal from "../InformacoesGrupo/InformacoesGrupoModal";
import ListaParticipantesModal from "../ListaParticipantesModal";
import SolicitacoesPendentesModal from "../SolicitacoesPendentesModal";
import NovoGrupoModal from "../NovoGrupoModal";

function Grupos({usuario, errors, setErrors}){
  const {id} = useParams();
  const [postagem, setPostagem] = useState({...Postagem.model});
  const [postagens, setPostagens] = useState();
  const [subgrupos, setSubgrupos] = useState();
  const [loading, setLoading] = useState(false);
  const [grupo, setGrupo] = useState();
  const [postagemSelected, setPostagemSelected] = useState();
  const [showInformacoesGrupoModal, setShowInformacoesGrupoModal] = useState(false);
  const [showNovoSubgrupoModal, setShowNovoSubgrupoModal] = useState(false);
  const [showListaParticipantesModal, setShowListaParticipantesModal] = useState(false);
  const [showSolicitacoesPendentesModal, setShowSolicitacoesPendentesModal] = useState(false);
  const [showPostagens, setShowPostagens] = useState(false);
  const [loadingSubtitulo, setLoadingSubtitulo] = useState();
  const [tag, setTag] = useState();
  const [tags, setTags] = useState();
  const [midia, setMidia] = useState();
  const [page, setPage] = useState(1);

  const controllerGrupo = new ControllerGrupo(usuario, grupo, errors, postagem, midia, setPostagem, setPostagens, setMidia, setErrors, setLoading);

  useEffect(()=>{
    controllerGrupo.findGrupoById(setGrupo, id);
    controllerGrupo.findSubgruposByGrupoId(setSubgrupos, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(()=>{
    controllerGrupo.findPostagensByGrupoId(setPostagens, page, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, id]);

  useEffect(()=>{
    controllerGrupo.findTags(tag, setTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag]);

  return(
    <SideBar>
      <Main>
        { loading && <Loading/> }
        { showInformacoesGrupoModal && <InformacoesGrupoModal onClose={()=>setShowInformacoesGrupoModal(false)} grupo={grupo} setGrupo={setGrupo}/>}
        { showListaParticipantesModal && <ListaParticipantesModal onClose={()=>setShowListaParticipantesModal(false)} grupo={grupo}/>}
        { showSolicitacoesPendentesModal && <SolicitacoesPendentesModal onClose={()=>setShowSolicitacoesPendentesModal(false)} grupo={grupo}/>}
        { showNovoSubgrupoModal && <NovoGrupoModal onClose={()=>setShowNovoSubgrupoModal(false)} grupo_pai={grupo} />}

        <Content className="content" id="content">
          <button className="back" id="button-back" onClick={
            showPostagens ? ()=>setShowPostagens(false) : ()=>controllerGrupo.voltar(setPostagemSelected)
          }>
            <img src={arrow_back_icon} alt="Icone de voltar"/>
            <span> Voltar </span>
          </button>

          <section>
            <div className="top">
              <div className="title">
                <h2>Grupo</h2>
                <span>{grupo?.nome}</span>
              </div>
              <InputSearch text="Pesquisar grupo"/>
            </div>
          </section>

          <div className="panel">
            {
              !postagemSelected ?
              <section className="left-section">
                <PostagemInput 
                  errors={errors}
                  postagem={postagem} 
                  midia = {midia}
                  onChange={(e)=>controllerGrupo.validarTexto(e, setTag)} 
                  onSubmit={(e)=>controllerGrupo.handleSubmit(e, setLoadingSubtitulo)}
                  onSelectMidia={(e)=>controllerGrupo.handleSelectMidia(e, setMidia)}
                />
                
                {(tag && tag !== "#" && tags?.rows?.length > 0) && 
                  <TagsList tags={tags} onSelect={(nome)=>controllerGrupo.completaTag(nome, setTag)}/> 
                }
                
                { subgrupos?.count === 0 || showPostagens ?
                  <section className="postagens-section">
                    <h3>Postagens</h3>

                    {

                      postagens?.rows?.length > 0 ?
                      <>
                        {postagens?.rows?.map((postagem) => (
                          <CardPostagem 
                            postagem={postagem}
                            tipo={tiposPublicacao.POSTAGEM}
                            handleLike={()=>controllerGrupo.handleLike(postagem, postagens)}
                            handleComentar= {()=>controllerGrupo.selecionaPostagem(setPostagemSelected, postagem, postagens)}
                          />
                        ))}
                        { postagens.rows.length !== postagens.count &&
                          <span className="more" onClick={()=>setPage(page+1)}> Ver mais </span>
                        }
                      </>:
                      <div className="painel-empty">
                        <img src={arvore_image} alt="Icone de uma árvore"/>
                        <span>Ainda não há nada aqui. Seja o primeiro a fazer uma postagem...</span>
                      </div>
                    }
                  </section> :
                  <section className="grupos-section">
                    <h3>Grupos</h3>
                    <div className="grupos-list">
                    <CardGrupo textButton={"Ver postagens"} grupo={grupo} onClick={()=>{setShowPostagens(true)}}/>
                      {
                        subgrupos?.rows?.map(subgrupo=>
                          <CardGrupo grupo={subgrupo} link={`/grupos/${subgrupo?.id}`}/>      
                        )
                      }
                    </div>
                  </section>
                }
              </section>
              :
              <Comentar postagemSelected={postagemSelected} setPostagemSelected={setPostagemSelected} handleLike={()=>controllerGrupo.handleLike(postagemSelected, postagens)}/>
            }

           <section className="right-section">

              {
                grupo?.is_admin ?
                <section className="actions-section"> 
                  <div className="action-item" onClick={()=>setShowInformacoesGrupoModal(true)}>
                    <img src={informations_icon} alt="Icone de informações"/>
                    <span>Informações do {grupo?.tipo === "Subgrupo" ? "subgrupo" : "grupo"}</span>
                  </div>
                  <div className="action-item" onClick={()=>setShowListaParticipantesModal(true)}>
                    <img src={lista_participantes_icon} alt="Icone de informações"/>
                    <span>Lista de participantes</span>
                  </div>
                  {grupo?.tipo === "Grupo Principal" && 
                    <>
                      <div className="action-item" onClick={()=>setShowSolicitacoesPendentesModal(true)}>
                        <img src={mail_icon} alt="Icone de mensagens"/>
                        <span>Solicitações pendentes</span>
                      </div>
                    
                      <div className="action-item" onClick={()=>setShowNovoSubgrupoModal(true)}>
                        <img src={informations_icon} alt="Icone de informações"/>
                        <span>Subgrupos</span>
                      </div>
                    </>
                  }
                </section> :   
                <section className="admins-section">
                  <h3>Administradores</h3> 
                  {
                    grupo?.administradores?.map(administrador=>
                      <div className="admin-profile">
                        <CircularImage src={administrador?.usuario?.foto_perfil?.path || perfil_image} alt="Foto de perfil do participante"/>
                        <span>{administrador?.usuario?.nome}</span>
                      </div>
                    )
                  }
                
                </section>
              }

              <section className="participantes-section">
                <h3>Participantes</h3> 

                <div className="list">
                  {grupo?.grupos_usuarios?.map(grupo_usuario=>
                    <CircularImage src={grupo_usuario?.usuario?.foto_perfil?.path || perfil_image} alt="Foto de perfil do participante"/>
                  )}
                  
                </div>

                <span>Você {grupo?.quantidade_usuarios > 1 &&  `e mais ${grupo?.quantidade_usuarios-1 || 'alguns'} participantes` } </span>
              </section>

              {/* <section className="semelhantes-section">
                <h3>Grupos Semelhantes</h3>
                {
                  Array.from({ length: 3 }).map((_, index) => (
                    <CardGrupo details={false}/>
                  ))
                }
              </section> */}

            </section>
          </div>
        </Content>
      </Main>
    </SideBar>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Grupos);