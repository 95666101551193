import { Content, Main } from "./styled";
import elipce from "../../../assets/icons/elipce.svg";

function Loading({titulo, subtitulo}){
  return(
    <Main>
      <Content>
        <div className="loader">
          <img src={elipce} alt="Icone de Carregamento" className="elipce"/>         
        </div>

        <h3>{titulo || "Carregando as informações"}</h3>
        <h4>{subtitulo || "Deixando tudo pronto para você"}</h4>
      </Content>
    </Main>
  );
}

export default Loading;