import styled from "styled-components";

export const Main = styled.div`
  z-index: 2000;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(50,50,50,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Content = styled.div`
 
  background-color: #fff;
  width: 335px;
  height: 335px;
  background: #FFFFFF;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .loader {
    position: relative;
    width: 95px; 
    height: 95px;

    img{
      width: 95px;
    }
  }

  h3{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    margin-top: 37px;
  }

  h4{
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 150%;
    color: #8F8F8F;
    margin-top: 18px;
    text-align: center;
  }

`