import SideBar from "../../../components/SideBar";
import account_icon from "../../../../assets/icons/account.svg";
import lock_icon from "../../../../assets/icons/lock.svg";
import edit_icon from "../../../../assets/icons/edit.svg";
import notifications_icon from "../../../../assets/icons/notifications.svg";
import { Main } from "../../styled";
import { Content } from "./styled";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../../utils/config";
import AlterarEmailModal from "../AlterarEmailModal";
import AlterarContatoModal from "../AlterarContatoModal";
import { useState } from "react";
import AlterarEstadoModal from "../AlterarEstadoModal";
import AlterarCidadeModal from "../AlterarCidadeModal";
import AlterarNomeModal from "../AlterarNomeModal";
import AlterarSenhaModal from "../AlterarSenhaModal";
import ConfirmarDeletarModal from "../ConfirmarDeletarModal";
import DeleteDialog from "../../../components/DeleteDialog";
import Usuario from "../../../../models/Usuario";
import { useNavigate } from "react-router-dom";
import ControllerConfiguracoes from "./controller";
import Loading from "../../../components/Loading";

function Home({usuario, setUsuario, errors, setErrors}){
  const [showAlterarEmailModal, setShowAlterarEmailModal] = useState(false);
  const [showAlterarContatoModal, setShowAlterarContatoModal] = useState(false);
  const [showAlterarEstadoModal, setShowAlterarEstadoModal] = useState(false);
  const [showAlterarCidadeModal, setShowAlterarCidadeModal] = useState(false);
  const [showAlterarNomeModal, setShowAlterarNomeModal] = useState(false);
  const [showAlterarSenhaModal, setShowAlterarSenhaModal] = useState(false);
  const [showConfirmarDeletarModal, setShowConfirmarDeletarModal] = useState(false);
  const [showDeletarDialog, setShowDeletarDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const [checked, setChecked] = useState(true);
  const navigate = useNavigate();

  const controllerConfiguracoes = new ControllerConfiguracoes(usuario, errors, setErrors, setLoading, setUsuario);

  function logout (){
    sessionStorage.removeItem('usuario');
    setUsuario(Usuario.model);
    navigate('/login');
  }

  return(
    <SideBar>
      <Main>
        <Content className="content">
          { showAlterarEmailModal && <AlterarEmailModal onClose={()=>setShowAlterarEmailModal(false)}/> }
          { showAlterarContatoModal && <AlterarContatoModal onClose={()=>setShowAlterarContatoModal(false)}/> }
          { showAlterarEstadoModal && <AlterarEstadoModal onClose={()=>setShowAlterarEstadoModal(false)}/> }
          { showAlterarCidadeModal && <AlterarCidadeModal onClose={()=>setShowAlterarCidadeModal(false)}/> }
          { showAlterarNomeModal && <AlterarNomeModal onClose={()=>setShowAlterarNomeModal(false)}/> }
          { showAlterarSenhaModal && <AlterarSenhaModal onClose={()=>setShowAlterarSenhaModal(false)}/> }
          { showConfirmarDeletarModal && <ConfirmarDeletarModal onClose={()=>setShowConfirmarDeletarModal(false)}/> }

          
          {showDeletarDialog && <DeleteDialog 
            titulo={"Deseja deletar sua conta?"} 
            subtitulo={"Todos os seus plantios serão excluídos e você não conseguirá recuperar sua conta."}
            onClose={()=>setShowDeletarDialog(false)}
            onDelete={()=>{
              controllerConfiguracoes.sendEmail(()=>{
                setShowConfirmarDeletarModal(true);
                setShowDeletarDialog(false); 
              })
              
            } }

          />}
          
          { loading && <Loading/> }
          <section>
            <div className="top">
              <div className="title">
                <h2>Configurações</h2>
                <span>Por aqui você acessa suas configurações gerais do Geoplantio.</span>
              </div>
              
            </div>
          </section>
          <section className="painel">
            <section className="card">
              <div className="top">
                <img src={account_icon} alt="Icone de conta de usuário"/>
                <span>Conta</span>
              </div>

              <div className="list">
                <div className="item border">
                  <label>E-mail</label>
                  <div className="content-item">
                    <span>{usuario.email}</span>
                    <img src={edit_icon} alt="Icone de editar" onClick={()=>setShowAlterarEmailModal(true)}/>
                  </div>
                </div>
              </div>

              <div className="list">
                <div className="item border">
                  <label>Número de telefone</label>
                  <div className="content-item">
                    <span>{usuario.celular}</span>
                    <img src={edit_icon} alt="Icone de editar" onClick={()=>setShowAlterarContatoModal(true)}/>
                  </div>
                  
                </div>
              </div>

              <div className="list">
                <div className="item border">
                  <label>Estado</label>
                  <div className="content-item">
                    <span>{usuario.estado}</span>
                    <img src={edit_icon} alt="Icone de editar" onClick={()=>setShowAlterarEstadoModal(true)}/>
                  </div>
                  
                </div>
              </div>

              <div className="list">
                <div className="item border">
                  <label>Cidade</label>
                  <div className="content-item">
                    <span>{usuario.cidade}</span>
                    <img src={edit_icon} alt="Icone de editar" onClick={()=>setShowAlterarCidadeModal(true)}/>
                  </div>
                  
                </div>
              </div>

              <div className="list">
                <div className="item border">
                  <label>Nome e sobrenome</label>
                  <div className="content-item">
                    <span>{usuario.nome}</span>
                    <img src={edit_icon} alt="Icone de editar" onClick={()=>setShowAlterarNomeModal(true)}/>
                  </div>
                </div>
              </div>

              <div className="list">
                <div className="item border">
                  <label>Senha</label>
                  <div className="content-item">
                    <span>******</span>
                    <img src={edit_icon} alt="Icone de editar" onClick={()=>setShowAlterarSenhaModal(true)}/>
                  </div>
                </div>
              </div>

              <button className="delete-account" onClick={()=>setShowDeletarDialog(true)}>Deletar minha conta</button>
              <button className="logout" onClick={logout}>Sair da minha conta</button>

            
            </section>

            

            <section className="card">
              <div className="top">
                <img src={lock_icon} alt="Icone de cadeado"/>
                <span>Privacidade</span>
              </div>

              <div className="list">
                <div className="item border">
                  <label></label>
                  <div className="content-item">
                    <span><a href="https://geoplantio.com.br">Politica de privacidade</a></span>
                  </div>
                </div>
              </div>

              <div className="list">
                <div className="item">
                  <label></label>
                  <div className="content-item">
                    <span><a href="https://geoplantio.com.br">Termos de uso</a></span>
                  </div>
                </div>
              </div>
              <div className="sized-box">

              </div>
              <div className="top">
                <img src={notifications_icon} alt="Icone de notificação"/>
                <span>Notificações</span>
              </div>

              <div className="list">
                <div className="item">
                  <label>
                    <div className="content-item">
                      <span>Permitir notificação</span>
                      <div className={`check ${checked && 'checked'}`}>
                        <input value={checked} type="checkbox" name="notificacao" id="notificacao" required autoComplete="off" 
                          onClick={()=>setChecked(!checked)} className={errors.notificacao && 'input-error'}
                        />
                      </div>
                    </div>
                  </label>
                </div>
              </div>            
            </section>
          </section>

        </Content>
      </Main>
    </SideBar>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);