import styled from "styled-components";

export const Card = styled.div`
  width: 30%;
  max-width: 300px !important;
  background-color: #FCFEFC;
  box-shadow: 0px 2px 3px rgba(36, 36, 36, 0.1);
  margin: 24px 28px 24px 0;
  border-radius: 8px;
  

  .image {
    border-radius: 8px 8px 0px 0px;
    overflow: hidden;
    img{
      width: 100%;
      height: 140px;
      object-fit: cover;
    }
  }

  .description{
    margin: 12px 18px;

    h4{
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 150%;
    }
  }

  .bottom{
    display: flex;
    margin: 20px 18px;
    
    button{
      width: 100%;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px;
      color: #fff;
      border-radius: 5px;
      text-decoration: none;
      background-color: var(--primary-color);
      border: none;
      cursor: pointer;

      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 150%;
    }

  }

  @media screen and (max-width: 796px){
    width: 94%;
  }
`