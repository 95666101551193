import back_later_image from "../../../assets/back_later.png";
import play_store_image from "../../../assets/play-store.png";
import apple_store_image from "../../../assets/apple-store.png";
import { AppDownloadContainer } from "./styled";
export const AppDownload = ()=>{
    return(
        <AppDownloadContainer>
            <h1>Desculpe, esta página não está disponível em dispositivos móveis.</h1>
            <p>Para desfrutar de todos os recursos, baixe nosso aplicativo na Play Store ou na App Store.</p>
            <a href="https://play.google.com/store/apps/details?id=com.jonnpo.geoplantio"><img src={play_store_image} alt="Icone PlayStore"/> Baixar na Play Store</a>
            <a href="https://apps.apple.com/us/app/geoplantio/id6462326016"><img src={apple_store_image} alt="Icone AppleStore"/> Baixar na App Store</a>
            <img src={back_later_image} alt="Ilustração de arvore seca"/>
        </AppDownloadContainer>
    );
}

export default AppDownload;