import styled from "styled-components";

export const Content = styled.div`
  input{
    width: 294px;
  };

  @media screen and (max-width: 796px){
    input{
      width: 285px;
    };
  }

`
