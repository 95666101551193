import styled from "styled-components";

export const Main = styled.div`  
  .content{
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: #fff;

    .center-content{
      justify-content: center;
    }

    .left-section{
      @media screen and (max-width: 796px){
        width: 100%;
        padding: 25% 10%;
      }

      width: 50%;
      height: 100vh;
      display: flex;
      padding: 7% 8%;
      flex-direction: column;
      overflow-y: scroll;

      .top{

        h2{
          font-style: normal;
          font-weight: 700;
          font-size: 30px;
          line-height: 120%;
        }

        .description{
          max-width: 80%;
          margin-top: 15px;

          span{
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
          }
        }
      }

      .form{
        margin-top : 12px ;
        .terms {
          width: 85%;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          margin-top: 8px;
          display: flex;
          align-items: center;
          justify-content: center;

          label{
            margin-top: 0;
          }
          
          a {
            color: var(--black-color);
          }

          .check{
            position: relative;
            width: 15px;
            height: 15px;
            border-radius: 5px;
            border: 1px solid #CFCFCF;
            margin-right: 14px;
            

            input {
              margin-top: 0;
              width: 15px;
              display: none;
              cursor: pointer;
            }
            
          }

          .checked{
            background-color: var(--primary-color);
            border: none;

            :after{
              content: "";
              position: absolute;
              top: 2px;
              left: 5px;
              width: 3px;
              height: 6px;
              border: solid #fff;
              border-width: 0 2px 2px 0;
              -webkit-transform: rotare(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
        }

        .input-code{
          width: 44px;
          height: 52px;
          margin-right: 9px;
          text-align: center;
          background-color: #EDEDED;
          font-size: 15pt;
        }

        .resend-code{
          margin-top: 27px;
          font-size: 10px;
          max-width: 170px;
          height: 27px;
          font-weight: normal;
          background-color: #9A9A9A;

          :disabled{
            background-color: #FDFDFD;
            border: 1px solid #CFCFCF;
            color: #9A9A9A;
          }
        }

        .forgot-password{
          margin-top: 20px;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          span{
            margin-left: 8px;
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            cursor: pointer;
          }

          img{
            width: 16px ;
          }
        }

        .have-account{
          align-self: center;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 15px;
          margin-top: 15px;

          a {
            color: var(--primary-color);
            text-decoration: none;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 15px;
          }
        }
      }
    
    }

    .right-section{
      @media screen and (max-width: 796px){
        display: none;
      }
      width: 50%;
      height: 100vh;
      overflow: hidden;
      display: flex; 
      justify-content: center; 
      align-items: center; 

      img{
        width: 100%; 
      }

      
    }
  }
  
`