

function validarNome(e, apoiador, errors, setApoiador, setErrors) {
  setApoiador({...apoiador, nome : e.target.value})
  if(e.target.value < 1){
    setErrors({ ...errors, nome : "Digite um nome válido", geral: ""})
  }else{
    setErrors({ ...errors, nome : "", geral: ""});
  }
    
}


function validarEstado(e, apoiador, errors, setApoiador, setErrors) {
  setApoiador({...apoiador, estado : e.target.value})
  if(e.target.value < 1){
    setErrors({ ...errors, estado : "Digite um estado válido", geral: ""})
  }else{
    setErrors({ ...errors, estado : "", geral: ""});
  }
    
}

function validarDescricao(e, apoiador, errors, setApoiador, setErrors) {
  setApoiador({...apoiador, descricao : e.target.value})
  if(e.target.value < 1){
    setErrors({ ...errors, descricao : "Digite uma declaração válida", geral: ""})
  }else{
    setErrors({ ...errors, descricao : "", geral: ""});
  }
    
}

function validarLink(e, apoiador, errors, setApoiador, setErrors) {
  setApoiador({...apoiador, link : e.target.value})
  if(e.target.value < 1){
    setErrors({ ...errors, link : "Digite um link válido", geral: ""})
  }else{
    setErrors({ ...errors, link : "", geral: ""});
  }
    
}

function handleChangeMidia(e, setSelectedFile) {
  const { files }= e.target;
  setSelectedFile(files[0]);
}

const ValidacaoApoiadores = {
  validarNome,
  validarLink,
  validarEstado,
  handleChangeMidia,
  validarDescricao
}

export default ValidacaoApoiadores;