import { connect } from "react-redux";
import close_icon from "../../../../../assets/icons/close.svg";
import Formulario from "../../../../components/Formulario";
import { mapDispatchToProps, mapStateToProps } from "../../../../../utils/config";
import { useState } from "react";
import Loading from "../../../../components/Loading";
import AlertDialog from "../../../../components/AlertDialog";
import { Main, Container } from "../styled";
import InformacoesGrupoController from "../controller";

function AlterarSobreModal({grupo, setGrupo, errors, setErrors, onClose}){
  const [loading, setLoading] = useState(false);
  const [dadosGrupo, setDadosGrupo] = useState({...grupo});
  const [showSuccess, setShowSuccess] = useState(false);

  const informacoesGrupoController = new InformacoesGrupoController(dadosGrupo, setDadosGrupo, errors, setErrors, setLoading);

  return(
    <Main>
    <Container>
      {loading && <Loading/>}
      {showSuccess && (
        <AlertDialog
          titulo={`Sobre alterado com sucesso com sucesso!`}
          buttonText={"Ver meus dados"}
          buttonOnClick={onClose}
        />
      )}
      <div className="content-modal">
        <section>
          <div className="close" onClick={onClose}>
            <button type="button">
              <img src={close_icon} alt="Icone de fechar"/>
            </button>
          </div>
        </section>

        <section>
          <div className="title">
            <h2>Alterar Sobre do Grupo</h2>
          </div>
        </section> 

        <Formulario id="form-alterar-sobre">
          
          <div className="form-group">
            <label htmlFor="sobre">Sobre</label>
            <textarea id="sobre" name="sobre" placeholder="Insira o novo sobre do grupo" required autoComplete="off"
              value={dadosGrupo.sobre} onChange={(e)=>informacoesGrupoController.validarSobre(e)} className={errors.sobre ? 'multiple-line input-error' : 'multiple-line'}
            ></textarea>
            <span className="error">{errors.sobre}</span>
          </div>

          <div className="form-group">
          <button type="button" onClick={(e)=>informacoesGrupoController.alterarDados(e, 'form-alterar-sobre', setGrupo, setShowSuccess)}>Salvar</button> 
          </div>


              
        </Formulario>
      </div>
    </Container>
    </Main>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AlterarSobreModal);