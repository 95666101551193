import localizacao_icon from "../../../../assets/icons/localizacao.svg";
import especie_icon from "../../../../assets/icons/especie.svg";
import mudas_icon from "../../../../assets/icons/mudas.svg";
import data_icon from "../../../../assets/icons/data.svg";
import profile_icon from "../../../../assets/icons/profile.svg";
import arrow_rigth_icon from "../../../../assets/icons/arrow-right.svg";
import arrow_left_icon from "../../../../assets/icons/arrow-left.svg";
import { formatData } from "../../../../utils/conversoes";

function InformacoesPlantio({plantio, previousImage,  nextImage}){
  return(
    <>
      <section>
        <div className="title">
          <h2>Informações do plantio</h2>
        </div>
      </section>

      <section>
        <div className="gallery">

          <div className="images">
            {
              plantio?.midias?.map(midia=>{
                return(
                  <div className="image">
                    {
                      midia.tipo === 'Imagem' ?
                      <img key={midia.id} src={`${midia.path}`} alt={`Imagem ${midia.id} do plantio ${plantio.id}`}/> :
                      <video key={midia.id} src={`${midia.path}`} title={`Video ${midia.id} do plantio ${plantio.id}`}/>
                    }
                  </div>
                )
              })
            }
            
              
          </div>
          
          <div className="previus">
            <button type="button" onClick={previousImage}>
              <img src={arrow_left_icon} alt="Icone de anterior"/>
            </button>
          </div>
          <div className="next" onClick={nextImage}>
            <button type="button">
              <img src={arrow_rigth_icon} alt="Icone de próximo"/>
            </button>
          </div>

        </div>
      </section>

      <section className="info">
        <h2>{plantio.titulo}</h2>

        <div className="platio-info-scroll">
          <div className="item">
            <img src={localizacao_icon} alt="Icone de localização"/>
            <span>Localização: <span className="details">{plantio.endereco}</span></span>
          </div>

          <div className="item">
            <img src={especie_icon} alt="Icone de espécie"/>
            <span>Espécies: </span>
            
          </div>

          <div className="especies">
              {plantio.especies.map(especieItem=>(
                <span className="details">{especieItem.especie.nome_comum} - {especieItem.numero_mudas} mudas;</span>
              ))}
            </div>

         

          <div className="item">
            <img src={data_icon} alt="Icone de calendário"/>
            <span>Data do plantio: <span className="details">{formatData(plantio.data)}</span></span>
          </div>

          <div className="item">
            <img src={profile_icon} alt="Icone de tipo de plantação"/>
            <span>Tipo de plantação: <span className="details">{plantio.tipo}</span></span>
          </div>

        </div>
        
      </section>
    </>
  );
}

export default InformacoesPlantio;