import Grupo from "../../../../models/Grupo";
import CustomHandle from "../../../../utils/CustomHandle";

class ControllerVerConvite{
    constructor (setLoading, errors, setErrors){
        this.setLoading = setLoading;
        this.errors = errors;
        this.setErrors = setErrors;
    }

    async aceitar(solicitacao_id, setShowSuccess, setAceito){
      this.setLoading(true);
      const result = await Grupo.aceitarSolicitacao(solicitacao_id);
      const onSuccess = ()=>{
          setShowSuccess(true);
      };
    
      CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);
      this.setLoading(false);
      setAceito(true);

    }

    async recusar(solicitacao_id, setShowSuccess, setAceito){
      this.setLoading(true);
      const result = await Grupo.recusarSolicitacao(solicitacao_id);
      const onSuccess = ()=>{
          setShowSuccess(true);
          setAceito(false);
      };
    
      CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);
      this.setLoading(false);

    }

   
      
    
      
}

export default ControllerVerConvite;