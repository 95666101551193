import Formulario from "../../../components/Formulario";
import close_icon from "../../../../assets/icons/close.svg";
import { tiposGerenciamentoModal } from "../../../../utils/data";
import Select from "../../../components/Select";
import { Main } from "../styled";
import {useState} from "react";
import Bioma from "../../../../models/Bioma";
import { useEffect } from "react";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import ValidacaoBioma from "./validacao";
import Validacao from "../../validacao";
import DeleteDialog from "../../../components/DeleteDialog";

function BiomasModal({tipo, onClose, bioma, setBioma}){
  const [nomeBioma, setNomeBioma] = useState("");
  const [biomas, setBiomas] = useState({});
  const [errors, setErrors] = useState({});
  const [showOptions, setShowOptions] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [loading, setShowLoading] = useState(false);

  async function filtrarBiomas(e) {
    const searchValue = e.target.value;
    setNomeBioma(searchValue);

    let newBiomas = [];

    if (searchValue) {
      newBiomas = await Bioma.findSearch(searchValue);
    } else {
      newBiomas = await Bioma.findAll();
    }

    setBiomas(newBiomas.data);
  }

  function onClickSelectBioma(bioma) {
    setNomeBioma(bioma.nome);
    setBioma(bioma);
    setErrors((prevErrors) => ({ ...prevErrors, biomas: '' }));
  }

  
  function validar(e, handle) {
    handle(e, bioma, errors, setBioma, setErrors);
  }

  async function handleSubmit(e){
    e.preventDefault();
    
    const formulario = document.getElementById('form-bioma');
    const input_errors = Validacao.validarCampos(formulario, errors, setErrors);
    setErrors(input_errors);
    if (Validacao.emptyErrors(input_errors)) {
      setShowLoading(true);
      const result = await Bioma.create(bioma);
      if (result.response) {
        const data = result.response.data;

        if (data.field) {
          setErrors((prevErrors) => ({ ...prevErrors, [data.field]: data.error }));
          document.getElementById(data.field)?.focus();
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, geral: data.error }));
        }
      }else{
        setShowSuccess(true);
        setNomeBioma(bioma.nome);
      }
      setShowLoading(false);
    }
  }

  async function handleDelete(){

    setShowLoading(true);
    const result = await Bioma.delete(bioma.id);
    if (result.response) {
      const data = result.response.data;
      setErrors((prevErrors) => ({ ...prevErrors, delete: data.error }));
    }else{
      window.location.href = "/gerenciamento";
    }
    setShowLoading(false);
    
  }

  async function findAllBiomas() {
    const allBiomas = await Bioma.findAll();
    setBiomas(allBiomas.data);
  }

  function limparCampos(){
    setNomeBioma("");
    setBioma({});
  }

  function handleSuccessButton(){
    setShowSuccess(false);
    if(tipo === tiposGerenciamentoModal.CRIAR){
      limparCampos();
    }else{
      findAllBiomas();
    }
  }

  useEffect(()=>{
    setNomeBioma(bioma.nome);
    findAllBiomas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return(
    <>
      {tipo !== tiposGerenciamentoModal.NONE  &&
        <Main>
          {loading && <Loading titulo={"Aguarde um momento"} subtitulo={"Estamos validando suas informações"}/>}
          {showSuccess && 
            <AlertDialog 
              titulo={`Bioma ${bioma.id ? 'atualizado' : 'cadastrado'} com sucesso!`} 
              buttonText={`${bioma.id ? 'Voltar' : 'Cadastrar novo bioma'}`} 
              buttonOnClick={handleSuccessButton}
            />
          }
          { showDelete && 
              <DeleteDialog
                error={errors.delete}
                titulo="Deseja realmente excluir esse bioma?" 
                subtitulo="Cerfique-se de que este bioma não está associado a nenhuma espécie."
                onDelete={handleDelete} 
                onClose={()=>setShowDelete(false)}
              />
            }
          <div className="content-modal">
            <div className="top">
              <h3>Biomas</h3>
              <button type="button" onClick={()=>{limparCampos(); onClose()}}>
                <img src={close_icon} alt="Icone de fechar"/>
              </button>
            </div>
            
            <h4>
              { tipo === tiposGerenciamentoModal.EDITAR ? 
                "Editar ou excluir um bioma" : 
                "Adicionar um novo bioma"
              }
            </h4>
            <Formulario id="form-bioma">
              { tipo === tiposGerenciamentoModal.EDITAR &&
                <div className="form-group">
                  <label htmlFor="biomas">Lista de biomas</label>
                  <Select 
                    id="biomas" 
                    name="biomas" 
                    placeholder="Pesquise por biomas aqui" 
                    required={false}
                    autoComplete="off"
                    value={nomeBioma} 
                    onChange={filtrarBiomas} 
                    className={`input-prefix ${errors.biomas && 'input-error'}`}
                    options={biomas.rows}
                    onClickOption={onClickSelectBioma}
                    showOptions={showOptions}
                    setShowOptions={setShowOptions}
                  />
                  <span className="error">{errors.biomas}</span>
                </div>
              }

              <div className="form-group">
                <label htmlFor="nome">{tipo === tiposGerenciamentoModal.EDITAR ? "Alterar nome do Bioma" : "Nome do bioma"}</label>
                <input 
                  required
                  value={bioma.nome || ""} 
                  onChange={(e)=>validar(e, ValidacaoBioma.validarNome)}   
                  type="text" 
                  name="nome" 
                  id="nome"
                  placeholder={tipo === tiposGerenciamentoModal.EDITAR ? "Escolha um bioma" : "Digite o nome do novo bioma"}
                />
                <span className="error">{errors.nome}</span>
              </div>
          
              <div className="form-group">
                
                <button disabled={tipo === tiposGerenciamentoModal.EDITAR && !bioma.id} className="save-button" 
                  type="button" onClick={handleSubmit}
                >
                  { tipo === tiposGerenciamentoModal.EDITAR ? "Salvar edição" : "Registrar novo bioma" }
                </button>
                <span className="error">{errors.geral}</span>
              </div>

             {tipo === tiposGerenciamentoModal.EDITAR &&
                <div className="form-group">
                  <button disabled={!bioma.id} className="remove-button" onClick={()=>setShowDelete(true)} type="button">Excluir bioma</button>
                </div>
              }
            </Formulario>             
          </div>
        </Main>
      }
    </>
  );
};

export default BiomasModal;