function validarNome(e, bioma, errors, setBioma, setErrors) {
  setBioma({...bioma, nome : e.target.value})
  if(e.target.value < 1){
    setErrors({ ...errors, nome : "Digite um nome válido", geral: ""})
  }else{
    setErrors({ ...errors, nome : "", geral: ""});
  }
    
}

const ValidacaoBioma = {
  validarNome
}

export default ValidacaoBioma;