import { Main, Container } from "./styled";
import close_icone from "../../../../assets/icons/close.svg";
import localizacao_icone from "../../../../assets/icons/localizacao.svg";
import perfil_image from "../../../../assets/perfil.png";
import { useState } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../../utils/config";
import Loading from "../../../components/Loading";
import { useEffect } from "react";
import ControllerSolicitacoesPendentes from "./controller";
import CircularImage from "../../../components/CircularImage";
import SemResultados from "../../../components/SemResultados";
import VerConviteModal from "../VerConviteModal";

function SolicitacoesPendentesModal({grupo, setGrupo, onClose, errors, setErrors}){
  const [solicitacoes, setSolicitacoes] = useState();
  const [solicitacaoSelecionada, setSolicitacaoSelecionada] = useState();
  const [loading, setLoading] = useState(true);
  const closeModal = onClose;

  const controllerSolicitacoesPendentes = new ControllerSolicitacoesPendentes(setLoading, errors, setErrors);

  useEffect(()=>{
    if(!solicitacaoSelecionada){
      controllerSolicitacoesPendentes.findAllSolicitacoes(grupo.id, setSolicitacoes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [solicitacaoSelecionada])

  
  return(
    <Main>
      {loading && <Loading titulo={"Aguarde"} subtitulo={"Estamos deixando tudo pronto para você..."}/>}
      {solicitacaoSelecionada && <VerConviteModal solicitacao={solicitacaoSelecionada} grupo={grupo} onClose={()=>setSolicitacaoSelecionada(null)}/>}
      <Container>
        <section>
          <div className="close" onClick={closeModal}>
            <button type="button">
              <img src={close_icone} alt="Icone de fechar"/>
            </button>
          </div>
        </section>

        <section>
          <div className="title">
            <h2>Solicitações Pendentes</h2>
          </div>
        </section>  

        <section className="painel">
            <section className="card">
              {solicitacoes?.length > 0 ? solicitacoes?.map(solicitacao=>
                <div className="solicitacao">
                  <CircularImage src={solicitacao?.usuario?.foto_perfil?.path || perfil_image} alt="Foto do usuario" />
                  <div>
                    <span>{solicitacao.usuario.nome}</span>
                    <div className="location">
                      <img src={localizacao_icone} alt="icone de localizacao"/>
                      <span>{solicitacao.usuario.estado}</span>
                    </div>
                    
                  </div>
                  <button onClick={()=>setSolicitacaoSelecionada(solicitacao)}>Ver convite</button>
                  <div className="desde">
                    <span>{controllerSolicitacoesPendentes.formatarData(solicitacao.ingresso_em)}</span>
                    <span>{controllerSolicitacoesPendentes.formatarHora(solicitacao.ingresso_em)}</span>
                  </div>
                </div>  
              
              ): <SemResultados texto={`Nenhuma solicitação pendete em ${grupo.nome}`}/>
              
              }
              
            </section>     
        </section>
      </Container>
    </Main>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SolicitacoesPendentesModal);