import Usuario from "../../../../models/Usuario";
import CustomHandle from "../../../../utils/CustomHandle";

class ControllerConfiguracoes{

  constructor(usuario, errors, setErrors, setLoading, setUsuario){
    this.usuario = usuario;
    this.errors = errors;
    this.setErrors = setErrors;
    this.setLoading = setLoading;
    this.setUsuario = setUsuario;
  }

  async close(onClose, emailAntigo){
    this.setLoading(true);
    
    let new_usuario = {...this.usuario};
    delete new_usuario.foto_perfil;
    delete new_usuario.foto_capa;
    
    if(!new_usuario.sobre){
      new_usuario.sobre = '';
    }

    const result = await Usuario.create(new_usuario);
    const onSuccess = ()=>{
      onClose();
      this.setUsuario({...this.usuario, email : emailAntigo});
    }

    CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess)
    this.setLoading(false);

  }
  async sendEmail(setShowModal){
    this.setLoading(true);
    const result = await Usuario.sendEmail(this.usuario.id);
    const onSuccess = ()=>{
      setShowModal(true);
    }

    await CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);
    this.setLoading(false);
  }

  async confirmarAlterarEmail(e, inputRefs, setEmailAntigo, setShowSuccess){
    e.preventDefault();
    const codigo = inputRefs.map((ref) => ref.current.value).join("");
    const submitAction = async ()=> await Usuario.confirmEmail(this.usuario.id, codigo);
    const onSuccess = async  ()=>{
      setEmailAntigo(this.usuario.email);
      setShowSuccess(true);
    }

    await CustomHandle.submitForm('form-alterar-email', submitAction, this.errors, this.setErrors, this.setLoading, onSuccess);


  }

  async alterarEmail(e, setShowCodigoForm){
    e.preventDefault();
    let new_usuario = {...this.usuario};
    delete new_usuario.foto_perfil;
    delete new_usuario.foto_capa;
    
    if(!new_usuario.sobre){
      new_usuario.sobre = '';
    }

    const submitAction = async ()=> await Usuario.create(new_usuario);
    const onSuccess = async  ()=>{
      await this.sendEmail(setShowCodigoForm);
    }

    await CustomHandle.submitForm('form-alterar-email', submitAction, this.errors, this.setErrors, this.setLoading, onSuccess);


  }

  async alterarDados(e, form, dadosUsuario, setShowSuccess){
    e.preventDefault();
    let new_usuario = {...dadosUsuario};
    delete new_usuario.foto_perfil;
    delete new_usuario.foto_capa;
    
    if(!new_usuario.sobre){
      new_usuario.sobre = '';
    }

    const submitAction = async ()=> await Usuario.create(new_usuario);
    const onSuccess = async  ()=>{
      setShowSuccess(true);
      this.setUsuario(dadosUsuario);
    }

    await CustomHandle.submitForm(form, submitAction, this.errors, this.setErrors, this.setLoading, onSuccess);


  }

  validarSenha(e, usuarioDados, setUsuarioDados){ 
    setUsuarioDados({...usuarioDados, senha_atual : e.target.value});  
    if(e.target.value.length < 6 ){
      this.setErrors({ ...this.errors, senha_atual : "A senha deve conter pelo menos 6 caracteres", geral : ""});
    }else{
      this.setErrors({ ...this.errors, senha_atual : "", geral : ""});
    }
  }
  
  validarNovaSenha(e, dadosUsuario, setDadosUsuario){
      setDadosUsuario({...dadosUsuario, nova_senha : e.target.value.toString()});
  
      if(e.target.value.length < 6){
        this.setErrors({ ...this.errors, nova_senha : "A senha deve conter pelo menos 6 caracteres", geral : ""});
      }else{
        this.setErrors({ ...this.errors, nova_senha : "", geral : ""});
      }
    
      if(dadosUsuario.confirmar_senha){
        this.validarConfirmarSenha(
          {target : {value : dadosUsuario.confirmar_senha}}, 
          {...dadosUsuario, nova_senha : e.target.value}, 
          setDadosUsuario
        );
      }
  }
    
  validarConfirmarSenha(e, dadosUsuario, setDadosUsuario){ 
    setDadosUsuario({...dadosUsuario, confirmar_senha : e.target.value});  

    if(e.target.value !== dadosUsuario.nova_senha){
      this.setErrors({ ...this.errors, confirmar_senha : "As senhas digitadas não são iguais", geral : ""});
    }else{
      this.setErrors({ ...this.errors, confirmar_senha : "", geral : ""});
    }
  }

  async alterarSenha(e, usuario_id, dadosUsuario, setShowSuccess){
    e.preventDefault();
    const submitAction = async () => await Usuario.alterarSenha({...dadosUsuario, usuario_id : usuario_id});
    const onSuccess = async  ()=>{
      setShowSuccess(true);
    }

    await CustomHandle.submitForm('form-alterar-senha', submitAction, this.errors, this.setErrors, this.setLoading, onSuccess);
    
  }

  async excluirUsuario(navigate){
    const result = await Usuario.delete(this.usuario.id);
    const onSuccess = async  ()=>{
      this.setUsuario({...Usuario.model});
      sessionStorage.removeItem('usuario');
      navigate('/login');

    }

    await CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);

  }

  async confirmarDeletarConta(e, inputRefs, navigate){
    e.preventDefault();
    const codigo = inputRefs.map((ref) => ref.current.value).join("");
    const submitAction = async ()=> await Usuario.confirmEmail(this.usuario.id, codigo);
    const onSuccess = async  ()=>{
      this.excluirUsuario(navigate);
    }

    await CustomHandle.submitForm('form-deletar-conta', submitAction, this.errors, this.setErrors, this.setLoading, onSuccess);


  }

}  

export default ControllerConfiguracoes;