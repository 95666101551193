import styled from "styled-components";

export const Content = styled.div`
  .description{
    .info{
      display: flex;
      justify-content: space-between;
      h4{
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 120%;
      }

      button{
        border: none;
        background-color: transparent; 
        cursor: pointer;
        img{
          width: 15px;
        }       
      }

      .info-localizacao{
        display: flex;
        align-items: center;
        margin-top: 4px;
        img{
          width: 9px;
          margin-right: 6px;
        }

        span{
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 120%;
          color: #8F8F8F;
        }
      }
    }

    .about{
      margin-top: 12px;
      span{
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
      }
    }
  }
  
`;
