import { connect } from "react-redux";
import close_icon from "../../../../assets/icons/close.svg";
import Formulario from "../../../components/Formulario";
import { mapDispatchToProps, mapStateToProps } from "../../../../utils/config";
import { useState } from "react";
import ControllerCadastro from "../../auth/Cadastro/controller";
import ControllerConfiguracoes from "../Home/controller";
import Loading from "../../../components/Loading";
import AlertDialog from "../../../components/AlertDialog";

function AlterarNomeModal({usuario, setUsuario, errors, setErrors, onClose}){
  const [loading, setLoading] = useState(false);
  const [dadosUsuario, setDadosUsuario] = useState({...usuario});
  const [showSuccess, setShowSuccess] = useState(false);

  const controllerCadastro = new ControllerCadastro(dadosUsuario, errors, setDadosUsuario, setErrors, null);
  const controllerConfiguracoes = new ControllerConfiguracoes(usuario, errors, setErrors, setLoading, setUsuario);

  return(
    <div className="modal">
      {loading && <Loading/>}
      {showSuccess && (
        <AlertDialog
          titulo={`Contato alterado com sucesso com sucesso!`}
          buttonText={"Ver meus dados"}
          buttonOnClick={onClose}
        />
      )}
      <div className="content-modal">
        <div className="top">
          <h3>Alterar Nome e Sobrenome</h3>
          <button type="button" onClick={onClose}>
            <img src={close_icon} alt="Icone de fechar"/>
          </button>
        </div>

        <Formulario id="form-alterar-nome">
          
          <div className="form-group">
            <label htmlFor="nome">Nome e sobrenome</label>
            <input type="text" id="nome" name="nome" placeholder="Insira seu novo nome e sobrenome" required autoComplete="off"
              value={dadosUsuario.nome} onChange={(e)=>controllerCadastro.validarNome(e)} className={errors.nome && 'input-error'}
            />
            <span className="error">{errors.nome}</span>
          </div>

          <div className="form-group">
          <button type="button" onClick={(e)=>controllerConfiguracoes.alterarDados(e, 'form-alterar-nome', dadosUsuario, setShowSuccess)}>Salvar</button> 
          </div>


              
        </Formulario>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AlterarNomeModal);