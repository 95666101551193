import { addDays, endOfMonth, formatDate, parseISO } from "date-fns";
import { useState } from "react";
import Plantio from "../../../../models/Plantio";
import CustomHandle from "../../../../utils/CustomHandle";
import { useEffect } from "react";
import { FilterDaysComponent } from "../../../components/filterDays";
import ReactApexChart from "react-apexcharts";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../../utils/config";
import { estados } from "../../../../utils/data";

function getCurrentMonth() {
  return formatDate(new Date(), 'yyyy-MM')
};

const GraphBarraPlantios = ({ usuario, errors, setErrors, setLoading, setSelectedEstado, selectedEstado }) => {

  const [filterPeriod, setFilterPeriod] = useState(7);
  const [selectedDate, setSelectedDate] = useState(getCurrentMonth());
  const [toDate, setToDate] = useState(new Date());
  const fromDate = addDays(toDate, (filterPeriod * -1));
  const [graphPlantios, setGraphPlantios] = useState();

  const formattedToDate = formatDate(toDate, 'yyyy-MM-dd');
  const formattedFromDate = formatDate(fromDate, 'yyyy-MM-dd');
  const categories = graphPlantios?.items.map(item => item.label).reverse();
  const chartData = graphPlantios?.items.map(item => item.value).reverse();

  const findGraphPlantios = async () => {
    setLoading(true);
    const result = await Plantio.findGraphByState(formattedFromDate, formattedToDate, selectedEstado);
    const onSuccess = (response) => {
      setGraphPlantios(response);
    };

    CustomHandle.handleResult(result, errors, setErrors, onSuccess);

    setLoading(false);
  }

  useEffect(() => {
    findGraphPlantios();
  }, [filterPeriod, toDate, selectedEstado]);

  useEffect(() => {
    
    const currentMonth = getCurrentMonth();
    if (selectedDate !== currentMonth) {
      const selectedEndDate = endOfMonth(parseISO(`${selectedDate}-01`));
      setToDate(selectedEndDate);
      setFilterPeriod(30);
    } else {
      setToDate(new Date());
    }
  }, [selectedDate]);

  const chartOptions = {
    chart: {
      type: 'bar',
      // height: 200,
      zoom: {
        enabled: false,
      },
      events: {},
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '10px'
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      min: 0,
      max: graphPlantios?.count || 0,
      tickAmount: 4
    },
    grid: {
      borderColor: "#2AA444",
      strokeDashArray: 5,

      row: {
        height: '10px',
        colors: ['transparent'],
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        const serie = series[seriesIndex];
        const value = serie[dataPointIndex];
        return value;
      },
      cssClass: 'teste'
    },

  };

  return (
    <section className="grafic-section">

      <div className="action-bar">
        <FilterDaysComponent
          selected={filterPeriod}
          setSelected={setFilterPeriod}
        />
        <input className='select-month' type='month' value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)} />

        <select value={selectedEstado} onChange={(e) => setSelectedEstado(e.target.value)}>
          <option value={'todos'}>Todos os estados</option>
          {estados.map((estado, index) => (
            <option key={index} value={estado}>{estado}</option>
          ))}
        </select>
      </div>

      <div className='graphic-title'>
        <span className='b3-bold'>Numero de registro de plantios</span>
        <span className='b3-bold'>Total: <span className='b3-regular'>{graphPlantios?.count || 0} registros</span></span>
      </div>
      {graphPlantios?.count > 0 ?
        <ReactApexChart
          series={[{
            name: "Registros",
            color: "#2AA444",
            data: chartData,
          }]}
          options={chartOptions}
          type='bar'
          height={graphPlantios?.count > 1 ? graphPlantios?.count * 20 : 100}
        /> :
        <span className="empty">Nenhum dado para ser exibido</span>
      }
    </section>
  );

};

export default connect(mapStateToProps, mapDispatchToProps)(GraphBarraPlantios);
