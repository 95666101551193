import styled from "styled-components";

export const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  
`

export const Container = styled.div`
  width: 400px;
  background-color: #fff;
  border-radius: 10px;
  padding: 34px;
  overflow-y: scroll ;
  

  .title{
    h2{
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 120%;
    }
  }

  .close{
    display: flex;
    width: 100%;
    justify-content: flex-end;

    button{
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: none;
      width: 19px;
      height: 19px;
      cursor: pointer;

    }
  }

  .list{
      .content-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .item{
        display: flex;
        flex-direction: column;
        padding-bottom: 6px;
          

        label {
          margin-top: 12px;
        }

        label, span{
          color: #8A8A8A ;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
        }

        span{
          color: #4D4F50;
          margin-top: 6px;

          a{
            color: #0B8CCD;
            text-decoration: none;
          } 
        }

        img{
          cursor: pointer;
        }
      }

      .check{
        cursor: pointer;
        position: relative;
        width: 15px;
        height: 15px;
        border-radius: 5px;
        border: 1px solid #CFCFCF;
        margin-right: 14px;
        

        input {
          margin-top: 0;
          width: 15px;
          display: none;
          cursor: pointer;
        }
        
      }

      .checked{
        background-color: var(--primary-color);
        border: none;

        :after{
          content: "";
          position: absolute;
          top: 2px;
          left: 5px;
          width: 3px;
          height: 6px;
          border: solid #fff;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotare(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }

      .border{
        border-bottom: 1px solid #CFCFCF;
      }
    }

  button{
    width: 100%;
    border-radius: 6px;
    height: 36px;
    margin-top: 12px;
    cursor: pointer;
  }

  .delete-account{
    border: 0.5px solid #D41A1A;
    background: #D41A1A;
    color: #fff;
    margin-top: 30px;
  }



    .add-adm-item, .add-adm-item-list{
    display: flex;
    align-items: center;
    cursor: pointer;
    .add-button{
      margin-top: 10px;
      margin-left: 10px;
    }

    input{
      width: 280px !important;
    }

    .prefix{
      margin-top: -32px !important;
    }

  }

  .add-adm-item-list{
    .profile{
      margin-top: -40px !important;
      width: 32px;
      height: 32px;
      border: 1px solid var(--primary-color);
      
    }
  }  

  
`