import { bindActionCreators } from "redux";
import * as ErrorsActions from "../store/actions/errors";
import * as UsuarioActions from "../store/actions/usuario";

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    Object.assign(
      {},
      ErrorsActions,
      UsuarioActions
    ),
    dispatch
  );

export const mapStateToProps = state => ({ 
  errors : state.errors,
  usuario : state.usuario,
  });