

function validarNome(e, patrocinador, errors, setPatrocinador, setErrors) {
  setPatrocinador({...patrocinador, nome : e.target.value})
  if(e.target.value < 1){
    setErrors({ ...errors, nome : "Digite um nome válido", geral: ""})
  }else{
    setErrors({ ...errors, nome : "", geral: ""});
  }
    
}

function validarLink(e, patrocinador, errors, setPatrocinador, setErrors) {
  setPatrocinador({...patrocinador, link : e.target.value})
  if(e.target.value < 1){
    setErrors({ ...errors, link : "Digite um link válido", geral: ""})
  }else{
    setErrors({ ...errors, link : "", geral: ""});
  }
    
}

function handleChangeMidia(e, setSelectedFile) {
  const { files }= e.target;
  setSelectedFile(files[0]);
}

const ValidacaoPatrocinadores = {
  validarNome,
  validarLink,
  handleChangeMidia
}

export default ValidacaoPatrocinadores;