import styled from "styled-components";

export const Content = styled.div`
    .more{
        color: var(--primary-color);
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        cursor: pointer;
        &:hover{
        font-weight: bold;
        
        }

    }

`;

export const PerfilItem = styled.div`
    width: 100%;
    margin: 20px 0;
    display: flex;
    align-items: center;

    button{
        
        font-size: 10px;
        width: 40px;
        height: 25px;
        margin: 0 !important;
        margin-left: auto !important;
    }

    .profile{
        width: 35px;
        height: 35px;
        border: 1px solid var(--primary-color);
    
    }

    .user-info{
        margin-left: 12px;
        img{
            width: 10px;
            margin-right: 4px;
        }
    }

    .location-info{
        display: flex;
        align-items: center;
    }
`;