import SideBar from "../../../components/SideBar";
import { Content } from "./styled";
import { Main } from "../../styled";
import InputSearch from "../../../components/InputSearch";
import arrow_back_icon from "../../../../assets/icons/arrow-back.svg";
import PostagemInput from "../../../components/PostagemInput";
import CardPostagem from "../../../components/Cards/CardPostagem";
import CardApoiador from "../../../components/Cards/CardApoiador";
import arvore_image from "../../../../assets/arvore.png";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../../utils/config";
import ControllerForum from "./controller";
import { useState } from "react";
import { useEffect } from "react";
import Postagem from "../../../../models/Postagem";
import Loading from "../../../components/Loading";
import { tiposPublicacao } from "../../../../utils/data";
import Comentar from "../Comentar";
import TagsList from "../../../components/TagsList";

function Home({ usuario, errors, setErrors }) {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [midia, setMidia] = useState();
  const [postagens, setPostagens] = useState();
  const [postagem, setPostagem] = useState({ ...Postagem.model });
  const [loading, setLoading] = useState(false);
  const [loadingSubtitulo, setLoadingSubtitulo] = useState("Estamos preparando tudo pra você");
  const [postagemSelected, setPostagemSelected] = useState();
  const [typingTimeout, setTypingTimeout] = useState();
  const [tag, setTag] = useState();
  const [tags, setTags] = useState();
  const [tagsMaisComentadas, setTagsMaisComentadas] = useState();
  const [patrocinadores, setPatrocinadores] = useState([]);

  const controllerForum = new ControllerForum(page, errors, postagem, usuario, midia, postagens, search, setSearch, setPage, setMidia, setPostagem, setErrors, setPostagens, setLoading, setLoadingSubtitulo, setPatrocinadores);

  useEffect(() => {
    controllerForum.findPostagens(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, page]);

  useEffect(() => {
    controllerForum.findTagsMaisComentadas(setTagsMaisComentadas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    controllerForum.findTags(tag, setTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag]);

  useEffect(()=>{
    controllerForum.findPatrocinadores();
  }, []);

  return (
    <SideBar>
      <Main>
        {loading && <Loading titulo={"Aguarde"} subtitulo={loadingSubtitulo} />}


        <Content className="content" id="content">


          <section>
            <div className="top">
              <div>
                {postagemSelected &&
                  <button className="back" id="button-back" onClick={() => controllerForum.voltar(setPostagemSelected)}>
                    <img src={arrow_back_icon} alt="Icone de voltar" />
                    <span> Voltar </span>
                  </button>
                }

                <div className="title">
                  <h2>Fórum</h2>
                  <span>Fórum de discussão para tirar dúvidas e compartilhar dicas de plantio. Seja bem vindo!</span>
                </div>
              </div>

              <InputSearch text="Pesquisar no fórum" onChangeCapture={(e) => controllerForum.handleSearch(e, typingTimeout, setTypingTimeout)} />
            </div>
          </section>

          <div className="panel">
            {!postagemSelected ?
              <section className="left-section">
                <PostagemInput
                  errors={errors}
                  postagem={postagem}
                  midia={midia}
                  onChange={(e) => controllerForum.validarTexto(e, setTag)}
                  onSubmit={(e) => controllerForum.handleSubmit(e)}
                  onSelectMidia={(e) => controllerForum.handleSelectMidia(e)}
                />
                {(tag && tag !== "#" && tags?.rows?.length > 0) &&
                  <TagsList tags={tags} onSelect={(nome) => controllerForum.completaTag(nome, setTag)} />
                }
                <section className="postagens-section">
                  <h3>Postagens</h3>
                  {
                    postagens?.rows?.length > 0 ?
                      <>
                        {postagens?.rows?.map((postagem) => (
                          <CardPostagem
                            onDelete={(setShowDeletarDialog) => controllerForum.deletePostagem(postagem.id, setShowDeletarDialog)}
                            postagem={postagem}
                            tipo={tiposPublicacao.POSTAGEM}
                            handleLike={() => controllerForum.handleLike(postagem)}
                            handleComentar={() => controllerForum.selecionaPostagem(setPostagemSelected, postagem)}
                          />
                        ))}
                        {postagens.rows.length !== postagens.count &&
                          <span className="more" onClick={() => setPage(page + 1)}> Ver mais </span>
                        }
                      </> :
                      <div className="painel-empty">
                        <img src={arvore_image} alt="Icone de uma árvore" />
                        <span>Ainda não há nada aqui. Seja o primeiro a fazer uma postagem...</span>
                      </div>
                  }
                </section>
              </section> :
              <Comentar postagemSelected={postagemSelected} setPostagemSelected={setPostagemSelected} handleLike={() => controllerForum.handleLike(postagemSelected)} />
            }


            <section className="right-section">
              <section className="matters-section">
                <h3>Assuntos mais comentados</h3>
                {
                  tagsMaisComentadas?.rows?.map((tag, index) => (
                    <div className="item">
                      <span className="primary">{index + 1}.</span> <span>#{tag.nome}</span>
                    </div>
                  ))
                }
              </section>


              <section className="supporters-section">
                <hr />
                <h3>Conheça nossos patrocinadores</h3>
                <CardApoiador />

                {patrocinadores.map(patrocinador => (
                  <CardApoiador patrocinador={patrocinador} />
                ))}


              </section>

            </section>
          </div>
        </Content>
      </Main>
    </SideBar>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);