import { connect } from "react-redux";
import close_icon from "../../../../assets/icons/close.svg";
import Formulario from "../../../components/Formulario";
import { mapDispatchToProps, mapStateToProps } from "../../../../utils/config";
import { useState } from "react";
import ControllerCadastro from "../../auth/Cadastro/controller";
import { estados } from "../../../../utils/data";
import ControllerConfiguracoes from "../Home/controller";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";

function AlterarEstadoModal({usuario, setUsuario, errors, setErrors, onClose}){

  const [loading, setLoading] = useState(false);
  const [dadosUsuario, setDadosUsuario] = useState({...usuario});
  const [showSuccess, setShowSuccess] = useState(false);

  const controllerConfiguracoes = new ControllerConfiguracoes(usuario, errors, setErrors, setLoading, setUsuario);
  const controllerCadastro = new ControllerCadastro(dadosUsuario, errors, setDadosUsuario, setErrors, null);

  return(
    <div className="modal">
      {loading && <Loading/>}
      {showSuccess && (
        <AlertDialog
          titulo={`Estado alterado com sucesso com sucesso!`}
          buttonText={"Ver meus dados"}
          buttonOnClick={onClose}
        />
      )}
      <div className="content-modal">
        <div className="top">
          <h3>Alterar Estado</h3>
          <button type="button" onClick={onClose}>
            <img src={close_icon} alt="Icone de fechar"/>
          </button>
        </div>

        <Formulario id="form-alterar-estado">
          
          <div className="form-group">
            <label htmlFor="estado">Estado</label>
            <input type="text" list="estados" id="estado" name="estado" placeholder="Escolha seu novo estado" required autoComplete="off"
              value={dadosUsuario.estado} onChange={(e)=>controllerCadastro.validarEstado(e)} className={errors.estado && 'input-error'}
            />
            <datalist id="estados">
              { estados.map((estado, index)=>{
                  return <option  key={index} value={estado}/>
              })}
            </datalist>
            <span className="error">{errors.estado}</span>
          </div>

          <div className="form-group">
          <button type="button" onClick={(e)=>controllerConfiguracoes.alterarDados(e, 'form-alterar-estado', dadosUsuario, setShowSuccess)}>Salvar</button> 
          </div>


              
        </Formulario>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AlterarEstadoModal);