import Formulario from "../../../Formulario";
import { Card } from "../../styled";
import serach_icon from "../../../../../assets/icons/seach.svg";
import { Content } from "../styled";
import Select from "../../../Select";
import { useEffect, useState } from "react";
import Usuario from "../../../../../models/Usuario";

function CardUsuarios({onShowUsuarios, onEdit}){
  const [nomeUsuario, setNomeUsuario] = useState("");
  const [usuario, setUsuario] = useState({});
  const [usuarios, setUsuarios] = useState({});
  const [errors, setErrors] = useState({});
  const [showOptions, setShowOptions] = useState(false);

  async function filtrarUsuarios(e) {
    const searchValue = e.target.value;
    setNomeUsuario(searchValue);
    let newUsuarios = [];
    if (searchValue) {
      newUsuarios = await Usuario.findSearch(searchValue);
    }
    setUsuarios(newUsuarios.data);
  }

  function onClickSelectUsuario(usuario) {
    setNomeUsuario(usuario.nome);
    setUsuario(usuario);
    setErrors((prevErrors) => ({ ...prevErrors, usuario: '' }));
  }

  return (
    <Card className="card-gerenciamento">
      <Content>
        <h3>Usuários</h3>
        <h4>Procure usuários por nome e veja seus perfis.</h4>
        <Formulario>
          <div className="form-group">
            <label>Nome do usuário</label>
            <Select
              id="usuarios" 
              name="usuarios" 
              placeholder="Pesquise por usuários aqui" 
              required 
              autoComplete="off"
              value={nomeUsuario} 
              onChange={filtrarUsuarios} 
              className={`input-prefix ${errors.usuarios && 'input-error'}`}
              options={usuarios?.rows}
              onClickOption={onClickSelectUsuario}
              showOptions={showOptions}
              setShowOptions={setShowOptions}
              perfil={true}
            />
            <span className="error">{errors.name}</span>
          </div>

          <div className="form-group">
            <button className="edit-button" onClick={()=>onEdit(usuario)} type="button">Editar usuário</button>
          </div>

          <div className="form-group">
            <button className="add-button" onClick={onShowUsuarios} type="button">Ver lista completa de usuários</button>
          </div>

        </Formulario>
      </Content>
    </Card>    
  );
}

export default CardUsuarios;

