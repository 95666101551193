import styled from "styled-components";

export const Main = styled.div`
  display: flex;
  justify-content: center;
  overflow-y: scroll;

  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
  align-items: center;
  z-index: 1000;

  .especies{
    display: flex;
    flex-direction: column;
    margin-left: 24px;
  }
`

export const Container = styled.div`
  width: 360px;
  background-color: #fff;
  border-radius: 10px;
  padding: 34px;

  .platio-info-scroll{
    overflow-y: scroll;
    max-height: 160px;
  }

  .title{
    h2{
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 120%;
    }
  }

  .close{
    display: flex;
    width: 100%;
    justify-content: flex-end;

    button{
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: none;
      width: 19px;
      height: 19px;
      cursor: pointer;

    }
  }

  .gallery{
    position: relative;
    width: 291px;
    
    .next, .previus{  
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      
      
      button{
        display: flex;
        border: none;
        border-radius: 9px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 34px;
        width: 34px;
      }
    }

    .next{
      right: 10px;
    }

    .previus{
      left: 10px;
    }

    .images{
      display: flex;
      transition: transform 0.5s ease;
      overflow: hidden;

      .add-image{
        cursor: pointer;
      }

      .image{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 295px;
        height: 210px;
        background-color: #efefef;
        border-radius: 10px;
        
        img{
          width: 291px;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }
    }
  }

  .info{
    margin-top: 6px;

    h2{
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 120%;
      margin: 18px 0;
    }

    .item{
      display: flex;
      margin-top: 8px;

      span{
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;

        .details{
          font-weight: 500;
        }
      }
      img{
        width: 15px;
        margin-right: 8px;
      }
    }
    
  }

  .bottom{
    display: flex;
    margin-top: 25px;
    
    button{
      width: 100%;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px;
      color: #fff;
      border-radius: 5px;
      text-decoration: none;
      background-color: var(--primary-color);
      border: none;
      cursor: pointer;

      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 150%;
    }

  }
  
`