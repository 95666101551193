import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  
  .sidebar{
    width: 210px;
    bottom: 0;
    background-color: #fff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    
  }

  .panel{
    width: 100%;
    margin-left: 15px;
  }

  .top-logo{

    display: flex;
    justify-content: center;
    padding: 60px 0 22px 0;
    img{
      width: 75px;
    }
  }


  .nav-item{
    display: flex;
    align-items: center;
    height: 40px;
    width: 170px;
    margin: 6px 12px;
    padding: 9px 18px;
    border-radius: 12px;
    text-decoration: none;
    color: var(--black-color);

    cursor: pointer;

    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;

    img{
      margin: 0 6px;
      width: 18px;
    }
  }

  .active, .nav-item:hover {
    background-color: var(--primary-color);
    color: #fff;

    font-weight: normal;
    
    img{
        filter: invert(100%) brightness(150%);
    }
  }

  @media screen and (max-width: 796px){
    .sidebar{
      width: 60px;
    }

    .top-logo{
      img{
        width: 45px;
      }
    }

    .nav-item{
      margin-left: 0;
      width: 60px;
      justify-content: center;
      span{
        display: none;
      }
    }

    .panel{
      margin-left: 5px;
    }
  }

`