import styled from "styled-components";

export const Content = styled.div`
  position: relative;
  height: 350px;
  
  .pro-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .pro{
    padding: 2px 10px;
    background-color: var(--primary-color);
    color: #FFFFFF !important;
    border-radius: 16px;
  }

  .description{
    span{
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
      color: #8F8F8F;
      margin: 0;
    }

    .info{
      display: flex;
      flex-wrap: wrap;
      .info-left, .info-right{
        
        width: 49%;
        margin-right: 0.5%;

        h6{
          margin-top: 3px;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 150%;
          color: #8F8F8F;
        }

        .details{
          display: flex;
          align-items: center;
          margin-top: 6px;

          img{
            width: 14px;
            margin-right: 6px ;
          }
          
          span{
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            
            /* Additional styles for the text */
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 150%;
            color: var(--black-color);
          }

        }
      }
    }
  }
`;
