import styled from "styled-components";

export const AppDownloadContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100vw;
    flex-direction: column;
    img{
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    h1{
        margin: 50px 20px;
        padding: 0;
        font-size: 14pt;
        text-align: center;
    }

    p{
        margin: 0 20px 30px 20px;
        text-align: center;
    }

    a{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        margin-top: 30px;
        padding: 8px 30px;
        border: 1px solid var(--primary-color);
        border-radius: 8px;
        color: var(--primary-color);
        text-decoration: none;

        img{
            position: relative;
            width: 15px;
        }

    }


`;