import { connect } from "react-redux";
import close_icon from "../../../../../assets/icons/close.svg";
import perfil_image from "../../../../../assets/perfil.png";
import add_adm_icone from "../../../../../assets/icons/add-adm.svg";
import remove_adm_icone from "../../../../../assets/icons/remove-adm.svg";
import Formulario from "../../../../components/Formulario";
import { mapDispatchToProps, mapStateToProps } from "../../../../../utils/config";
import { useState } from "react";
import Loading from "../../../../components/Loading";
import AlertDialog from "../../../../components/AlertDialog";
import { Main, Container } from "../styled";
import InformacoesGrupoController from "../controller";
import CircularImage from "../../../../components/CircularImage";
import Select from "../../../../components/Select";
import { useEffect } from "react";

function AlterarAdministradoresModal({usuario, grupo, setGrupo, errors, setErrors, onClose}){
  const [loading, setLoading] = useState(false);
  const [dadosGrupo, setDadosGrupo] = useState({...grupo});
  const [showSuccess, setShowSuccess] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showFindUser, setShowFindUser] = useState(false);
  const [administradoresId, setAdministradoresId] = useState([]);
  const [nomeUsuario, setNomeUsuario] = useState();
  const [administradores, setAdministradores] = useState(grupo.administradores);
  const [usuarios, setUsuarios] = useState(false);

  const informacoesGrupoController = new InformacoesGrupoController(dadosGrupo, setDadosGrupo, errors, setErrors, setLoading);

  useEffect(()=>{
    setAdministradoresId([]);
    administradores.map(administrador=>{
      administradoresId.push(administrador.id);
    });

  },[grupo.administradores]);
  return(
    <Main>
    <Container>
      {loading && <Loading/>}
      {showSuccess && (
        <AlertDialog
          titulo={`Administradores alterados com sucesso com sucesso!`}
          buttonText={"Ver meus dados"}
          buttonOnClick={onClose}
        />
      )}
      <div className="content-modal">
        <section>
          <div className="close" onClick={onClose}>
            <button type="button">
              <img src={close_icon} alt="Icone de fechar"/>
            </button>
          </div>
        </section>

        <section>
          <div className="title">
            <h2>Alterar Administradores do Grupo</h2>
          </div>
        </section> 

        <Formulario id="form-alterar-sobre">
          
        <div className="form-group">
          <div className="add-adm-item">
            <input id="administador" name="administador" value="Você" disabled/>
            <img src={add_adm_icone} alt="Icone de adicionar" className="add-button" onClick={()=>setShowFindUser(true)}/>
          </div>
        </div>
              
          {administradores.map(administrador=>{
            if(administrador?.is_admin && administrador.usuario.id !== usuario.id){
              return <div className="form-group">
                <div className="add-adm-item-list">
                  <input className="input-prefix" name="administador" value={administrador.usuario.nome} disabled/>
                  <CircularImage className="prefix" src={administrador?.usuario?.foto_perfil?.path || perfil_image} alt="Foto do usuario" />
                  <img src={remove_adm_icone} alt="Icone de adicionar" className="add-button" onClick={()=>informacoesGrupoController.removeAdministrador(administrador, administradores, setAdministradores)}/>
                </div>
              </div>
            }
          }
            
          )}
      
      
          { showFindUser &&
            <div className="form-group">
              <div className="add-adm-item">
                <Select
                  id="usuarios" 
                  name="usuarios" 
                  placeholder="Pesquise por usuarios aqui" 
                  required={false}
                  autoComplete="off"
                  value={nomeUsuario} 
                  onChange={(e)=>informacoesGrupoController.filtrarUsuarios(e, setUsuarios, setNomeUsuario, administradoresId)} 
                  className={`input-prefix ${errors.usuarios && 'input-error'}`}
                  options={usuarios?.rows}
                  onClickOption={(usuario)=>informacoesGrupoController.addAdministrador(grupo.id, usuario, administradores, setAdministradoresId, setUsuarios, setNomeUsuario)}
                  showOptions={showOptions}
                  setShowOptions={setShowOptions}
                  perfil={true}
                />  
              </div>
              <span className="error">{errors.usuarios}</span>
            </div>
          }

          <div className="form-group">
          <button type="button" onClick={(e)=>informacoesGrupoController.alterarAdministradores(administradores, setShowSuccess, setGrupo)}>Salvar</button> 
          </div>


              
        </Formulario>
      </div>
    </Container>
    </Main>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AlterarAdministradoresModal);