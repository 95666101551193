import axios from 'axios';

// export const baseURL = "http://localhost:3000/";
// export const baseURL = "http://apidev.geoplantio.com.br/";
export const baseURL = "https://back.geoplantio.com.br/";
const api = axios.create({
    baseURL: baseURL
});

// Interceptador para adicionar o token do usuário ao cabeçalho da requisição
api.interceptors.request.use(
  config => {
    let usuario = sessionStorage.getItem('usuario');
    if(usuario){
      usuario = JSON.parse(usuario);
    }
    config.headers.Authorization = usuario ? `Bearer ${usuario.token}` : ``;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
  
);

export default api;