import { Main, Container } from "../styled";
import close_icone from "../../../../../assets/icons/close.svg";
import edit_icon from "../../../../../assets/icons/edit.svg";
import { useState } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../../../utils/config";
import Loading from "../../../../components/Loading";
import AlertDialog from "../../../../components/AlertDialog";
import AlterarNomeModal from "../AlterarNomeModal";
import AlterarSobreModal from "../AlterarSobreModal";
import AlterarEstadoModal from "../AlterarEstadoModal";
import AlterarAdministradoresModal from "../AlterarAdministradoresModal";
import { useEffect } from "react";
import DeleteDialog from "../../../../components/DeleteDialog";
import InformacoesGrupoController from "../controller";
import { useNavigate } from "react-router-dom";

function InformacoesGrupoModal({grupo, setGrupo, onClose, errors, setErrors}){
  const [showSuccess, setShowSuccess] = useState(false);
  const [showAlterarNomeModal, setShowAlterarNomeModal] = useState(false);
  const [showAlterarSobreModal, setShowAlterarSobreModal] = useState(false);
  const [showAlterarEstadoModal, setShowAlterarEstadoModal] = useState(false);
  const [showAlterarAdministradoresModal, setShowAlterarAdministradoresModal] = useState(false);
  const [showdeletarGrupoModal, setShowdeletarGrupoModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countAdministradores, setConuntAdministradores] =useState(0);
  const navigate = useNavigate();
  const closeModal = onClose;

  const informacoesGrupoController = new InformacoesGrupoController(grupo, setGrupo, errors, setErrors, setLoading);

  useEffect(()=>{
    let count = 0;
    grupo.administradores.map((administador)=>{
      if(administador.is_admin){
        count++;
      }
    });
    setConuntAdministradores(count);
  }, [grupo.administradores, grupo])

  
  return(
    <Main>
      {loading && <Loading titulo={"Aguarde"} subtitulo={"Estamos deixando tudo pronto para você..."}/>}
      {showAlterarNomeModal && <AlterarNomeModal grupo={grupo} setGrupo={setGrupo} onClose={()=>setShowAlterarNomeModal(false)}/>}
      {showAlterarSobreModal && <AlterarSobreModal grupo={grupo} setGrupo={setGrupo} onClose={()=>setShowAlterarSobreModal(false)}/>}
      {showAlterarEstadoModal && <AlterarEstadoModal grupo={grupo} setGrupo={setGrupo} onClose={()=>setShowAlterarEstadoModal(false)}/>}
      {showAlterarAdministradoresModal && <AlterarAdministradoresModal grupo={grupo} setGrupo={setGrupo} onClose={()=>setShowAlterarAdministradoresModal(false)}/>}
      
      {showdeletarGrupoModal && <DeleteDialog 
        titulo={`Deseja deletar este ${grupo.tipo === "Grupo Principal" ? "grupo" : "subgrupo"}?`} 
        subtitulo={"Todos os dados e postagens do grupo serão excluídos."}
        onClose={()=>setShowdeletarGrupoModal(false)}
        onDelete={()=>{
          informacoesGrupoController.deleteGrupo(()=>{
            setShowSuccess(true);
            setShowdeletarGrupoModal(false); 
          })
          
        } }

      />}
      {showSuccess &&
          <AlertDialog
            titulo={`${grupo.tipo === "Grupo Principal" ? "Grupo" : "Subgrupo"} deletado com sucesso!`} 
            buttonText={"Ver grupos"} 
            buttonOnClick={()=>{
              navigate('/grupos');
            }}
          />
        }
      <Container>
        <section>
          <div className="close" onClick={closeModal}>
            <button type="button">
              <img src={close_icone} alt="Icone de fechar"/>
            </button>
          </div>
        </section>

        <section>
          <div className="title">
            <h2>Informações do {grupo.tipo === "Grupo Principal" ? "grupo" : "subgrupo"}</h2>
          </div>
        </section>  

        <section className="painel">
            <section className="card">

              <div className="list">
                <div className="item border">
                  <label>Nome</label>
                  <div className="content-item">
                    <span>{grupo.nome}</span>
                    <img src={edit_icon} alt="Icone de editar" onClick={()=>{setShowAlterarNomeModal(true)}}/>
                  </div>
                </div>
              </div>

              <div className="list">
                <div className="item border">
                  <label>Sobre</label>
                  <div className="content-item">
                    <span>{grupo.sobre}</span>
                    <img src={edit_icon} alt="Icone de editar" onClick={()=>{setShowAlterarSobreModal(true)}}/>
                  </div>
                  
                </div>
              </div>

              <div className="list">
                <div className="item border">
                  <label>Estado</label>
                  <div className="content-item">
                    <span>{grupo.estado}</span>
                    <img src={edit_icon} alt="Icone de editar" onClick={()=>{setShowAlterarEstadoModal(true)}}/>
                  </div>
                  
                </div>
              </div>

              <div className="list">
                <div className="item border">
                  <label>Administradores</label>
                  <div className="content-item">
                    <span>{countAdministradores}</span>
                    <img src={edit_icon} alt="Icone de editar" onClick={()=>{setShowAlterarAdministradoresModal(true                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           )}}/>
                  </div>
                </div>
              </div>

              <button className="delete-account" onClick={()=>{setShowdeletarGrupoModal(true)}}>Deletar grupo</button>
            
            </section>     
        </section>
      </Container>
    </Main>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InformacoesGrupoModal);