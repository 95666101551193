import SideBar from "../../../components/SideBar";
import { Main } from "../../styled";
import { useState } from "react";
import { useEffect } from "react";
import Loading from "../../../components/Loading";
import { useRef } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../../utils/config";
import ReactApexChart from "react-apexcharts";
import { FilterDaysComponent } from "../../../components/filterDays";
import Plantio from "../../../../models/Plantio";
import CustomHandle from "../../../../utils/CustomHandle";
import { addDays, formatDate } from 'date-fns';
import GraphLinhaPlantios  from "../GraphLinhaPlantios";
import GraphLinhaUsuarios from "../GraphLinhaUsuarios";
import GraphBarraPlantios from "../GraphBarraPlantios";


function Home() {


  const [loading, setLoading] = useState(true);
  const [selectedEstado, setSelectedEstado] = useState('todos');
  

  

  return (
    <SideBar>
      <Main>

        <div className="content">
          <section>
            <div className="top">
              <div className="title">
                <h2>Relatórios</h2>
                <span>Por aqui você pode acompanhar com base em dados os detalhes da plataforma.</span>
              </div>

            </div>

          </section>

          <section className="graphic-container">
          {loading && <Loading /> }

          {selectedEstado === 'todos' ?
            <GraphLinhaPlantios setLoading={setLoading} setSelectedEstado={setSelectedEstado}/> :
            <GraphBarraPlantios setLoading={setLoading} setSelectedEstado={setSelectedEstado} selectedEstado={selectedEstado}/>
          }
          <GraphLinhaUsuarios setLoading={setLoading}/>]
          </section>

        </div>
      </Main>
    </SideBar>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);