import perfil_image from "../../../../assets/perfil.png";
import capa_image from "../../../../assets/capa.png";
import { Card } from "../styled";
import { Content } from "./styled";
import { Link } from "react-router-dom";
import CircularImage from "../../CircularImage";

function CardGrupo({textButton, grupo, onClick, link}){
  let usuarios = grupo?.grupos_usuarios;
  return (
    
    <Card className="card">
      <Content>
        
        <section>
          <div className="image">
            <img src={grupo?.foto_capa?.path || capa_image} alt="Foto de capa do grupo"/>
          </div>
        </section>

        <section>
          <div className="description">
            <h4>{grupo?.nome}</h4>
            <div className="info">

              <div className="participantes">
                {
                  usuarios?.map(grupo_usuario=>{
                    return ( <CircularImage src={grupo_usuario.usuario?.foto_perfil?.path || perfil_image} alt={"Foto de perfil do participante"}/>)
                  })
                }
                <h6>{grupo?.quantidade_usuarios} participantes</h6>
              </div>

              {(link || onClick) &&
                <div className="about">
                  <span>{grupo?.sobre}</span>
                </div>
              }
            </div>
          </div>
        </section>
        
        {(link || onClick) &&
          <section>
            <div className="bottom">
              {
                onClick ?
                  <button type="button" onClick={onClick}>{textButton || "Ver grupo"}</button>
                : <Link to={link.toString()}>{textButton || "Ver postagens"}</Link>
              }
              
            </div>
          </section>
        }
      </Content>
    </Card>    
  );
}

export default CardGrupo;

