import styled from "styled-components";

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 110%;
  overflow: hidden;
  border-radius: 10px;
  margin-top: 12px;
  height: 215px;

  a{
    height: 100% !important;
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    img{
      height: 100% !important;
      width: 100%;
      object-fit: cover;
    }
  }

  .img-patrocinador{
    filter: brightness(50%);
    width: 100%;
    object-fit: cover;
    
  }

  .info{
    position: absolute;
    top: 18px;
    left: 18px;

    .tag{
      width: 95px;
      border-radius: 5px;
      padding: 5px 9px;

      background-color: #fff;
      color: var(--primary-color);
      
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 120%;

    }

    .details, .title{
      color: #fff;
      text-shadow: 0px 0px 5px black;
    }

    .title{
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 120%;
      margin-top: 24px;
    }

    .details{
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 150%;
      width: 80%;
      margin-top: 12px;
    }

    button{
      width: 80%;
      height: 36px;
      background-color: var(--primary-color);
      border: none;
      color: #fff;
      margin-top: 12px;
      border-radius: 8px;
      cursor: pointer;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;

      margin-left: 0 !important;
      
      line-height: 150%;
    }
  }
`