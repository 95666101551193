import { Content, Main } from "./styled";
import success from "../../../assets/success.png";

function AlertDialog({titulo, width, buttonText, buttonOnClick, secondaryButtonText, secondaryButtonOnclick}){
  return(
    <Main>
      <Content width={width}>
       
        <img src={success} alt="Icone de Carregamento" className="success"/>         
        <h3>{titulo}</h3>
        <button type="button" onClick={buttonOnClick}>{buttonText}</button>
        {
          secondaryButtonText &&
          <div className="secondary-button">
            <button type="button" onClick={secondaryButtonOnclick}>{secondaryButtonText}</button>
          </div>
        }
      </Content>
    </Main>
  );
}

export default AlertDialog;