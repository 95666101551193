import styled from "styled-components";

export const Main = styled.div`
  z-index: 1000;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(50,50,50,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Content = styled.div`
 
  background-color: #fff;
  width: ${props=>props.width ? props.width : 281}px;
  background: #FFFFFF;
  border-radius: 12px;
  padding: 21px 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  img{
    width: 240px;
  }

  h3{
    width: 70%;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    margin: 18px 0;
  }

  button{
    background-color: var(--primary-color);
    width: 100%;
    height: 40px;
    color: #fff;
    border: none;
    border-radius: 9px;
    cursor: pointer;
    margin-top: 0 !important;
    margin-bottom: 1px !important;
  }

  .secondary-button{
    width: 100%;
    button{
      margin-top: 18px;
      background-color: transparent;
      color: var(--primary-color);
      border: 1px solid var(--primary-color);
      font-weight: bold;
    }
  }

`