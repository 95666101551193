import styled from "styled-components";

export const Form = styled.form`
  .form-group{
    display: flex;
    flex-direction: column;
    margin-top: 0.3rem;
    position: relative;

    .error{
      color: #C43333;
      font-size: 11px;
      margin-left: 5px;
      margin-top: 2px;
    }

    .sufix{
      align-self: flex-end;
      margin: -30px 20px 15px;
      cursor: pointer;
      width: 16px;     
    }

    .prefix{
      position: absolute;
      width: 18px;
      height: 18px;
      top: 57px;
      left: 12px;
      cursor: pointer;
    }

    .sufix-arrow{
      position: absolute;
      width: 18px;
      height: 18px;
      top: 57px;
      right: 12px;
      cursor: pointer;
    }

    .input-prefix{
      padding-left: 50px;
      width: 100%;
    }

    .input-sufix{
      width: 100%;
      padding-right: 50px;
    }

    .autocomplete-dropdown-container{
      background-color: #fff;
      width: 100%;
      position: absolute;
      z-index: 1000;
      box-shadow: 0px 2px 3px rgba(36, 36, 36, 0.3);

      .suggestion-item, .suggestion-item--active{
        padding: 5px 0;
        cursor: pointer;
        display: flex;
        align-items: center;

        span, img{
          margin-left: 12px;

          :hover{
            font-weight: bold;
          }
        }
      }
    }    
  }

  label{
    margin-top: 18px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 120%;
  }

  input, select, textarea{
    height: 42px;
    border: 1px solid #CFCFCF;
    border-radius: 4px;   
    margin-top: 12px;
    padding: 0 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    color: var(--black-color);

    :focus{
      outline: 1px solid #CFCFCF;
    }
  }

  .declaracao{
    height: 62px;
  }

  .multiple-line{
    height: 100px !important;
    resize: none !important;
    padding: 0 12px !important;
  };

  button{
    padding: 6px;
    border: none;
    margin: 3px 0;
    background-color: var(--primary-color);
    color: #fff;
    cursor: pointer;
    border-radius: 3px;
    margin-top: 26px;
    height: 42px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 150%;

    :disabled{
      background-color: gray;
    }

    a{
      text-decoration: none;
      color: #fff;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
    }

   
  
  }

  .form-row{
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
  }

  .input-error   {
    border: 1px solid #C43333;
  }


  #midias{
    display: none;
  }

  .midias{
    display: flex;
    flex-wrap: wrap;
  }



  .image{
    display: flex;
    align-items: center;
    justify-content: center;

    border: 2px dashed #CFCFCF;
    border-radius: 4px;
    width: 60px;
    height: 60px;
    margin-right: 10px;

    cursor: pointer;

    .plus-icon{
      width: 15px;
    }

    .preview-image, .preview-video{
      width: 58px;
      height: 58px;
      object-fit: cover;
      cursor: pointer;
      border-radius: 4px;
    }

    :hover .blur{
      display: flex;
    }

    .blur{
      position: absolute;
      border-radius: 4px;
      background-color: rgba(36,36,36,0.5);
      width: 58px;
      height: 58px;
      display: none;
      align-items: center;
      justify-content: center;
    }

  }

  .image-error{
    border: 2px dashed rgb(196, 51, 51);
  }

  .card-app-container{
    background-color: #F9FAF9;
    padding: 16px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 270px;
  }

  .card-app-profile{
    display: flex;  
    gap: 16px; 
  }

  .card-app-profile-img{
    width: 40px !important;
    height: 40px !important;
    border-radius: 100% !important;
  }

  .card-app-profile-data{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .card-app-profile-name{
    font-size: 14px;
    font-weight: bold;

  }

  .card-app-descricao{
    width: 250px;
    font-size: 14px;
  }

  .card-app-profile-locale{
    display: flex;
    gap: 4px;
    align-items: center;
  }

  .card-app-profile-locale-icon{
    width: 16px !important;
  }

  .card-app-profile-state{
    font-size: 12px;
  }


  
`