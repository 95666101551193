import styled from "styled-components";

export const Profile = styled.div`

  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  width: 25px; 
  height: 25px;
  border-radius: 50%; 
  overflow: hidden; 

  img{
    width: 100%;
    object-fit: cover;
  }

`