import styled from "styled-components";
export const Content = styled.div`
  display: flex;
  background-color: #fff;
  width: 100%;
  height: 100vh;

  .especies-container{
    display: flex;
    margin-top: 4px;
    flex-direction: column;
    gap: 8px;

    .nome-especie{
      background-color: #71BE82;
      color: #fff;
      border-radius: 12px;
      padding: 2px 8px;
    }

    .especie-item{

      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .especies-count{
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: center;
      
      
      span{
        margin: 0;
        padding: 0;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 1px solid #F7F7F7 ;
        width: 20px;
        border-radius: 50%;
        &:hover{
          color: var(--primary-color);
          border: 1px solid var(--primary-color);
        }
      }
      input{
        width: 60px;
        height: 25px;
        margin: 0;
        text-align: center;
      }
    }
  }
  

  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  .coordenadas-list{
    display: flex;
    flex-direction: column;
    gap: -4px !important;
  }

  .left-section{
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 5% 7%;
    overflow-y: scroll;

    h2, .primary{
      font-style: normal;
      font-weight: 700;
      font-size: 42px;
      line-height: 120%;
      margin: 0;
    }

    span{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }

    .coordenadas{
      color: #838383;
      font-size: 12px;
      font-weight: 500;
      margin-top: 12px;
      
      span{
        font-size: 12px;
        line-height: 150%;
        color: var(--black, #4D4F50) !important;
      }
    }
    
  }

  .right-section{
    width: 50%;
    height: 100vh;
    overflow: hidden;
    display: flex; 
    justify-content: center; 
    align-items: center; 

    img{
      width: 100%; 
    }
  }

  @media screen and (max-width: 796px){
    .right-section{
      display: none;
    }

    .left-section{
      width: 100%;
      padding-top: 60px;
      height: calc(100vh - 60px);
    }
  }
`