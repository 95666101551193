import { Main, Container } from "./styled";
import close_icone from "../../../../assets/icons/close.svg";
import localizacao_icone from "../../../../assets/icons/localizacao.svg";
import perfil_image from "../../../../assets/perfil.png";
import { useState } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../../utils/config";
import Loading from "../../../components/Loading";
import { useEffect } from "react";
import ControllerListaParticipantes from "./controller";
import CircularImage from "../../../components/CircularImage";

function ListaParticipantesModal({grupo, setGrupo, onClose, errors, setErrors}){
  const [participantes, setParticipantes] = useState();
  const [loading, setLoading] = useState(true);
  const closeModal = onClose;

  const controllerListaParticipantes = new ControllerListaParticipantes(setLoading, errors, setErrors);

  useEffect(()=>{
    controllerListaParticipantes.findAllParticipantes(grupo.id, setParticipantes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  
  return(
    <Main>
      {loading && <Loading titulo={"Aguarde"} subtitulo={"Estamos deixando tudo pronto para você..."}/>}
      
      <Container>
        <section>
          <div className="close" onClick={closeModal}>
            <button type="button">
              <img src={close_icone} alt="Icone de fechar"/>
            </button>
          </div>
        </section>

        <section>
          <div className="title">
            <h2>Lista de Participantes</h2>
          </div>
        </section>  

        <section className="painel">
            <section className="card">
              {participantes?.rows?.map(participante=>
                <div className="participante">
                  <CircularImage src={participante?.foto_perfil?.path || perfil_image} alt="Foto do usuario" />
                  <div>
                    <span>{participante.nome}</span>
                    <div className="location">
                      <img src={localizacao_icone} alt="icone de localizacao"/>
                      <span>{participante.estado}</span>
                    </div>
                    
                  </div>
                  
                </div>  
              
              )}
              
            </section>   

        </section>
      </Container>
    </Main>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaParticipantesModal);