import SideBar from "../../../components/SideBar";
import { Main } from "../../styled";
import { Content } from "./styled";
import localizacao_icone from "../../../../assets/icons/localizacao.svg";
import capa_image from "../../../../assets/capa.png";
import perfil_image from "../../../../assets/perfil.png";
import editar_icon from "../../../../assets/icons/edit.svg";
import CircularImage from "../../../components/CircularImage";
import CardApoiador from "../../../components/Cards/CardApoiador";
import CardArvore from "../../../components/Cards/CardArvores";
import { useEffect } from "react";
import { useState } from "react";
import Loading from "../../../components/Loading";
import { useNavigate } from "react-router-dom";
import arvore_image from "../../../../assets/arvore.png";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../../utils/config";
import ControllerPerfil from "./controller";
import Toast from "../../../components/Toast";

function Home({ usuario, setUsuario, errors, setErrors }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showAlterarPerfil, setShowAlterarPerfil] = useState(false);
  const [plantios, setPlantios] = useState({ rows: [] });
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [patrocinadores, setPatrocinadores] = useState([]);

  const controllerPerfil = new ControllerPerfil(usuario, setUsuario, errors, setPlantios, setErrors, setLoading, setPatrocinadores);

  useEffect(() => {
    controllerPerfil.findData();
    controllerPerfil.findPatrocinadores();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SideBar>
      <Main>
        {loading && <Loading titulo={"Aguarde um momento"} subtitulo={"Estamos carregando suas informações"} />}
        <Toast error={errors.geral} />
        <Content className="content">
          <section>
            <div className="top">
              <div className="title">
                <h2>Perfil</h2>
              </div>
            </div>
          </section>

          <div className="panel">
            <section className="left-section">

              <section>
                <div className="perfil">
                  <div className="foto-capa">
                    <img src={usuario?.foto_capa?.path || capa_image} alt="Foto de capa do usuário" />
                    {showAlterarPerfil &&
                      <label htmlFor="foto-capa">
                        <input className="d-none" accept="image/png, image/jpeg" type="file" id="foto-capa" name="foto-capa" onChange={(e) => controllerPerfil.handleMidia(e, 'foto_capa')} />
                        <div className="button-edit-capa">
                          <img src={editar_icon} alt="Icone de editar" />
                        </div>
                      </label>
                    }
                  </div>
                  <div className="foto-perfil">

                    <CircularImage src={usuario?.foto_perfil?.path || perfil_image} alt="Foto do perfil do usuário" />
                    {showAlterarPerfil &&
                      <label htmlFor="foto-perfil">
                        <input className="d-none" type="file" accept="image/png, image/jpeg" id="foto-perfil" name="foto-perfil" onChange={(e) => controllerPerfil.handleMidia(e, 'foto_perfil')} />
                        <div className="button-edit-perfil">
                          <img src={editar_icon} alt="Icone de editar" />
                        </div>
                      </label>
                    }

                  </div>
                </div>

                <div className="info">
                  <div className="info-user">
                    <div>
                      <h4>{usuario.nome || "Seu nome"}</h4>
                      <div className="info-localizacao">
                        <img src={localizacao_icone} alt="Icone de localizacao" />
                        <span>{usuario.estado || "Seu estado"}</span>
                      </div>
                    </div>
                    <div>
                      <button type="button" onClick={() => setShowAlterarPerfil(!showAlterarPerfil)}>{showAlterarPerfil ? 'Concluir edição' : 'Editar perfil'}</button>
                    </div>
                  </div>

                  <div className="info-about">
                    {showAlterarPerfil ?
                      <input type="text" placeholder="Adicione uma descrição sua aqui." value={usuario.sobre} onChange={(e) => controllerPerfil.handleSobre(e, typingTimeout, setTypingTimeout)} /> :
                      <span>{usuario.sobre || "Adicione uma descrição sua aqui."}</span>
                    }
                  </div>
                </div>
              </section>

              <section className="section-arvores">
                <div className="top">
                  <h3>Árvores plantadas</h3>
                  <h6 className="primary" onClick={() => navigate('/minhas-arvores')}>Ver todas</h6>
                </div>

                <div className="arvores">
                  {
                    plantios.rows.length > 0 ?
                      plantios.rows.map(plantio => (
                        <CardArvore plantio={plantio} />
                      ))
                      :
                      <div className="painel-empty">
                        <img src={arvore_image} alt="Icone de uma árvore" />
                        <span>Você ainda não plantou nenhuma árvore...</span>
                      </div>
                  }
                </div>
              </section>

              {/* <section>
                <div className="panel-postagens">
                  <h3>Postagens</h3>
                  {
                    Array.from({ length: 3 }).map((_, index) => (
                      <CardPostagem/>     
                    ))
                  }
                </div>
              </section>    */}

            </section>

            <section className="right-section">

              <div className="supporters">
                <h3>Seja um patrocinador</h3>
                <CardApoiador />
              </div>
              {patrocinadores.length > 0 &&
                <div className="supporters">
                  <h3>Patrocinadores</h3>
                  {patrocinadores.map(patrocinador=>(
                      <CardApoiador patrocinador={patrocinador}/>
                  ))}
                
                </div>
              }

            </section>
          </div>
        </Content>
      </Main>
    </SideBar>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);