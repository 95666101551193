import * as S from './styled';

export const ITEMS_DAYS_FILTER = [
    { value: 1, label: "Ontem" },
    { value: 7, label: "7 dias" },
    { value: 15, label: "15 dias" },
    { value: 30, label: "30 dias" },
]

export const FilterDaysComponent = (props) => {
    return (
        <S.FilterDaysContainer className='b3-regular'>
            {ITEMS_DAYS_FILTER.map(item => (
                <>
                    {(item.value === 1 && !props.today) ?
                        <></> :
                        <S.FilterDaysItem onClick={() => props.setSelected(item.value)} className={props.selected === item.value ? 'active' : ''}>
                            {item.label}
                        </S.FilterDaysItem>
                    }
                </>
            ))}

        </S.FilterDaysContainer>
    )
}