import SideBar from "../../../components/SideBar";
import { Main } from "../../styled";
import { useState } from "react";
import PlantioModal from "../PlantioModal";
import CardPlantio from "../../../components/Cards/CardPlantio";
import InputSearch from "../../../components/InputSearch";
import { useEffect } from "react";
import Loading from "../../../components/Loading";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import arvore_image from "../../../../assets/arvore.png";
import ControllerMinhasArvores from "./controller";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../../utils/config";
import { ModalAssinatura } from "../../../components/ModalAssinatura";

function Home({usuario, errors, setErrors}){
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [plantios, setPlantios] = useState([]);
  const [plantioSelecionado, setPlantioSelecionado] = useState({});
  const [search, setSearch] = useState("");
  const [selectedTab, setSelectedTab] = useState({title:"Todos os plantios", pro: false});
  const [page, setPage] = useState(1);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [showModalPro, setShowModalPro] = useState(true);
  const divRef = useRef(null);
  const navigate = useNavigate();

  const controllerMinhasArvores = new ControllerMinhasArvores(page, setPage, search, errors, setErrors, setSearch, setPlantios, setPlantioSelecionado, setShowModal,  setLoading);

  useEffect(()=>{
    controllerMinhasArvores.findPlantios(usuario.id, selectedTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, page, usuario, selectedTab]);

  return(
    <SideBar>
      <Main>
        { loading && <Loading/>}
        {showModal && <PlantioModal onClose={()=>setShowModal(false)} plantio={plantioSelecionado}/>}
        <div className="content" ref={divRef}>
          <section>
            <div className="top">
              <div className="title">
                <h2>Meus Plantios</h2>
                <span>Acesse todas as suas plantações e veja detalhes.</span>
              </div>

              <InputSearch 
                text="Pesquisar plantio" 
                onChangeCapture={(e)=>controllerMinhasArvores.handleSearch(e, typingTimeout, setTypingTimeout)} 
                error={errors.geral}
              />
              
            </div>
            
          </section>

          <section>
            <div className="tabs">
              {controllerMinhasArvores.tabs.map(tab=>(
                <div className={`tabItem ${selectedTab.title === tab.title && 'selected'}`} onClick={()=>setSelectedTab(tab)}>
                  <span className="title">{tab.title}</span>
                  {tab.pro && <span className="pro">PRO</span>}
                </div>
              ))}
            </div>
          </section>

          {(selectedTab.pro && showModalPro && usuario.tipo === "Comum") &&
            <ModalAssinatura onClose={()=>setShowModalPro(false)}/>
          }
            
          <section>
            {
              plantios?.rows?.length > 0 ?
              <div className="painel">
                {plantios?.rows?.map(plantio=>
                  <CardPlantio key={`card-plantio-${plantio.id}`} plantio={plantio} onClick={()=>controllerMinhasArvores.handleDetalhes(plantio)}/>   
                )}
                
                { plantios.rows.length !== plantios.count &&
                  <span className="more" onClick={()=>setPage(page+1)}> Ver mais </span>
                }
              </div> :
              
              <div className="painel-empty">
                <img src={arvore_image} alt="Icone de uma árvore"/>
                <span>{search ? "Nenhuma árvore encontrada..." : "Você ainda não plantou nenhuma árvore..."}</span>
                <button type="button" onClick={()=>navigate('/plantar')}>Registrar plantio</button>
              </div>
            }
          </section>          
          
        </div>
      </Main>
    </SideBar>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);