import { Profile } from "./styled";

function CircularImage({src, alt, className}){
  return (
    <Profile className={`profile ${className}`}>
      <img src={src} alt={alt}/>
    </Profile>
  );
}

export default CircularImage;