import { Main, Container } from "./styled";
import close_icone from "../../../../assets/icons/close.svg";
import InformacoesPlantio from './informacoes';
import { useNavigate } from "react-router-dom";
import ControllerPlantioModal from "./controller";

function PlantioModal(props){
  const navigate = useNavigate();
  const closeModal = props.onClose;
  const plantio = props.plantio;

  const controllerPlantioModal = new ControllerPlantioModal();

  return(
    <Main>
      <Container>
        <section>
          <div className="close" onClick={closeModal}>
            <button type="button">
              <img src={close_icone} alt="Icone de fechar"/>
            </button>
          </div>
        </section>
      
        <InformacoesPlantio 
          plantio={plantio} 
          previousImage={()=>controllerPlantioModal.previousImage()} 
          nextImage={()=>controllerPlantioModal.nextImage()}
        />

        <section>
          <div className="bottom">
            <button type="button" onClick={()=>navigate(`/minhas-arvores/atualizar/${plantio.id}`)}>Atualizar Plantio</button>
          </div>
        </section>
        
      </Container>
    </Main>
  );
};

export default PlantioModal;