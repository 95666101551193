import api from '../services/api';

const Patrocinador = {
  create : async function (data) {
    return await api.post('patrocinadores', data)
    .then(response=>response)
    .catch(error=>error);
  },

  delete : async function (patrocinador_id) {
    return await api.delete(`patrocinadores/id/${patrocinador_id}`)
    .then(response=>response)
    .catch(error=>error);
  },

  findAll : async function () {
    return await api.get('patrocinadores/all/1/50')
    .then(response=>response)
    .catch(error=>error);
  },

  findSearch : async function (data) {
    return await api.get(`patrocinadores/data/${data}/1/50`)
    .then(response=>response)
    .catch(error=>error);
  }
}

export default Patrocinador;
