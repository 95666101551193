import Usuario from "../../../../models/Usuario";
import CustomHandle from "../../../../utils/CustomHandle";

class ControllerListaUsuarios {
  constructor(page, setPage, search, errors, setErrors, setSearch, usuarios, setUsuarios, setUsuarioSelecionado, setShowModal,  setLoading) {
    this.page = page;
    this.setPage = setPage;
    this.usuarios = usuarios;
    this.setUsuarios = setUsuarios;
    this.setUsuarioSelecionado = setUsuarioSelecionado;
    this.setShowModal = setShowModal;
    this.setSearch = setSearch;
    this.setLoading = setLoading;
    this.search = search;
    this.errors= errors;
    this.setErrors = setErrors;
    
  }

  handleDetalhes(plantio){
    this.setUsuarioSelecionado(plantio);
    this.setShowModal(true);
  }

  handleSearch(e, typingTimeout, setTypingTimeout){
    clearTimeout(typingTimeout);
    const newTypingTimeout = setTimeout(() => {
      this.setUsuarios({});
      this.setSearch(e.target.value);
      if(this.page!==1){
        this.setPage(1);
      }
    }, 500);
    setTypingTimeout(newTypingTimeout);
  };

  async findUsuarios(page = this.page){
    const result = await Usuario.findSearch(this.search, this.page);
    const onSuccess = (response)=>{
      
      if(page === 1){
        this.setUsuarios(response);
      }else{
        
        const new_usuarios = response.rows;
        this.setUsuarios({...this.usuarios, rows : [...this.usuarios.rows, ...new_usuarios]});
      }
    };

    CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);
    this.setLoading(false);
  }


}

export default ControllerListaUsuarios;
