function validarNomeComum(e, especie, errors, setEspecie, setErrors) {
  setEspecie({...especie, nome_comum : e.target.value})
  if(e.target.value < 1){
    setErrors({ ...errors, nome_comum : "Digite um nome comum válido", geral : ""})
  }else{
    setErrors({ ...errors, nome_comum : "", geral : ""});
  }  
}

function validarNomeCientifico(e, especie, errors, setEspecie, setErrors) {
  setEspecie({...especie, nome_cientifico : e.target.value})
  if(e.target.value < 1){
    setErrors({ ...errors, nome_cientifico : "Digite um nome cientifico válido", geral : ""})
  }else{
    setErrors({ ...errors, nome_cientifico : "", geral : ""});
  }  
}

const ValidacaoEspecie = {
  validarNomeComum,
  validarNomeCientifico
}

export default ValidacaoEspecie;