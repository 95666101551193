import { Form } from "./styled";


function Formulario({children, id, className}){
    
  return(
    <Form className={className} id={id} onSubmit={(e)=>{e.preventDefault()}}>
      {children}
    </Form>
  );
}

export default Formulario;