import { connect } from "react-redux";
import close_icon from "../../../../assets/icons/close.svg";
import Formulario from "../../../components/Formulario";
import { mapDispatchToProps, mapStateToProps } from "../../../../utils/config";
import { useEffect, useState } from "react";
import { useRef } from "react";
import ControllerConfirmarCadastro from "../../auth/CadastroConfirmacao/controller";
import Loading from "../../../components/Loading";
import ControllerConfiguracoes from "../Home/controller";
import { useNavigate } from "react-router-dom";

function ConfirmarDeletarModal({usuario, setUsuario, errors, setErrors, onClose}){
  const [loading, setLoading] = useState(false);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [clickCount, setClickCount] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [timer, setTimer] = useState(30);
  const navigate = useNavigate();

  const controllerConfirmarCadastro = new ControllerConfirmarCadastro(usuario, setUsuario, inputRefs, ()=>{}, errors, setErrors, setLoading, setClickCount, setDisabled, setTimer);
  const controllerConfiguracoes = new ControllerConfiguracoes(usuario, errors, setErrors, setLoading, setUsuario);

  useEffect(() => {
    let countdown = setInterval(() => {
      setTimer((prevTime) => prevTime - 1);
    }, 1000);

    return () => {
      clearInterval(countdown);
    };
  }, [clickCount]);

  useEffect(() => {
    if (timer <= 0) {
      setDisabled(false);
    }
  }, [timer]);

  return(
    <div className="modal">
      {loading && <Loading/>}
      <div className="content-modal">
        <div className="top">
          <h3>Deletar Conta</h3>
          <button type="button" onClick={onClose}>
            <img src={close_icon} alt="Icone de fechar"/>
          </button>
        </div>

        <Formulario id="form-deletar-conta">
            <span>
              Confirme a exclusão da sua conta preenchendo o código enviado por email para{" "}
              <strong>{usuario.email || "seu email"}</strong>
            </span>
  
            <div className="form-row">
              {[0, 1, 2, 3].map((index) => (
                <div className="form-group" key={index}>
                  <label htmlFor={`codigo`}></label>
                  <input
                    className={`input-code ${errors?.codigo && "input-error"}`}
                    type="text"
                    name={`codigo`}
                    id={`codigo`}
                    maxLength="1"
                    required
                    onKeyDown={(e) =>
                      controllerConfirmarCadastro.validarCodigo(e, index)
                    }
                    ref={inputRefs[index]}
                    onPaste={(e) =>
                      controllerConfirmarCadastro.handlePaste(e, inputRefs)
                    }
                  />
                </div>
              ))}
            </div>

            <div className="form-group">
              <span className="error">{errors?.codigo}</span>
            </div>

            <div className="form-group">
              <button
                type="button"
                className="resend-code"
                disabled={disabled}
                onClick={() => controllerConfirmarCadastro.handleResendEmail()}
              >
                {timer > 0
                  ? `Nãorecebi um código (0:${timer < 10 ? "0" + timer : timer})`
                  : "Não recebi um código"}
              </button>
            </div>

          <div className="form-group">
            <button type="button" onClick={(e)=>controllerConfiguracoes.confirmarDeletarConta(e, inputRefs, navigate)}>Prosseguir</button> 
          </div>


              
        </Formulario>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmarDeletarModal);