import Grupo from "../../../../models/Grupo";
import CustomHandle from "../../../../utils/CustomHandle";

class ControllerSolicitacoesPendentes{
    constructor (setLoading, errors, setErrors){
        this.setLoading = setLoading;
        this.errors = errors;
        this.setErrors = setErrors;
    }

    async findAllSolicitacoes(grupo_id, setSolicitacoes){
        const result = await Grupo.getSolicitacoes(grupo_id);
        const onSuccess = (response)=>{
            setSolicitacoes(response);
        };
      
        CustomHandle.handleResult(result, this.errors, this.setErrors, onSuccess);
        this.setLoading(false);
    }

    formatarData(dataISO) {
        const dataAtual = new Date();
        const dataFornecida = new Date(dataISO);
      
        // Verifica se a data fornecida é igual à data atual
        if (
          dataFornecida.getDate() === dataAtual.getDate() &&
          dataFornecida.getMonth() === dataAtual.getMonth() &&
          dataFornecida.getFullYear() === dataAtual.getFullYear()
        ) {
          return "Hoje";
        }
      
        // Calcula a data de ontem
        const ontem = new Date(dataAtual);
        ontem.setDate(dataAtual.getDate() - 1);
      
        // Verifica se a data fornecida é igual à data de ontem
        if (
          dataFornecida.getDate() === ontem.getDate() &&
          dataFornecida.getMonth() === ontem.getMonth() &&
          dataFornecida.getFullYear() === ontem.getFullYear()
        ) {
          return "Ontem";
        }
      
        // Formata a data no formato "dd/mm/YYYY"
        const dia = dataFornecida.getDate().toString().padStart(2, "0");
        const mes = (dataFornecida.getMonth() + 1).toString().padStart(2, "0");
        const ano = dataFornecida.getFullYear();
      
        return `${dia}/${mes}/${ano}`;
      }

      formatarHora(dataISO) {
        const data = new Date(dataISO);
        const hora = data.getHours().toString().padStart(2, "0");
        const minutos = data.getMinutes().toString().padStart(2, "0");
        
        return `${hora}:${minutos}`;
      }
      
    
      
}

export default ControllerSolicitacoesPendentes;