import data_icon from "../../../../assets/icons/data.svg";

import { Card } from "./styled";
import { formatData } from "../../../../utils/conversoes";

function CardArvore({plantio}){
  return (
    <Card>
      <div className="image">
        <img src={plantio.midias[0].path} alt="Imagem da árvore"/>
      </div>
      <div className="description">
        <h4>{plantio.titulo}</h4>
        <h6>Data do plantio</h6>
        <div className="data">
          <img src={data_icon} alt="Icone de calendário"/>
          <span>{formatData(plantio.data)}</span>
        </div>
      </div>
    </Card>
  );
}

export default CardArvore;