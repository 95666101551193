import { Content } from "./styled";

function TagsList({tags, onSelect}){
  return(
    <Content id="tooltip">
      {
        tags?.rows?.map(tag=>{
          return(
            <div className="item" onClick={()=>onSelect(tag.nome)}>
              <span className="nome">{tag.nome}</span> <span className="mencoes"> - {tag.mencoes} menções</span>
            </div>
          )
        })
      }
    </Content>
  );
}

export default TagsList;
