import Formulario from "../../../components/Formulario";
import close_icon from "../../../../assets/icons/close.svg";
import plus_icon from "../../../../assets/icons/plus.svg";
import lixeira_icon from "../../../../assets/icons/lixeira.svg";
import add_image from "../../../../assets/add-imagem.png";
import { estados, tiposGerenciamentoModal } from "../../../../utils/data";
import Select from "../../../components/Select";
import { Main } from "../styled";
import { useState } from "react";
import Apoiador from "../../../../models/Apoiador";
import { useEffect } from "react";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import Validacao from "../../validacao";
import DeleteDialog from "../../../components/DeleteDialog";
import ValidacaoApoiadores from "./validacao";
import { convertToBase64 } from "../../../../utils/conversoes";
import CustomHandle from "../../../../utils/CustomHandle";
import locale_icon from "../../../../assets/icons/pin.svg";

function ApoiadoresModal({ tipo, onClose, apoiador, setApoiador }) {
  const [nomeApoiador, setNomeApoiador] = useState("");
  const [apoiadores, setApoiadores] = useState({});
  const [errors, setErrors] = useState({});
  const [showOptions, setShowOptions] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [loading, setShowLoading] = useState(false);
  const [image, setImage] = useState();

  async function filtrarApoiadores(e) {
    const searchValue = e.target.value;
    setNomeApoiador(searchValue);

    let newApoiadores = [];

    if (searchValue) {
      newApoiadores = await Apoiador.findSearch(searchValue);
    } else {
      newApoiadores = await Apoiador.findAll();
    }

    setApoiadores(newApoiadores.data);
  }

  function onClickSelectApoiador(apoiador) {
    setNomeApoiador(apoiador.nome);
    setApoiador(apoiador);
    setErrors((prevErrors) => ({ ...prevErrors, apoiadores: '' }));
  }


  function validar(e, handle) {
    handle(e, apoiador, errors, setApoiador, setErrors);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (image) {
      convertToBase64([image])
        .then(async (base64Array) => {
          const new_apoiador = { ...apoiador, foto: base64Array[0], link: "https://link.com", organizacao_id: 3 };
          const submitAction = async () => await Apoiador.create(new_apoiador);
          const onSuccess = () => {
            setShowSuccess(true);
          }
          await CustomHandle.submitForm('form-apoiador', submitAction, errors, setErrors, setShowLoading, onSuccess);
        })
        .catch(() => {
          setErrors({ ...errors, midias: "Ocorreu um erro ao tratar a imagem. Tente novamente" })
        });
    } else {
      if (!apoiador.id) {
        setErrors({ ...errors, foto: 'Campo obrigatório' });
      } else {
        const new_apoiador = { ...apoiador };
        delete new_apoiador.foto;
        const submitAction = async () => await Apoiador.create(new_apoiador);
        const onSuccess = () => {
          setShowSuccess(true);
        }
        await CustomHandle.submitForm('form-apoiador', submitAction, errors, setErrors, setShowLoading, onSuccess);
      }

    }

  }

  async function handleDelete() {

    setShowLoading(true);
    const result = await Apoiador.delete(apoiador.id);
    if (result.response) {
      const data = result.response.data;
      setErrors((prevErrors) => ({ ...prevErrors, delete: data.error }));
    } else {
      window.location.href = "/gerenciamento";
    }
    setShowLoading(false);

  }

  function limparCampos() {
    setNomeApoiador("");
    setImage(null);
    setApoiador({});
    setErrors({});
    document.getElementById("foto").value = null;
  }

  function handleSuccessButton() {
    setShowSuccess(false);
    if (tipo === tiposGerenciamentoModal.CRIAR) {
      limparCampos();
    } else {
      // findAllApoiadores();
    }
  }

  useEffect(() => {
    setNomeApoiador(apoiador.nome);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      {tipo !== tiposGerenciamentoModal.NONE &&
        <Main>

          <div className="content-modal modal-apoiador">
            <div className="top">
              <h3>Apoiadores</h3>
              <button type="button" onClick={() => { limparCampos(); onClose() }}>
                <img src={close_icon} alt="Icone de fechar" />
              </button>
            </div>

            <h4>
              {tipo === tiposGerenciamentoModal.EDITAR ? "Editar ou excluir um usuário" : "Adicionar um novo apoiador"}
            </h4>
            <Formulario className="form-apoiador" id="form-apoiador">
              <div className="container-left">
                {tipo === tiposGerenciamentoModal.EDITAR &&
                  <div className="form-group">
                    <label htmlFor="apoiadores">Lista de apoiadores</label>
                    <Select
                      id="apoiadores"
                      name="apoiadores"
                      placeholder="Pesquise por apoiadores aqui"
                      required={false}
                      autoComplete="off"
                      value={nomeApoiador}
                      onChange={filtrarApoiadores}
                      className={`input-prefix ${errors.apoiadores && 'input-error'}`}
                      options={apoiadores.rows}
                      onClickOption={onClickSelectApoiador}
                      showOptions={showOptions}
                      setShowOptions={setShowOptions}
                    />
                    <span className="error">{errors.apoiadores}</span>
                  </div>
                }

                <div className="form-group">
                  <label htmlFor="nome">Nome do Apoiador</label>
                  <input
                    required
                    value={apoiador.nome || ""}
                    type="text"
                    name="nome"
                    id="nome"
                    placeholder={tipo === tiposGerenciamentoModal.EDITAR ? "Escolha um apoiador" : "Digite o nome do novo apoiador"}
                    onChange={(e) => ValidacaoApoiadores.validarNome(e, apoiador, errors, setApoiador, setErrors)}
                  />
                  <span className="error">{errors.nome}</span>
                </div>

                <div className="form-group">
                  <label htmlFor="estado">Estado do apoiador</label>
                  <input type="text" list="estados" id="estado" name="estado" placeholder="Escolha seu estado" required autoComplete="off"
                    value={apoiador.estado ?? ''} onChange={(e) => ValidacaoApoiadores.validarEstado(e, apoiador, errors, setApoiador, setErrors)} className={errors.estado && 'input-error'}
                  />
                  <datalist id="estados">
                    {estados.map((estado, index) => {
                      return <option key={index} value={estado} />
                    })}
                  </datalist>
                  <span className="error">{errors.estado}</span>
                </div>

                <div className="form-group">
                  <label>Imagem do banner do apoiador</label>

                  <input id="foto" type="file" name="foto" accept="image/*" required={!apoiador.id} onChange={(e) => { ValidacaoApoiadores.handleChangeMidia(e, setImage) }} />
                  <div className="foto">
                    <label htmlFor="foto">

                      <div className={`image ${errors.foto && 'image-error'}`} onClick={() => setErrors({ ...errors, foto: "" })}>
                        {image || apoiador?.foto?.path ?
                          <>
                            <img className={`image-added`} src={image ? URL.createObjectURL(image) : apoiador?.foto?.path} alt="Imagem do produto" />
                            <div className="blur"
                              onClick={() => { }}
                            >
                              <div className="blur-bg"></div>
                              <img src={lixeira_icon} alt="Icone de Lixeira" />
                            </div>
                          </> :
                          <img className="plus-icon" src={plus_icon} alt="Iocne de adicionar" />
                        }
                      </div>
                    </label>
                  </div>
                  <span className="error">{errors.foto}</span>
                </div>

                <div className="form-group">
                  <label htmlFor="descricao">Declaração do Apoiador</label>
                  <textarea
                    required
                    className="declaracao"
                    value={apoiador.descricao || ""}
                    type="text"
                    name="descricao"
                    id="descricao"
                    placeholder={tipo === tiposGerenciamentoModal.EDITAR ? "Escolha um apoiador" : "Declaração do apoiador"}
                    onChange={(e) => ValidacaoApoiadores.validarDescricao(e, apoiador, errors, setApoiador, setErrors)}
                  />
                  <span className="error">{errors.descricao}</span>
                </div>

                <div className="buttons">
                  <div className="form-group">
                    <button disabled={tipo === tiposGerenciamentoModal.EDITAR && !apoiador.id} className="save-button"
                      type="button" onClick={handleSubmit}
                    >
                      Salvar edição
                    </button>
                    <span className="error">{errors.geral}</span>
                  </div>

                  {tipo === tiposGerenciamentoModal.EDITAR &&
                    <div className="form-group">
                      <button disabled={!apoiador.id} className="remove-button" onClick={() => setShowDelete(true)} type="button">Excluir apoiador</button>
                    </div>
                  }
                </div>
              </div>

              <div className="container-right">
                <span className="title">Visualização do card</span>

                <div className="cards">
                  <div className="card-app">
                    <span className="sub-title">Aplicativo Geoplantio</span>
                    <div className="card-app-container">
                      <div className="card-app-profile">
                        <img className="card-app-profile-img" src={image ? URL.createObjectURL(image) : apoiador?.foto?.path || add_image} alt="Imagem do banner" />
                        <div className="card-app-profile-data">
                          <span className="card-app-profile-name">{apoiador.nome ?? "Nome"}</span>
                          <div className="card-app-profile-locale">
                            <img className="card-app-profile-locale-icon" src={locale_icon} />
                            <span className="card-app-profile-state">{apoiador.estado ?? "Estado"}</span>
                          </div>
                        </div>
                      </div>

                      <span className="card-app-descricao">{apoiador.descricao ?? "Declaração"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Formulario>
          </div>

          {loading && <Loading titulo={"Aguarde um momento"} subtitulo={"Estamos validando suas informações"} />}
          {showSuccess &&
            <AlertDialog
              width={320}
              titulo={`Apoiador ${apoiador.id ? 'atualizado' : 'cadastrado'} com sucesso!`}
              buttonText={`${apoiador.id ? 'Voltar' : 'Cadastrar novo apoiador'}`}
              buttonOnClick={handleSuccessButton}
            />
          }
          {showDelete &&
            <DeleteDialog
              error={errors.delete}
              titulo="Deseja realmente excluir esse apoiador?"
              subtitulo="Todos os dados deste apoiador seram excluídos de forma permanente."
              onDelete={handleDelete}
              onClose={() => setShowDelete(false)}
            />
          }
        </Main>
      }
    </>
  );
};

export default ApoiadoresModal;