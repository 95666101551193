import React, { useState, useRef, useEffect } from "react";
import { GoogleMap, useJsApiLoader, Marker, MarkerClusterer, Polygon } from "@react-google-maps/api";
import pin_icone from "../../../assets/icons/pin.svg";
import pin_plus_icone from "../../../assets/pin-plus.png";
import pin_pro_icone from "../../../assets/pin-pro.png";
import pin_success_icone from "../../../assets/pin-success.png";
import pin_cluster_icone from "../../../assets/pin_cluster.png";
import { useLocation } from "react-router-dom";

function Map({
  markers,
  mapCenter,
  forceCenter,
  isEndArea = false,
  onClick,
  marker_icon,
  onClickMarker,
  onMarkerDragEnd,
  isPro,
  clusters = false,
  plantio = false
}) {
  const [endArea, setEndArea] = useState(isEndArea);
  const [currentMapCenter, setCurrentMapCenter] = useState();
  const [polygonPath, setPolygonPath] = useState([]);
  const [mousePosition, setMousePosition] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const path = location.pathname;
  const mapRef = useRef(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyChzIrlXUuShcdN83X-FuEArrWJ_1NUqLQ" // Substitua pela sua chave de API do Google Maps
  });

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [path]);

  useEffect(() => {
    if (markers && mapRef.current) {
      const path = markers.map(marker => ({
        lat: parseFloat(marker.lat),
        lng: parseFloat(marker.lng)
      }));

      if (!endArea && mousePosition) {
        path.push(mousePosition);
      }

      if(markers.length === 0 && !plantio){
        setEndArea(false);
      }

      setPolygonPath(path);
    }
  }, [markers, mousePosition, endArea]);

  useEffect(() => {
    if (mapRef.current) {
      setCurrentMapCenter(mapRef.current.props.center);
    }
  }, [mapCenter]);

  const handleMouseMove = (e) => {
    if (!marker_icon) {
      const latLng = e.latLng;
      const pixel = e.pixel;
      setMousePosition({ lat: latLng.lat(), lng: latLng.lng(), x: pixel.x, y: pixel.y });
    }
  };

  const handleMouseLeave = () => {
    setMousePosition(null); // Limpa a posição do mouse ao sair do mapa
  };

  const getCardText = () => {
    return isPro ?
      markers.length > 1 ?
        'Clique duas vezes para finalizar o polígono' :
        'Clique em qualquer local para começar a desenhar' :
      'Clique em qualquer local para adicionar uma coordenada';
  };

  const clusterStyles = [
    {
      textColor: "green",
      url: pin_cluster_icone, // Caminho da imagem do cluster
      height: 40,
      width: 40,
      textSize: 20,
    },
  ];

  return isLoaded && !isLoading ? (
    <div style={{ height: "100vh", width: "100%", position: "relative" }}>
      <GoogleMap
        ref={mapRef}
        mapContainerStyle={{ height: "100%", width: "100%" }}
        center={forceCenter || currentMapCenter || mapCenter}
        zoom={12}
        onCenterChanged={() => setCurrentMapCenter(mapRef.current?.props.center)}
        onMouseMove={handleMouseMove}
        onMouseOut={handleMouseLeave}
      >
        {clusters ? (
          <MarkerClusterer options={{ styles: clusterStyles }}>
            {(clusterer) => markers?.map((marker, index) => (
              <Marker
                key={`marker-${index}`}
                draggable={isPro}
                onDrag={onMarkerDragEnd ? (e) => onMarkerDragEnd(index, e.latLng) : () => { }}
                position={{ lat: parseFloat(marker.lat), lng: parseFloat(marker.lng) }}
                icon={{
                  anchor: new window.google.maps.Point(16, 16),
                  scaledSize: new window.google.maps.Size(32, 32),
                  url: isPro ?
                    index === markers.length - 1 && endArea ?
                      pin_success_icone :
                      pin_pro_icone :
                    (marker_icon || pin_icone)
                }}
                onClick={onClickMarker ?
                  () => onClickMarker(marker) :
                  index === markers.length - 1 ?
                    () => { setEndArea(true) } :
                    () => { }
                }
                clusterer={clusterer}
                title={marker.titulo}
              />
            ))}
          </MarkerClusterer>
        ) : (
          markers?.map((marker, index) => (
            <Marker
              key={`marker-${index}`}
              draggable={isPro}
              onDrag={onMarkerDragEnd ? (e) => onMarkerDragEnd(index, e.latLng) : () => { }}
              position={{ lat: parseFloat(marker.lat), lng: parseFloat(marker.lng) }}
              icon={{
                anchor: new window.google.maps.Point(16, 16),
                scaledSize: new window.google.maps.Size(32, 32),
                url: isPro ?
                  index === markers.length - 1 && endArea ?
                    pin_success_icone :
                    pin_pro_icone :
                  (marker_icon || pin_icone)
              }}
              onClick={onClickMarker ?
                () => onClickMarker(marker) :
                index === markers.length - 1 ?
                  () => { setEndArea(true) } :
                  () => { }
              }
              title={marker.titulo}
            />
          ))
        )}

        {mousePosition && (!endArea) && (
          <>
            <Marker
              position={mousePosition}
              onClick={endArea ? () => { } : onClick}
              icon={{
                anchor: new window.google.maps.Point(16, 16),
                scaledSize: new window.google.maps.Size(32, 32),
                url: isPro ? pin_plus_icone : pin_icone,
              }}
            />
            {(markers.length === 0 || markers.length > 1) &&
              <div
                style={{
                  position: "absolute",
                  transform: "translate(-50%, -100%)",
                  left: `${mousePosition.x}px`,
                  top: `${mousePosition.y - 30}px`,
                  background: "white",
                  padding: "8px",
                  borderRadius: "8px",
                  boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
                  whiteSpace: "nowrap",
                }}
              >
                {getCardText()}
              </div>
            }
          </>
        )}

        {isPro && polygonPath.length > 0 && (
          <Polygon
            key="polygon"
            path={polygonPath}
            options={{
              fillColor: "#D7E3DA",
              fillOpacity: 0.8,
              strokeColor: "#FFFFFF",
              strokeOpacity: 1,
              strokeWeight: 3,
            }}
          />
        )}
      </GoogleMap>
    </div>
  ) : <></>;
}

export default Map;