import styled from "styled-components";

export const Main = styled.main`
  display: flex;
  background-color: #fff;
  width: 100%;
  height: 100vh;

  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);

  .teste{
        padding: 8px 16px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--primary-color) !important;
        color: #fff;
        font-family: "Poppins";
    }

  .action-bar{
    display: flex;
    gap: 13px;

    .select-month, select{
        border: 1px solid #ccc;
        border-radius: 8px;
        padding: 0 8px;

    }
  }

  .graphic-container{
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 32px;
  }

  .empty{
    height: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .grafic-section{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: 1033px;
    /* height: 347px; */
    flex-shrink: 0;

    border-radius: 8px;
    border: 1.5px solid #EFF0F2;
    background: #FEFEFE;
    padding: 20px;
  }

  .graphic-title{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

  .back{
      margin-right: auto;
      margin-top:5px;
      margin-bottom: 12px;
      background-color: transparent;
      border: none;
      cursor: pointer;

      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;

      color: var(--primary-color);

      img{
        width: 6px;
        margin-right: 10px;
      }

    }

  .top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 10px;
    gap: 16px;
    


    section{
      h2{
        font-style: normal;
        font-weight: 700;
        font-size: 27px;
      }
    }

    .title{
      
      h2{
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 150%;
        margin: 0;
      }

      span{
        font-style: normal;
        font-weight: 400;
        font-size: 13,5px;
        line-height: 150%;
        margin-top: 12px;
      }
    }

  }


  .tabs{
    display: flex;
    gap: 24px;

    .selected{
      color: #2AA444;
        .title{
           border-bottom: 1px solid #2AA444;
           font-weight: bold;
        }
    }

    .tabItem{
      display: flex;
      gap: 8px;
      cursor: pointer;

      :hover{
        color: #2AA444;
        .title{
           border-bottom: 1px solid #2AA444;
        }
      }

      .pro{
        display: flex;
        width: 41px;
        height: 23px;
        padding: 8px 4px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: #fff;

        border-radius: 12px;
        background: #2AA444;

      }
    }
  }

  .content{
    background-color: #fff;
    width: 100%;
    height: 100vh;
    padding: 48px 90px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    overflow-y: scroll ;

    button{
      background-color: var(--primary-color);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 30px;
      border-radius: 6px;
      border: none;
      cursor: pointer;
      margin-left: auto;
      margin-right: 15px;
      margin-bottom: -40px;
      margin-top: 30px;

      img{
        width: 27px;
        height: 27px;
        margin-right: 15px;
      }
    }


    .painel{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
  
      .items{
        display: flex;
        flex-wrap: wrap; 
      }

      .more{
        color: var(--primary-color);
        font-size: 15px;
        font-weight: 700;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        :hover{
          font-weight: bold;
        }
      }
    }

    .painel-empty{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img{
        width: 278px;
      }

      span{
        margin-top: 18px;
        font-size: 15px;
        line-height: 150%;
      }

      button{
        width: 325px;
        height: 50px;
        background-color: var(--primary-color);
        color: #fff;
        border-radius: 3px;
        border: none;
        cursor: pointer;
        margin-top: 36px;
        margin-left: 0;
      }
      
    }

  }

  .card-gerenciamento{
    width: 30% !important;
    max-width: 395px !important;
    margin-top: 24px;
    margin-bottom: 0px ;
  }

  @media screen and (max-width: 796px){
    .content{
      padding-top: 60px;
      height: calc(100vh - 60px);
    }
  }
  
`;