import Formulario from "../../../components/Formulario";
import lixeira_icone from "../../../../assets/icons/lixeira-red.svg";
import close_icon from "../../../../assets/icons/close.svg";
import { tiposGerenciamentoModal } from "../../../../utils/data";
import { Main } from "../styled";
import { useState } from "react";
import Especie from "../../../../models/Especie";
import { useEffect } from "react";
import Select from "../../../components/Select";
import ValidacaoEspecie from "./validacao";
import Bioma from "../../../../models/Bioma";
import Validacao from "../../validacao";
import AlertDialog from "../../../components/AlertDialog";
import DeleteDialog from "../../../components/DeleteDialog";
import Loading from "../../../components/Loading";

function EspeciesModal({especie, setEspecie, nomeEspecie, setNomeEspecie, tipo, onClose}){
  const [nomeBioma, setNomeBioma] = useState("");
  const [especies, setEspecies] = useState();
  const [biomas, setBiomas] = useState({rows : []});
  const [errors, setErrors] = useState({});
  const [showOptions, setShowOptions] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [loading, setShowLoading] = useState(false);
  const [showOptionsBiomas, setShowOptionsBiomas] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const placeholder = "Escolha uma espécie";

  async function filtrarEspecies(e) {
    const searchValue = e.target.value;
    setNomeEspecie(searchValue);

    let newEspecies = [];

    if (searchValue) {
      newEspecies = await Especie.findSearch(searchValue);
    } else {
      newEspecies = await Especie.findAll();
    }

    setEspecies(newEspecies.data);
  }

  async function filtrarBiomas(e) {
    const searchValue = e.target.value;
    setNomeBioma(searchValue);

    let newBiomas = [];

    if (searchValue) {
      newBiomas = await Bioma.findSearch(searchValue);
    } else {
      newBiomas = await Bioma.findAll();
    }

    setBiomas(newBiomas);
  }

  function onClickSelectEspecie(especie) {
    setNomeEspecie(`${especie.nome_comum} - ${especie.nome_cientifico}`);
    setEspecie(especie);
    setErrors((prevErrors) => ({ ...prevErrors, especie: '' }));
    
  }

  function onClickSelectBioma(bioma) {
    let new_biomas = especie.biomas;
    new_biomas.push(bioma);
    setNomeBioma('');
    setEspecie({...especie, biomas : new_biomas});
    setErrors((prevErrors) => ({ ...prevErrors, biomas: '' }));
    
  }

  function limparCampos(){
    setEspecie({nome_comum : '', nome_cientifico: '', biomas : []});
    setNomeEspecie('');
    setBiomas(findAllBiomas());
  }

  function handleRemoveBioma(bioma){
    setEspecie({...especie, biomas : especie.biomas.filter((item)=>item!==bioma)});
  }

  async function handleDelete(){
    setShowLoading(true);
    const result = await Especie.delete(especie.id);
    if (result.response) {
      const data = result.response.data;
      setErrors((prevErrors) => ({ ...prevErrors, delete: data.error }));
    }else{
      window.location.href = "/gerenciamento";
    }
    setShowLoading(false);
  }

  async function findAllEspecies() {
    const allEspecies = await Especie.findAll();
    setEspecies(allEspecies.data); 
  }

  async function findAllBiomas() {
    const allBiomas = await Bioma.findAll();
    setBiomas(allBiomas);
  }

  function validar(e, handle) {
    handle(e, especie, errors, setEspecie, setErrors);
  }

  async function handleSubmit(e){
    e.preventDefault();
    const formulario = document.getElementById('form-especie');
    let input_errors = Validacao.validarCampos(formulario, errors, setErrors);
    if(especie.biomas.length === 0){
      input_errors  = {...input_errors, biomas : "Selecione pelo menos um bioma."}
    }
    setErrors(input_errors);
    if (Validacao.emptyErrors(input_errors)) {
      setShowLoading(true);
      let biomas = [];
      especie.biomas.map(bioma=>biomas.push(bioma.id));
      const result = await Especie.create({...especie, biomas : biomas});
      if (result.response ) {
        const data = result.response.data;

        if (data.field) {
          setErrors((prevErrors) => ({ ...prevErrors, [data.field]: data.error }));
          document.getElementById(data.field)?.focus();
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, geral: data.error }));
        }
      }else if(result.message){
        setErrors((prevErrors) => ({ ...prevErrors, geral: 'Ocorreu um erro inesperado. Tente novamente mais tarde.' }));
      }else{
        setShowSuccess(true);
      }
      setShowLoading(false);
    }
  }

  function handleSuccessButton(){
    setShowSuccess(false);
    if(tipo === tiposGerenciamentoModal.CRIAR){
      limparCampos();
    }else{
      findAllEspecies();
    }
  }

  useEffect(() => {
    findAllEspecies();
    findAllBiomas();
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return(
    <>
      {tipo !== tiposGerenciamentoModal.NONE  &&
        <Main>
          {loading && <Loading titulo={"Aguarde um momento"} subtitulo={"Estamos validando suas informações"}/>}
          {showSuccess && 
            <AlertDialog
              titulo={`Espécie ${especie.id ? 'atualizada' : 'cadastrada'} com sucesso!`} 
              buttonText={`${especie.id ? 'Voltar' : 'Cadastrar nova espécie'}`} 
              buttonOnClick={handleSuccessButton}
            />
          }
          { showDelete && 
              <DeleteDialog
                error={errors.delete}
                titulo="Deseja realmente excluir esta espécie?" 
                subtitulo="Cerfique-se de que esta espécie não está associada a nenhum plantio."
                onDelete={handleDelete} 
                onClose={()=>setShowDelete(false)}
              />
            }
          <div className="content-modal">
            <div className="top">
              <h3>Espécies</h3>
              <button type="button" onClick={()=>{limparCampos(); onClose()}}>
                <img src={close_icon} alt="Icone de fechar"/>
              </button>
            </div>
            
            <h4>
              { tipo === tiposGerenciamentoModal.EDITAR && "Editar uma espécie" }
              { tipo === tiposGerenciamentoModal.CRIAR && "Adicionar uma nova espécie" }
              { tipo === tiposGerenciamentoModal.INFORMACOES && "Informações" }
            </h4>
            <Formulario id="form-especie">
              { tipo !== tiposGerenciamentoModal.CRIAR &&
                <div className="form-group">
                  <label htmlFor="biomas">Lista de especies</label>
                  <Select
                    id="especies" 
                    name="especies" 
                    placeholder="Pesquise por especies aqui"
                    autoComplete="off"
                    value={nomeEspecie} 
                    onChange={filtrarEspecies} 
                    className={`input-prefix ${errors.especies && 'input-error'}`}
                    options={especies.rows}
                    onClickOption={onClickSelectEspecie}
                    showOptions={showOptions}
                    setShowOptions={setShowOptions}
                  />
                </div>
              }

              <div className="form-group">
                <label htmlFor="nome_comum">Nome Comum</label>
                <input value={especie.nome_comum} type="text" id="nome_comum" name="nome_comum" onChange={(e)=>validar(e, ValidacaoEspecie.validarNomeComum)} className={errors.nome_comum && "input-error"}
                  placeholder={ tipo === tiposGerenciamentoModal.CRIAR ? "Digite o nome comum" : placeholder} disabled={tipo === tiposGerenciamentoModal.INFORMACOES}  
                />
                <span className="error">{errors.nome_comum}</span>
              </div>

              <div className="form-group">
                <label htmlFor="nome_cientifico">Nome Cientifico</label>
                <input required value={especie.nome_cientifico} type="text" id="nome_cientifico" name="nome_cientifico" onChange={(e)=>validar(e, ValidacaoEspecie.validarNomeCientifico)} className={errors.nome_cientifico && "input-error"}
                  placeholder={ tipo === tiposGerenciamentoModal.CRIAR ? "Digite o nome cientifico" : placeholder}disabled={tipo === tiposGerenciamentoModal.INFORMACOES}  
                />
                <span className="error">{errors.nome_cientifico}</span>
              </div>
              
              <div className="form-group">
                <label>Biomas</label>
                
                { tipo !== tiposGerenciamentoModal.INFORMACOES ? 
                  <>
                    <Select
                      id="biomas" 
                      name="biomas" 
                      placeholder="Pesquise por biomas aqui" 
                      autoComplete="off"
                      required={false}
                      value={nomeBioma} 
                      onChange={filtrarBiomas} 
                      className={`input-prefix ${errors.biomas && 'input-error'}`}
                      options={biomas?.rows?.filter(bioma=>!especie.biomas.includes(bioma)) || []}
                      onClickOption={onClickSelectBioma}
                      showOptions={showOptionsBiomas}
                      setShowOptions={setShowOptionsBiomas}
                    />

                    {especie.biomas.map(bioma => (
                      <div className="form-group" key={`bioma-selecionado-${bioma.id}`}>
                        <input type="text" defaultValue={bioma.nome} readOnly className="bioma-selecionado input-sufix"/>
                        <img className="sufix" src={lixeira_icone} alt="Icone de Lixeira" onClick={()=>handleRemoveBioma(bioma)}/>
                      </div>
                    ))}
                  
                  </>
                 : 
                  <>
                   {especie.biomas.map(bioma=>(
                      <input key={`bioma-${bioma.id}`} defaultValue={bioma.nome} readOnly type="text" name="bioma" className="bioma-selecionado"
                        disabled={tipo === tiposGerenciamentoModal.INFORMACOES}  
                      />
                    ))}

                    {
                      especie.biomas.length === 0 &&
                      <input type="text" name="bioma" placeholder="Escolha uma espécie"
                        disabled={tipo === tiposGerenciamentoModal.INFORMACOES}  
                      />
                    }
                  </>
                }
                <span className="error">{errors.biomas}</span>
              </div>

              <div className="form-group">
                <label>Nome completo</label>
                <input disabled value={(especie.nome_comum ? especie.nome_comum+" - ": "") + especie.nome_cientifico} type="text" name="bioma" placeholder="Nome comum + Nome cientifico"/>
              </div>
              
              <div className="form-group">
                <span className="error">{errors.geral}</span>
              </div>

              {tipo !== tiposGerenciamentoModal.INFORMACOES &&
                <div className="form-group">
                  <button disabled={tipo === tiposGerenciamentoModal.EDITAR && !especie.id} className="save-button" type="button" onClick={handleSubmit}>{tipo === tiposGerenciamentoModal.EDITAR ? "Salvar edição" : "Registrar nova Espécie"}</button>
                </div>
              }

              {tipo === tiposGerenciamentoModal.EDITAR &&
                <div className="form-group">
                  <button disabled={!especie.id} className="remove-button" onClick={()=>setShowDelete(true)} type="button">Excluir espécie</button>
                </div>
              }
            </Formulario>
            
          </div>
        </Main>
      }
    </>
  );
};

export default EspeciesModal;