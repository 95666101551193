import data_icon from "../../../../assets/icons/data.svg";
import localizacao_icon from "../../../../assets/icons/localizacao.svg";
import { Card } from "../styled";
import { Content } from "./styled";
import { formatData } from "../../../../utils/conversoes";

function CardPlantio({ onClick, plantio }) {

  function getImagemCapa() {
    for (const midia of plantio.midias) {
      if (midia.tipo === "Imagem") {
        return `${midia.path}`;
      }
    }
  }

  return (
    <Card>
      <Content>

        <section>
          <div className="image">
            <img src={getImagemCapa()} alt={`Imagem do Plantio ${plantio.id}`} />
          </div>
        </section>

        <section>
          <div className="description">
            <div className="pro-container">
              <span>Plantio {plantio.id}</span>
              {plantio.tipo_categoria === "Profissional" && <span className="pro">PRO</span>}
            </div>

            <h4>{plantio.titulo}</h4>
            <div className="info">
              <div className="info-left">
                <h6>Data do Plantio</h6>
                <div className="details">
                  <img src={data_icon} alt="Icone de calendário" />
                  <span>{formatData(plantio.data)}</span>
                </div>
              </div>

              <div className="info-right">
                <h6>Localização</h6>
                <div className="details">
                  <img src={localizacao_icon} alt="Icone de localização" />
                  <span>{plantio.endereco}</span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="bottom">
            <button type="button" onClick={onClick}>Ver plantio</button>
          </div>
        </section>

      </Content>
    </Card>
  );
}

export default CardPlantio;

