import { Main } from "../styled";
import Formulario from "../../../components/Formulario";
import { estados } from "../../../../utils/data";
import cadastro_image from "../../../../assets/cadastro-image.png";
import eye_icon from "../../../../assets/icons/eye.svg";
import eye_show_icon from "../../../../assets/icons/eye-show.svg";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import ControllerCadastro from "./controller";
import ControllerAuth from "../controller";
import Loading from "../../../components/Loading";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../../utils/config";

function Cadastro({usuario, setUsuario, errors, setErrors}){
  const [loading, setLoading] = useState(false);
  const [showSenha, setShowSenha] = useState(false);
  const [showConfirmarSenha, setShowConfirmarSenha] = useState(false);
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();

  const controllerCadastro = new ControllerCadastro(usuario, errors, setUsuario, setErrors, setLoading);
  const controllerAuth = new ControllerAuth(usuario, errors, setUsuario, setErrors);

  return(
    <Main>
      {loading && <Loading titulo={"Aguarde um momento"} subtitulo={"Estamos validando suas informações"}/>}
      <div className="content">
        <section className="left-section">
          <div className="top">
            <h2>Junte-se a nós!</h2>
            <div className="description">
              <span>Faça parte da missão mais importante: ajudar o planeta!</span>
            </div>
          </div>
          <div className="form">
            <Formulario id="form-cadastro">
              <div className="form-col">
                <div className="form-group">
                  <label htmlFor="nome">Nome e sobrenome</label>
                  <input type="text" id="nome" name="nome" placeholder="Insira seu nome e sobrenome" required autoComplete="off"
                    value={usuario.nome} onChange={(e)=>controllerCadastro.validarNome(e)} className={errors.nome && 'input-error'}
                  />
                  <span className="error">{errors.nome}</span>
                </div>

                <div className="form-group">
                  <label htmlFor="email">E-mail</label>
                  <input type="text" id="email" name="email" placeholder="Insira seu e-mail" required autoComplete="off"
                    value={usuario.email} onChange={(e)=>controllerAuth.validarEmail(e)} className={errors.email && 'input-error'}
                  />
                  <span className="error">{errors.email}</span>
                </div>

                <div className="form-group">
                  <label htmlFor="estado">Estado</label>
                  <input type="text" list="estados" id="estado" name="estado" placeholder="Escolha seu estado" required autoComplete="off"
                    value={usuario.estado} onChange={(e)=>controllerCadastro.validarEstado(e)} className={errors.estado && 'input-error'}
                  />
                  <datalist id="estados">
                    { estados.map((estado, index)=>{
                        return <option  key={index} value={estado}/>
                    })}
                  </datalist>
                  <span className="error">{errors.estado}</span>
                </div>

                <div className="form-group">
                  <label htmlFor="cidade">Cidade</label>
                  <input type="text" id="cidade" name="cidade" placeholder="Insira sua cidade" required autoComplete="off"
                    value={usuario.cidade} onChange={(e)=>controllerCadastro.validarCidade(e)} className={errors.cidade && 'input-error'}
                  />
                  <span className="error">{errors.nome}</span>
                </div>

                <div className="form-group">
                  <label htmlFor="celular">Número de telefone</label>
                  <input type="text" id="celular" name="celular" placeholder="Insira seu número de telefone" required autoComplete="off"
                    value={usuario.celular} onChange={(e)=>controllerCadastro.validarCelular(e)} className={errors.celular && 'input-error'} maxLength={15}
                  />
                  <span className="error">{errors.celular}</span>
                </div>

                <div className="form-group">
                  <label htmlFor="senha">Senha</label>
                  <input type={showSenha ? "text" : "password"} id="senha" name="senha" placeholder="Insira sua senha" required autoComplete="off"
                    onChange={(e)=>controllerAuth.validarSenha(e)} className={errors.senha && 'input-error'}
                  />
                  <img src={showSenha ? eye_show_icon : eye_icon} alt="Icone ver senha" className="sufix" onClick={()=>setShowSenha(!showSenha)}/>
                  <span className="error">{errors.senha}</span>
                </div>

                <div className="form-group">
                  <label htmlFor="confirmar_senha">Confirmar senha</label>
                  <input type={showConfirmarSenha ? "text" : "password"} id="confirmar_senha" name="confirmar_senha" placeholder="Confirme sua senha" required autoComplete="off"
                    onChange={(e)=>controllerAuth.validarConfirmarSenha(e)} className={errors.confirmar_senha && 'input-error'}
                  />
                  <img src={showConfirmarSenha ? eye_show_icon : eye_icon} alt="Icone ver senha" className="sufix"  onClick={()=>setShowConfirmarSenha(!showConfirmarSenha)}/>
                  <span className="error">{errors.confirmar_senha}</span>
                </div>

                <div className="form-group">
                  <div className="terms">
                    <label htmlFor="termo">
                      <div className={`check ${checked && 'checked'}`}>
                        <input value={checked} type="checkbox" name="termo" id="termo" required autoComplete="off" 
                          onClick={()=>controllerCadastro.validarTermo(checked, setChecked)} className={errors.termo && 'input-error'}
                        />
                      </div>
                    </label>
                    
                    <span>
                      Ao clicar no botão "Criar conta", você aceita 
                      nossos <a href="http://google.com">termo de uso</a> e <a href="http://google.com">política de privacidade.</a>
                    </span>
                  </div>
                  <span className="error">{errors.termo}</span>
                </div>

                <div className="form-group">
                  <button type="submit" onClick={(e)=>controllerCadastro.handleSubmit(e, navigate)}>Criar conta</button>
                  <span className="error">{errors.geral}</span>
                </div>

                <div className="form-group">
                  <span className="have-account">
                    Já possui uma conta? <Link to={'/login'}>Acessar agora</Link>
                  </span>
                </div>

              </div>
            </Formulario>
          </div>
        </section>

        <section className="right-section">
          <img src={cadastro_image} alt="Crianca plantando uma muda"/>
        </section>
      </div>
    </Main>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Cadastro);