import api from '../services/api';

const Especie = {
  create : async function (data) {
    return await api.post('especies', data)
    .then(response=>response)
    .catch(error=>error);
  },

  delete : async function (especie_id) {
    return await api.delete(`especies/id/${especie_id}`)
    .then(response=>response)
    .catch(error=>error);
  },

  findAll : async function () {
    return await api.get('especies/all/1/6')
    .then(response=>response)
    .catch(error=>error);
  },

  findSearch : async function (data) {
    return await api.get(`especies/data/${data}/1/20`)
    .then(response=>response)
    .catch(error=>error);
  }
}

export default Especie;
