import { Main, Container } from "./styled";
import { Link } from 'react-router-dom';
import grupo_image from "../../../../assets/grupo.png";
import organizacao1_image from "../../../../assets/organizacao1.png";
import organizacao2_image from "../../../../assets/organizacao2.png";
import localizacao_icone from "../../../../assets/icons/localizacao.svg";
import close_icone from "../../../../assets/icons/close.svg";
import like_icon from "../../../../assets/icons/like.svg";
import facebook_icon from "../../../../assets/icons/facebook.svg";
import instagram_icon from "../../../../assets/icons/instagram.svg";

function OrganizacaoModal(props){
  const closeModal = props.onClose;
  return(
    <Main>
      <Container>
        <section>
          <div className="image">
            <img src={grupo_image} alt="Foto da organização"/>
            <div className="close" onClick={closeModal}>
              <button type="button">
                <img src={close_icone} alt="Icone de fechar"/>
              </button>
            </div>
          </div>
        </section>
        
        <section>
          <div className="title">
            <h3 className="primary">Plantio Brasil</h3>
            <img src={like_icon} alt="Icone de Like"/>
          </div>

          <div className="info">
            <img src={localizacao_icone} alt="Icone de localizacao"/>
            <span>Espirito Santo</span>          
          </div>

        </section>

        <section>
          <h3 className="primary">Sobre</h3>
          <div className="about">
            <span>Plantio Brasil é um movimento organizado para o plantio de árvores centrado no estado do Espírito Santo atualmente. Ajude-os doando diretamente e/ou acesse as redes sociais para acompanhá-los. </span>
          </div>
        </section>

        <section>
          <h3 className="primary">Redes Sociais</h3>
          
          <div className="social-network">
            <img src={instagram_icon} alt="Icone de Instagram"/>
            <Link to="https://intagram.com">@plantiobrasil</Link>
          </div>

          <div className="social-network">
            <img src={facebook_icon} alt="Icone de Facebook"/>
            <Link to="https://facebook.com">facebook.com/PlantioBrasilWorld</Link>
          </div>
        </section>
        
        <section>
          <h3 className="primary">Galeria de Fotos do Projeto</h3>

          <div className="gallery">
            <div className="image">
              <img src={organizacao1_image} alt="Foto 1 da oragnização"/>
            </div>

            <div className="image">
              <img src={organizacao2_image} alt="Foto 1 da oragnização"/>
            </div>

            <div className="image">
              <img src={organizacao1_image} alt="Foto 1 da oragnização"/>
            </div>

            <div className="image">
              <img src={organizacao2_image} alt="Foto 1 da oragnização"/>
            </div>

            <div className="image image-more">
              <img src={organizacao2_image} alt="Foto 1 da oragnização"/>
              <span>+12</span>
            </div>
          </div>
        </section>

        <section>
          <div className="bottom">
            <button type="button">Quero doar</button>
          </div>
        </section>
        
      </Container>
    </Main>
  );
};

export default OrganizacaoModal;