import { addDays, endOfMonth, formatDate, parseISO } from "date-fns";
import { useState } from "react";
import Plantio from "../../../../models/Plantio";
import CustomHandle from "../../../../utils/CustomHandle";
import { useEffect } from "react";
import { FilterDaysComponent } from "../../../components/filterDays";
import ReactApexChart from "react-apexcharts";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../../utils/config";
import Usuario from "../../../../models/Usuario";


function getCurrentMonth() {
  return formatDate(new Date(), 'yyyy-MM')
};

const GraphLinhaUsuarios = ({ usuario, errors, setErrors, setLoading }) => {

  const [filterPeriod, setFilterPeriod] = useState(7);
  const [selectedDate, setSelectedDate] = useState(getCurrentMonth());
  const [toDate, setToDate] = useState(new Date());
  const fromDate = addDays(toDate, (filterPeriod * -1));
  const [graphUsuarios, setGraphUsuarios] = useState();

  const formattedToDate = formatDate(toDate, 'yyyy-MM-dd');
  const formattedFromDate = formatDate(fromDate, 'yyyy-MM-dd');
  const categories = graphUsuarios?.items.map(item => item.label).reverse();
  const chartData = graphUsuarios?.items.map(item => item.value).reverse();

  const findGraphPlantios = async () => {
    setLoading(true);
    const result = await Usuario.findGraph(formattedFromDate, formattedToDate, 'todos');
    const onSuccess = (response) => {
      setGraphUsuarios(response);
    };

    CustomHandle.handleResult(result, errors, setErrors, onSuccess);

    setLoading(false);
  }

  useEffect(() => {
    const currentMonth = getCurrentMonth();
    if (selectedDate !== currentMonth) {
        const selectedEndDate = endOfMonth(parseISO(`${selectedDate}-01`));
        setToDate(selectedEndDate);
        setFilterPeriod(30);
    } else {
        setToDate(new Date());
    }
}, [selectedDate]);

  useEffect(() => {
    findGraphPlantios();
  }, [filterPeriod, toDate]);

  const chartOptions = {
    chart: {
      type: 'line',
      height: 200,
      zoom: {
        enabled: false,
      },
      events: {},
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 1,
      colors: ['#2AA444'],
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      min: 0,
      max: graphUsuarios?.count || 0,
      tickAmount: 4
    },
    grid: {
      borderColor: "#2AA444",
      strokeDashArray: 5,

      row: {
        colors: ['transparent'],
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        const serie = series[seriesIndex];
        const value = serie[dataPointIndex];
        return value;
      },
      cssClass: 'teste'
    },

  };

  return (
    <section className="grafic-section">

      <div className="action-bar">
        <FilterDaysComponent
          selected={filterPeriod}
          setSelected={setFilterPeriod}
        />
        <input className='select-month' type='month' value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)} />
      </div>

      <div className='graphic-title'>
        <span className='b3-bold'>Numero de usuários cadastados</span>
        <span className='b3-bold'>Total: <span className='b3-regular'>{graphUsuarios?.count || 0} usuários</span></span>
      </div>

      <ReactApexChart
        series={[{
          name: "Usuários",
          color: "#2AA444",
          data: chartData,
        }]}
        options={chartOptions}
        type='line'
        height={200}
      />
    </section>
  );

};

export default connect(mapStateToProps, mapDispatchToProps)(GraphLinhaUsuarios);